
const Businesses = ({ className }) => {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M263 5065 c-102 -31 -180 -103 -231 -212 l-27 -58 -3 -2300 c-2
                    -1790 0 -2310 10 -2345 15 -56 78 -121 134 -138 29 -8 146 -12 402 -12 l361 0
                    3 613 3 612 32 60 c36 71 99 132 175 172 l53 28 370 0 370 0 57 -28 c111 -54
                    184 -152 208 -277 6 -31 10 -280 10 -616 l0 -564 450 0 450 0 -2 2228 -3 2227
                    -27 56 c-35 71 -104 141 -173 174 -61 30 -52 29 -1050 180 -385 58 -861 131
                    -1058 161 -373 56 -443 62 -514 39z m983 -871 c50 -24 77 -68 82 -133 3 -46 0
                    -62 -20 -90 -46 -68 -71 -76 -274 -79 -110 -2 -197 1 -221 7 -54 15 -109 75
                    -118 130 -9 53 25 126 74 158 32 22 43 23 238 23 168 0 211 -3 239 -16z m1074
                    -6 c48 -33 72 -70 77 -120 7 -58 -23 -118 -74 -149 -37 -23 -45 -24 -243 -24
                    -198 0 -206 1 -243 24 -51 31 -81 91 -74 149 5 50 29 87 77 120 32 21 42 22
                    240 22 198 0 208 -1 240 -22z m-1074 -634 c50 -24 77 -68 82 -133 3 -46 0 -62
                    -20 -90 -46 -68 -71 -76 -274 -79 -110 -2 -197 1 -221 7 -54 15 -109 75 -118
                    130 -9 53 25 126 74 158 32 22 43 23 238 23 168 0 211 -3 239 -16z m1074 -6
                    c48 -33 72 -70 77 -120 7 -58 -23 -118 -74 -149 -37 -23 -45 -24 -243 -24
                    -198 0 -206 1 -243 24 -51 31 -81 91 -74 149 5 50 29 87 77 120 32 21 42 22
                    240 22 198 0 208 -1 240 -22z m-1074 -634 c50 -24 77 -68 82 -133 3 -46 0 -62
                    -20 -90 -46 -68 -71 -76 -274 -79 -110 -2 -197 1 -221 7 -54 15 -109 75 -118
                    130 -9 53 25 126 74 158 32 22 43 23 238 23 168 0 211 -3 239 -16z m1074 -6
                    c48 -33 72 -70 77 -120 7 -58 -23 -118 -74 -149 -37 -23 -45 -24 -243 -24
                    -198 0 -206 1 -243 24 -51 31 -81 91 -74 149 5 50 29 87 77 120 32 21 42 22
                    240 22 198 0 208 -1 240 -22z m-1074 -634 c50 -24 77 -68 82 -133 3 -46 0 -62
                    -20 -90 -46 -68 -71 -76 -274 -79 -110 -2 -197 1 -221 7 -54 15 -109 75 -118
                    130 -9 53 25 126 74 158 32 22 43 23 238 23 168 0 211 -3 239 -16z m1074 -6
                    c48 -33 72 -70 77 -120 7 -58 -23 -118 -74 -149 -37 -23 -45 -24 -243 -24
                    -198 0 -206 1 -243 24 -51 31 -81 91 -74 149 5 50 29 87 77 120 32 21 42 22
                    240 22 198 0 208 -1 240 -22z"/>
                <path d="M3310 1560 l0 -1561 763 3 762 3 67 32 c82 39 142 99 182 183 l31 65
                    0 1125 0 1125 -26 55 c-46 99 -128 173 -225 204 -47 16 -1516 326 -1541 326
                    -10 0 -13 -319 -13 -1560z m1096 654 c53 -39 69 -71 69 -134 0 -63 -16 -95
                    -69 -134 -25 -19 -44 -21 -224 -24 -223 -4 -256 2 -301 53 -52 59 -54 147 -4
                    206 47 55 67 59 295 56 191 -2 209 -4 234 -23z m0 -640 c53 -39 69 -71 69
                    -134 0 -63 -16 -95 -69 -134 -25 -19 -44 -21 -224 -24 -223 -4 -256 2 -301 53
                    -52 59 -54 147 -4 206 47 55 67 59 295 56 191 -2 209 -4 234 -23z m0 -640 c53
                    -39 69 -71 69 -134 0 -63 -16 -95 -69 -134 -25 -19 -44 -21 -224 -24 -223 -4
                    -256 2 -301 53 -52 59 -54 147 -4 206 47 55 67 59 295 56 191 -2 209 -4 234
                    -23z"/>
            </g>
        </svg>

    )
}
export default Businesses