import { useSearchParams, useNavigate, Navigate, Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { BeatLoader } from "react-spinners"
import { FaCrown } from "react-icons/fa"
import Cookies from "js-cookie"

import paymentSuccessIcon from "../../assets/icons/payment-success.png"
import ErrorPage from "../../components/ErrorPage/ErrorPage"
import BrandBar from "../../components/Navbar/BrandBar"
import { verifyCheckoutSessionAsync } from "../../slices/payments"
import {
    COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT,
    dashboardPath,
    FREELANCER
} from "../../assets/constants"

const PaymentSuccess = () => {
    const [searchParams, _] = useSearchParams()
    const session_id = searchParams.get("session_id")

    const [renderPage, setRenderPage] = useState(false)
    const [redirectPath, setRedirectPath] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        const redirectEncodedPath = window.sessionStorage.getItem(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION)
        if (redirectEncodedPath) {
            const redirectDecodedpath = window.decodeURIComponent(redirectEncodedPath)
            return setRedirectPath(redirectDecodedpath)
        }
        dispatch(verifyCheckoutSessionAsync({ sessionId: session_id, status: "success" }))
            .unwrap()
            .then((data) => {
                if (data.payload.userType === FREELANCER) {
                    Cookies.set(COOKIE_FREELANCER_VERSIGEMS_PURCHASE_COUNT, data.payload.versigems)
                    return navigate(dashboardPath, { replace: true })
                }
                setRenderPage(true)
            })
            .catch(() => setRenderPage(null))
    }, [])
    return (renderPage !== null
        ? redirectPath
            ? <Navigate to={redirectPath} replace />
            : <>
                <BrandBar />
                <div className="mb-12 py-10 md:py-14 px-3 smaller:px-8 md:px-16 lg:px-28">
                    <div className="max_screen_w rounded-md overflow-hidden">
                        <div className="col-span-2 flex justify-center text-start px-4 md:py-6">
                            <div className="bg-white shadow-form flex-1 flex flex-col gap-3 py-6 rounded-md px-4 max-w-[560px]">
                                {renderPage
                                    ? <div className="flex flex-col items-center gap-y-4 px-3 sm404:px-6 md:px-12 pt-10 md:pt-14">
                                        <h3 className="text-versich-light-blue text-xl md:text-2xl font-bold text-center">Payment Successful</h3>
                                        <img className="w-20 h-20 md:w-24 md:h-24" src={paymentSuccessIcon} alt="payment success" />
                                        <p className="text-versich-label w-full text-start max-md:text-base md:text-lg">Exclusive Benefits for Premium Subscribers:</p>
                                        <div className="w-full sm404:pl-5">
                                            <ul className="list-disc list-outside max-md:text-sm">
                                                <li className="flex gap-2">
                                                    <FaCrown className="text-green-600 max-md:min-w-3 max-md:h-3 mt-0.5" />
                                                    <span className="text-versich-dark-blue/80 font-semibold">View Client Direct Messages</span>
                                                </li>
                                                <li className="flex gap-2">
                                                    <FaCrown className="text-green-600 max-md:min-w-3 max-md:h-3 mt-0.5" />
                                                    <span className="text-versich-dark-blue/80 font-semibold">Profile Listed as Top Freelancer</span>
                                                </li>
                                                <li className="flex gap-2">
                                                    <FaCrown className="text-green-600 max-md:min-w-3 max-md:h-3 mt-0.5" />
                                                    <span className="text-versich-dark-blue/80 font-semibold">View Client Details</span>
                                                </li>
                                                <li className="flex gap-2">
                                                    <FaCrown className="text-green-600 max-md:min-w-3 max-md:h-3 mt-0.5" />
                                                    <span className="text-versich-dark-blue/80 font-semibold">Live Support</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-5 pb-5 md:pb-8">
                                            <Link to={dashboardPath} className="bg-versich-blue hover:bg-versich-blue-hover text-sm lg:text-base w-full text-white font-medium px-4 lg:px-5 py-3 rounded-lg">Go to Dashboard</Link>
                                        </div>
                                    </div>
                                    : <div className="w-full h-64 flex justify-center items-center">
                                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <ErrorPage status={404} />
    )
}
export default PaymentSuccess