import { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import DropdownFieldInput from "../Services/DropdownFieldInput"
import { allPaymentTypeAsync, allEstimatedBudgetAsync, allProjectDurationAsync } from "../../slices/service"
import { sortbyOptions } from "../../assets/constantOptions"

const jobSortbyOptions = [
    ...sortbyOptions,
    {
        uid: "highest_price",
        title: "Highest Price"
    }, {
        uid: "lowest_price",
        title: "Lowest Price"
    }
]

const Filters = ({ setFilters, initialSortby = "", initialPaymentType = "", initialBudget = "", initialDuration }) => {
    const [sortby, setSortby] = useState(initialSortby)
    const [paymentTypeOptions, setPaymentTypeOptions] = useState([])
    const [paymentType, setPaymentType] = useState(initialPaymentType)
    const [budgetOptions, setBudgetOptions] = useState([])
    const [budget, setBudget] = useState(initialBudget)
    const [durationOptions, setDurationOptions] = useState([])
    const [duration, setDuration] = useState(initialDuration)
    const manualChange = useRef(false) // track user manual filter change using this

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allPaymentTypeAsync())
            .unwrap()
            .then((data) => {
                setPaymentTypeOptions(data.payload)
            })
        dispatch(allEstimatedBudgetAsync())
            .unwrap()
            .then((data) => {
                setBudgetOptions(data.payload)
            })
        dispatch(allProjectDurationAsync())
            .unwrap()
            .then((data) => {
                setDurationOptions(data.payload)
            })
    }, [])

    // Set default
    useEffect(() => {
        setSortby(initialSortby)
        setPaymentType(initialPaymentType)
        setBudget(initialBudget)
        setDuration(initialDuration)
    }, [initialSortby, initialPaymentType, initialBudget, initialDuration])

    // Track value changes
    useEffect(() => {
        setFilters({ sortby, paymentType, budget, duration }, manualChange.current)
    }, [sortby, paymentType, budget, duration, manualChange])

    const dropdowns = [
        { name: "sortby", options: jobSortbyOptions, value: sortby, placeholder: "Sort by", setValue: setSortby },
        { name: "paymentType", options: paymentTypeOptions, value: paymentType, placeholder: "Payment type", setValue: setPaymentType },
        { name: "budget", options: budgetOptions, value: budget, placeholder: "Budget", setValue: setBudget },
        { name: "duration", options: durationOptions, value: duration, placeholder: "Duration", setValue: setDuration },
    ]
    return (
        <div className="grid grid-cols-1 sm401:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-5 lg:gap-6">
            {dropdowns.map(dropdown => (
                <DropdownFieldInput
                    name={dropdown.name}
                    options={dropdown.options}
                    value={dropdown.value}
                    placeholder={dropdown.placeholder}
                    hidePlaceholder={false}
                    onChange={(e) => {
                        dropdown.setValue(e.target.value)
                        manualChange.current = true
                    }}
                    key={dropdown.name}
                />
            ))}
        </div>
    )
}
export default Filters