import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../../ServiceRequestLayout"
import BusinessSize from "../../components/BusinessSize"
import ServiceNeed from "./components/ServiceNeed"
import ChoiceOfTool from "./components/ChoiceOfTool"
import ProjectDuration from "../../components/ProjectDuration"
import Budget from "../../components/Budget"
import ClientDetails from "../../components/ClientDetails"
import TitleAndDescription from "../../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_DAA_BU } from "../../../../../../assets/constants"
import { createRequestAsync } from "../../../../../../slices/client/service/dataAndAnalytics/business"

const metadata = {
    title: "New Request - Business: Data and Analytics Service",
    description: "Business data and analytics new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/data-and-analytics/business/`
}

const BusinessDataAndAnalytics = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [businessSize, setBusinessSize] = useState("")
    const [need, setNeed] = useState("")
    const [otherNeed, setOtherNeed] = useState(null)
    const [tools, setTools] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.businessSize && setBusinessSize(parsedData.businessSize)
        parsedData.need && setNeed(parsedData.need)
        parsedData.otherNeed && setOtherNeed(parsedData.otherNeed)
        parsedData.tools && setTools(parsedData.tools)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
    }, [])

    // Generate request data
    const formData = {
        businessSize,
        need,
        ...(otherNeed && { otherNeed }),
        tools,
        projectDuration,
        ...(otherProjectDuration && { otherProjectDuration }),
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 7
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!businessSize ? (
                <BusinessSize
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    setBusinessSize={setBusinessSize}
                    goBack={() => navigate(newRequestPath + "data-and-analytics/")}
                />
            ) : !need ? (
                <ServiceNeed
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    defaultValue={searchParams.get("need")}
                    setNeed={setNeed}
                    setOtherNeed={setOtherNeed}
                    goBack={() => setBusinessSize("")}
                />
            ) : !tools ? (
                <ChoiceOfTool
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    setTools={setTools}
                    goBack={() => setNeed("")}
                />
            ) : !projectDuration ? (
                <ProjectDuration
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    setProjectDuration={setProjectDuration}
                    setOtherProjectDuration={setOtherProjectDuration}
                    goBack={() => setTools(null)}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setProjectDuration("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(6)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setBudget("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(7)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setBudget("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const BusinessDataAndAnalyticsLayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_DAA_BU
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <BusinessDataAndAnalytics cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default BusinessDataAndAnalyticsLayout