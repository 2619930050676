import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { useSearchParams, useParams } from "react-router-dom"

import noData from "../../../assets/images/no-data.png"
import Meta from "../../../components/Meta"
import SearchBar from "../../../components/FindBusinesses/SearchBar"
import Filters from "../../../components/FindJobs/Filters"
import JobCard from "../../../components/FindJobs/JobCard"
import SectionLoader from "../../../components/loader/SectionLoader"
import Paginator from "../../../components/Paginator"
import { getPublicRequestsAsync } from "../../../slices/request"
import { findJobsPath } from "../../../assets/constants"

const metadata = {
    title: "Find Jobs | Job Requests & Opportunities",
    description: "Explore job opportunities and submit your job requests. Connect with employers and find your next career move here.",
    url: `${process.env.REACT_APP_CLIENT_URL}/`
}

const Jobs = () => {
    const { user } = useSelector(state => state.auth)
    const [jobs, setJobs] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const initialPage = parseInt(searchParams.get("page") || "1", 10)
    const [currentPage, setCurrentPage] = useState(initialPage)
    const [totalPages, setTotalPages] = useState(1)

    const { service } = useParams()

    // Query parameters
    const querySearch = searchParams.get("search") || ""
    const filterSortBy = searchParams.get("sortby") || ""
    const filterPaymentType = searchParams.get("paymentType") || ""
    const filterBudget = searchParams.get("budget") || ""
    const filterDuration = searchParams.get("duration") || ""

    /** 
    * Determine the effective search term.
    *  - If the URL contains a query parameter "search"
    *  - If there’s a route parameter (e.g. /find/web-app-development/jobs)
    */
    const effectiveQuery = querySearch || service || ""

    const combinedFilters = {
        ...(effectiveQuery && { search: effectiveQuery }),
        ...(currentPage && { page: currentPage }),
        ...(filterSortBy && { sortby: filterSortBy }),
        ...(filterPaymentType && { paymentType: filterPaymentType }),
        ...(filterBudget && { budget: filterBudget }),
        ...(filterDuration && { duration: filterDuration }),
    }

    // Fetch jobs based on search term, filters and page
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchJobs = async () => {
            setJobs([])
            const stringParams = new URLSearchParams(combinedFilters).toString()
            await dispatch(getPublicRequestsAsync(`?${stringParams}`))
                .unwrap()
                .then(async (data) => {
                    await new Promise(resolve => setTimeout(resolve, 50))
                    setJobs(data.results)
                    setTotalPages(data.totalPages ? data.totalPages : 0)
                })
                .catch(() => {
                    setJobs(null)
                    setTotalPages(null)
                })
        }
        fetchJobs()
    }, [effectiveQuery, currentPage, filterBudget, filterSortBy, filterPaymentType, filterDuration])


    // Update search resets all filter keys
    const updateSearch = (newQuery) => {
        const params = {}
        if (newQuery) {
            params.search = newQuery
        }
        params.page = "1"
        setSearchParams(params, { replace: true })
        setCurrentPage(1)
    }
    useEffect(() => {
        updateSearch("")
    }, [service])
    /**
     * Update filters
     * Merge with existing search value  
     * When a manual filter change occurs, reset page to 1
     */
    const updateFilters = (newFilters, resetPage = false) => {
        const params = { ...(querySearch && { search: querySearch }) }
        Object.keys(newFilters).forEach((key) => {
            if (newFilters[key]) {
                params[key] = newFilters[key]
            }
        })
        if (resetPage) {
            params.page = "1"
            setCurrentPage(1)
        }
        setSearchParams(params, { replace: true })
    }
    // Change the page number in URL
    const changePage = (number) => {
        let params = Object.fromEntries([...searchParams].filter(([key, value]) => value))
        if (number > 1) {
            params.page = number
        }
        else delete params.page
        setSearchParams(params, { replace: true })
    }
    useEffect(() => {
        changePage(currentPage)
    }, [currentPage])
    return (
        <div>
            <Meta data={metadata} />
            <div className="text-versich-darktext-color w-full h-full px-8 md:px-16 xl:px-28 pt-[100px] pb-[80px]">
                <div className="max_screen_w">
                    <div className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-left">
                        <h1 className="leading-tight">Find Jobs Tailored Just for You</h1>
                        <p className="text-base sm:text-lg lg:text-xl tracking-wide py-3 font-normal">Explore opportunities that match your skills and interests. Submit proposals and start working on projects you love.</p>
                    </div>
                    <div className="mt-6 sm:mt-8 lg:mt-10 space-y-4 sm:space-y-5 lg:space-y-6">
                        <SearchBar
                            id="job-search"
                            placeholder="Search for jobs by title, skill, or service."
                            setQuery={updateSearch}
                            initialSearch={querySearch}
                            parentPath={findJobsPath}
                        />
                        <Filters
                            setFilters={updateFilters}
                            initialSortby={filterSortBy}
                            initialPaymentType={filterPaymentType}
                            initialBudget={filterBudget}
                            initialDuration={filterDuration}
                        />
                    </div>
                </div>
            </div>
            <div className="pb-12 md:pb-16 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <h2 className="text-versich-darktext-color text-xl smaller:text-2xl lg:text-3xl font-bold text-left pb-2">{
                        user
                            ? `Hi ${user.name}, here are your job matches!`
                            : "Join VersiMarket to find work that fits your skills."
                    }</h2>
                    <div className="grid gap-y-8 md:gap-y-10 text-left my-10 md:my-16">
                        {jobs
                            ? jobs.length > 0
                                ? jobs.map((jobs, index) => (
                                    <JobCard job={jobs} updateSearch={updateSearch} key={index} />
                                ))
                                : <SectionLoader className="h-[500px]" />
                            : <div className="flex flex-col items-center justify-center">
                                <div>
                                    <img className="w-full h-full max-w-[500px] max-h-[500px]" src={noData} alt="no job found" />
                                </div>
                                <p className="text-versich-darktext-color text-sm -mt-5">No jobs found</p>
                            </div>
                        }
                    </div>
                    {totalPages &&
                        <Paginator pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={3} />
                    }
                </div>
            </div>
        </div>
    )
}
export default Jobs