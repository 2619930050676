import { FormProvider, useForm } from "react-hook-form"

import DialogBox, { closeDialog } from "../../DialogBox"
import FormLoader from "../../loader/FormLoader"
import Close from "../../svgs/Close"
import { directContactDialog } from "./DirectContactDialog"

const DirectContactDialogLayout = ({ children, customCloseDialog, description, onSubmit, errorMsg, loading }) => {
    const methods = useForm()
    return (
        <DialogBox
            id={directContactDialog}
            className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm sm:!max-w-xl !w-11/12 xl:!w-3/4"
            toastStacked={false}
            hideClose={true}
            customCloseEvent={(e) => { customCloseDialog(); closeDialog(e) }}
        >
            <div className="space-y-10 p-2.5 sm401:p-3.5 sm:p-4">
                <div className="flex gap-1.5">
                    <div className="text-versich-dark-blue flex-1">
                        <div className="text-xl font-semibold">Contact Business</div>
                        <div className="text-sm mt-0.5">{description}</div>
                    </div>
                    <div id="dialog_closebtn" className="text-right">
                        <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={directContactDialog}>
                            <Close className="w-4 h-4" />
                        </button>
                    </div>
                </div>
                <FormProvider {...methods}>
                    <form className="space-y-10" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={directContactDialog}>
                        <div className="space-y-5">
                            {children}
                        </div>
                        {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full text-center gap-3 md:gap-5">
                            <button className="primary_btn disabled:hover:bg-versich-light-blue disabled:opacity-70 w-full md:py-3 !px-6 lg:!px-10 disabled:cursor-not-allowed" type="submit">Submit</button>
                        </div>
                    </form>
                </FormProvider>
            </div>
            {loading && <FormLoader />}
        </DialogBox>
    )
}
export default DirectContactDialogLayout