
const Message = ({ message, userAccountType }) => {
    const isSent = message.sender.accountType === userAccountType
    const time = new Date(message.timestamp).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })
    return (
        <div className={"conversation flex mt-1 "
            + (isSent ? "message_sent justify-end" : "justify-start message_received")
        }>
            <div
                className={"max-w-xs flex gap-2 p-2.5 border shadow-formLight rounded-xl "
                    + (isSent
                        ? "bg-versich-lighter-blue/5 text-versich-dark-blue border-versich-lighter-blue/10 inner_shadow rounded-br-[4px]"
                        : "bg-white text-versich-dark-blue border-versich-darktext-color/[0.075] rounded-bl-[4px]"
                    )
                }
            >
                <div>
                    {message.content.split("\n").map((line, index) => (
                        // min-height = line-height (or)
                        // min-height = line-height - (line-height / 5)
                        <p className="min-h-[9.6px] sm401:min-h-4" key={index}>{line}</p>
                    ))}
                </div>
                <div className="text-gray-600/70 text-[10px] leading-tight text-right inline-flex items-end min-w-fit">
                    <span className="relative top-1.5">{time}</span>
                </div>
            </div>
        </div>
    )
}
export default Message