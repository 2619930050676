import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../ServiceRequestLayout"
import ProjectType from "./components/ProjectType"
import ProjectNeed from "./components/ProjectNeed"
import MonetizationStrategy from "./components/MonetizationStrategy"
import AppPlatform from "./components/AppPlatform"
import BusinessType from "../components/BusinessType"
import IndustryType from "../components/IndustryType"
import ProjectDuration from "../components/ProjectDuration"
import Budget from "../components/Budget"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import TitleAndDescription from "../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_MA } from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/mobileApp"

const metadata = {
    title: "New Request - Mobile App Service",
    description: "Mobile app new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/mobile-app/`
}

const MobileApp = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [projectType, setProjectType] = useState("")
    const [otherProjectType, setOtherProjectType] = useState(null)
    const [projectNeed, setProjectNeed] = useState("")
    const [otherProjectNeed, setOtherProjectNeed] = useState(null)
    const [monetizationStrategy, setMonetizationStrategy] = useState("")
    const [otherMonetizationStrategy, setOtherMonetizationStrategy] = useState(null)
    const [appPlatform, setAppPlatform] = useState("")
    const [otherAppPlatform, setOtherAppPlatform] = useState(null)
    const [businessType, setBusinessType] = useState("")
    const [otherBusinessType, setOtherBusinessType] = useState(null)
    const [industryType, setIndustryType] = useState("")
    const [otherIndustryType, setOtherIndustryType] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.projectType && setProjectType(parsedData.projectType)
        parsedData.otherProjectType && setProjectType(parsedData.otherProjectType)
        parsedData.projectNeed && setProjectNeed(parsedData.projectNeed)
        parsedData.otherProjectNeed && setOtherProjectNeed(parsedData.otherProjectNeed)
        parsedData.monetizationStrategy && setMonetizationStrategy(parsedData.monetizationStrategy)
        parsedData.otherMonetizationStrategy && setOtherMonetizationStrategy(parsedData.otherMonetizationStrategy)
        parsedData.appPlatform && setAppPlatform(parsedData.appPlatform)
        parsedData.otherAppPlatform && setOtherAppPlatform(parsedData.otherAppPlatform)
        parsedData.businessType && setBusinessType(parsedData.businessType)
        parsedData.otherBusinessType && setOtherBusinessType(parsedData.otherBusinessType)
        parsedData.industryType && setIndustryType(parsedData.industryType)
        parsedData.otherIndustryType && setOtherIndustryType(parsedData.otherIndustryType)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
    }, [])

    // Generate request data
    const formData = {
        projectType,
        ...(otherProjectType && { otherProjectType }),
        projectNeed,
        ...(otherProjectNeed && { otherProjectNeed }),
        monetizationStrategy,
        ...(otherMonetizationStrategy && { otherMonetizationStrategy }),
        appPlatform,
        ...(otherAppPlatform && { otherAppPlatform }),
        businessType,
        ...(otherBusinessType && { otherBusinessType }),
        industryType,
        ...(otherIndustryType && { otherIndustryType }),
        projectDuration,
        ...(otherProjectDuration && { otherProjectDuration }),
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        hiringDecision,
        ...(otherHiringDecision && { otherHiringDecision }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 11
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!projectType ? (
                <ProjectType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    defaultValue={searchParams.get("projectType")}
                    setProjectType={setProjectType}
                    setOtherProjectType={setOtherProjectType}
                    goBack={() => navigate(newRequestPath)}
                />
            ) : !projectNeed ? (
                <ProjectNeed
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    defaultValue={searchParams.get("projectNeed")}
                    setProjectNeed={setProjectNeed}
                    setOtherProjectNeed={setOtherProjectNeed}
                    goBack={() => setProjectType("")}
                />
            ) : !monetizationStrategy ? (
                <MonetizationStrategy
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    setMonetizationStrategy={setMonetizationStrategy}
                    setOtherMonetizationStrategy={setOtherMonetizationStrategy}
                    goBack={() => setProjectNeed("")}
                />
            ) : !appPlatform ? (
                <AppPlatform
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    defaultValue={searchParams.get("appPlatform")}
                    setAppPlatform={setAppPlatform}
                    setOtherAppPlatform={setOtherAppPlatform}
                    goBack={() => setMonetizationStrategy("")}
                />
            ) : !businessType ? (
                <BusinessType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    setBusinessType={setBusinessType}
                    setOtherBusinessType={setOtherBusinessType}
                    goBack={() => setAppPlatform("")}
                />
            ) : !industryType ? (
                <IndustryType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(6)}
                    setIndustryType={setIndustryType}
                    setOtherIndustryType={setOtherIndustryType}
                    goBack={() => setBusinessType("")}
                />
            ) : !projectDuration ? (
                <ProjectDuration
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(7)}
                    setProjectDuration={setProjectDuration}
                    setOtherProjectDuration={setOtherProjectDuration}
                    goBack={() => setIndustryType("")}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(8)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setProjectDuration("")}
                />
            ) : !hiringDecision ? (
                <HiringDecision
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(9)}
                    setHiringDecision={setHiringDecision}
                    setOtherHiringDecision={setOtherHiringDecision}
                    goBack={() => setBudget("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(10)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setHiringDecision("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(11)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setHiringDecision("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const MobileAppLayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_MA
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <MobileApp cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default MobileAppLayout