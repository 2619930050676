import { FaCrown } from "react-icons/fa"

const PremiumButton = ({ className, onClick, label }) => {
    return (
        <button className={"text-yellow-500 hover:bg-yellow-100 text-sm font-medium inline-flex items-center gap-1 border-2 border-yellow-300 rounded-xl "
            + (className ? className : "px-2.5 lg:px-3.5 py-2.5")
        }
            onClick={onClick}
        >
            <FaCrown /> {label}
        </button>
    )
}
export default PremiumButton