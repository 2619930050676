import { useSelector } from "react-redux"
import { useState } from "react"
import { Link } from "react-router-dom"
import { MdClose } from "react-icons/md"
import { CgMenuLeft } from "react-icons/cg"

import logo from "../../assets/logo.svg"
import {
    CLIENT,
    homePath, findProfessionalsPath, findJobsPath, businessListingPath, findBusinessesPath,
    loginPath, signupPath,
    dashboardPath,
} from "../../assets/constants"

const NavLink = ({ label, path, onClick }) => (
    <Link
        to={path}
        type="button"
        onClick={onClick}
        className="text-versich-darktext-color hover:text-versich-blue font-medium px-4 md:px-0 py-3 md:py-2 transition-colors"
    >
        {label}
    </Link>
)

const LinkOrButton = ({ className, label, path, resetClick }) => (
    <Link
        className={className}
        to={path}
        type="button"
        onClick={resetClick}
    >
        {label}
    </Link>
)

const Navbar = () => {
    const { user } = useSelector((state) => state.auth)
    const [mobileMenu, setMobileMenu] = useState(false)

    const isClient = user?.accountType === CLIENT
    const hideMobileMenu = () => setMobileMenu(false)
    return (
        <nav className="bg-white py-3.5 px-8 md:px-16 xl:px-28 shadow-bottom relative z-20 selection:bg-transparent">
            <div className="flex justify-between items-center gap-4 smaller:gap-6 max-w-[1700px] mx-auto">
                <div className="text-versich-darktext-color hover:text-versich-blue-hover p-1 sm401:p-2 border border-versich-darktext-color/20 hover:border-versich-blue/60 inner_shadow rounded-md sm401:rounded-xl cursor-pointer xl1180:hidden" onClick={() => setMobileMenu(!mobileMenu)}>
                    {mobileMenu
                        ? <MdClose className="text-2xl" />
                        : <CgMenuLeft className="text-2xl" />
                    }
                </div>
                <div className="max-xl1180:flex-1">
                    <Link to={homePath} className="flex items-center" onClick={hideMobileMenu}>
                        <img src={logo} alt="logo" className="w-[140px] smaller:w-[160px] lg:w-[180px]" />
                    </Link>
                </div>
                <div className={`bg-white bg-opacity-90 backdrop-filter backdrop-blur-md xl1180:bg-transparent xl1180:flex xl1180:gap-x-7 flex-col xl1180:flex-row xl1180:mt-0 w-full px-10 xl1180:px-0 pt-3 pb-7 xl1180:py-0 shadow-bottom xl1180:shadow-none absolute left-0 xl1180:relative xl1180:visible
                    ${mobileMenu ? "top-[100%] visible" : "hidden"}`}
                >
                    <div className="text-white flex justify-center flex-col xl1180:flex-row xl1180:gap-x-7 smallest:max-xl1180:max-w-[250px] mx-auto xl1180:ml-auto xl1180:mr-0">
                        <NavLink label="Find Professionals" path={findProfessionalsPath} onClick={hideMobileMenu} />
                        {!isClient && <NavLink label="Find Job" path={findJobsPath} onClick={hideMobileMenu} />}
                        <NavLink label="List Your Business" path={businessListingPath} onClick={hideMobileMenu} />
                        <NavLink label="Find Businesses" path={findBusinessesPath} onClick={hideMobileMenu} />
                        {user !== null
                            ? <LinkOrButton className="primary_btn_mobile_link max-xl1180:hidden" label="Dashboard" path={dashboardPath} resetClick={hideMobileMenu} />
                            : <>
                                <LinkOrButton className="secondary_btn sm:max-xl1180:hidden max-sm:mt-3" label="Login" path={loginPath} resetClick={hideMobileMenu} />
                                <LinkOrButton className="primary_btn_mobile_link max-xl1180:hidden" label="Sign up" path={signupPath} resetClick={hideMobileMenu} />
                            </>
                        }
                    </div>
                </div>
                <div className="flex xl1180:hidden items-center justify-center flex-row gap-x-4">
                    {user !== null
                        ? <LinkOrButton className="primary_btn_mobile_link" label="Dashboard" path={dashboardPath} resetClick={hideMobileMenu} />
                        : <>
                            <LinkOrButton className="secondary_btn max-sm:hidden" label="Login" path={loginPath} resetClick={hideMobileMenu} />
                            <LinkOrButton className="primary_btn_mobile_link" label="Sign up" path={signupPath} resetClick={hideMobileMenu} />
                        </>
                    }
                </div>
            </div>
        </nav >
    )
}
export default Navbar
