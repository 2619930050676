import { useSelector, useDispatch } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { useState } from "react"

import Meta from "../../../components/Meta"
import BrandBar from "../../../components/Navbar/BrandBar"
import FormLoader from "../../../components/loader/FormLoader"
import AddEducation from "../../../components/Dashboard/accountSettings/freelancer/education/AddEducation"
import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import toastify from "../../../utils/toastify"
import {
    TOASTTIMER, TOASTTYPE_ERROR,
    completeExperiencePath, dashboardPath
} from "../../../assets/constants"
import { freelancerEducationSetAsync } from "../../../slices/freelancer/education"

const metadata = {
    title: "Complete Profile Details",
    description: "Complete user profile details to become a verified user",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/complete/education/`
}

const FreelancerEducationForm = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [education, setEducation] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setSubmitted(true)
        const educationKeys = Object.keys(education)
        const emptyDetails = educationKeys.filter(k =>
            education[k].instituteName === "" ||
            education[k].programStartYear === 0 ||
            education[k].programEndYear === 0 ||
            education[k].degree === "" ||
            education[k].fieldOfStudy === "" ||
            education[k].programEndYear < education[k].programStartYear
        )
        if (emptyDetails.length > 0) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }
        setLoading(true)
        const formData = {
            education: educationKeys.map(k => {
                return {
                    institute_name: education[k].instituteName,
                    program_start: education[k].programStartYear,
                    program_end: education[k].programEndYear,
                    degree: education[k].degree,
                    field_of_study: education[k].fieldOfStudy
                }
            }),
            token: csrfToken
        }
        dispatch(freelancerEducationSetAsync(formData))
            .unwrap()
            .then(() => navigate(completeExperiencePath))
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }
    return (
        !user.education
            ? <div>
                <Meta data={metadata} />
                <BrandBar />
                <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                    <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight">
                        <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                            <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Academic Information</h1>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-5">
                            <AddEducation maximum={4} minimum={1} submitted={submitted} education={user.education} setEducation={setEducation} />
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
            : <Navigate to={completeExperiencePath} replace />
    )
}
export default FreelancerEducationForm
