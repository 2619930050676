import fullStarIcon from "../../../../../assets/svgs/review-star.svg"
import halfStarIcon from "../../../../../assets/svgs/review-star-half.svg"
import noStarIcon from "../../../../../assets/svgs/review-star-disabled.svg"

const ReviewStars = ({ total = 5, average, className }) => {
    return (
        <div className={"inline-flex "
            + (className ? className : "gap-2")
        }>
            {[...Array(total)].map((item, index) => (
                <img className="w-4 h-4 smaller:w-[18px] smaller:h-[18px] xl:w-[23px] xl:h-[23px]" src={
                    index + 1 <= average
                        ? fullStarIcon
                        : index >= average
                            ? noStarIcon
                            : halfStarIcon
                } alt={total} key={index} />
            ))}
        </div>
    )
}
export default ReviewStars