import { memo, useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { GrSend } from "react-icons/gr"

import { toConversationDetails } from "../../../assets/path"

const ProposalStatusCard = memo(({ restriction }) => {
    const { reason, conversationId } = restriction
    const navigate = useNavigate()

    // Handler to view conversation details
    const viewMessage = useCallback((id) => {
        toConversationDetails(id, navigate)
    }, [navigate])
    return (
        <div
            className={"bg-white text-sm md:text-base my-4 md:my-8 p-4 sm:p-5 lg:p-6 border rounded-2xl "
                + (conversationId ? "border-versich-light-blue/20" : "border-majorelle-blue/30")
            }
            role="alert"
            aria-live="polite"
        >
            {conversationId
                ? <p className="text-versich-lighter-blue/90 flex max-smaller:flex-col smaller:items-center gap-2">
                    <GrSend className="min-w-5 w-6 h-6 opacity-80" aria-hidden="true" />
                    <span>{reason}</span>
                    <div className="flex-1 smaller:text-right max-smaller:pt-3 smaller:pl-2">
                        <button className="accent_btn" onClick={() => viewMessage(conversationId)}>Message</button>
                    </div>
                </p>
                : <p className="text-majorelle-blue/90 flex items-center gap-2">
                    <GrSend className="min-w-5 w-6 h-6 opacity-80" aria-hidden="true" />
                    <span>{reason}</span>
                </p>
            }
        </div>
    )
})
export default ProposalStatusCard