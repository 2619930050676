import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import ComboServicesInputField from "../../../../components/Inputs/ComboServicesInputField"
import { dashboardPath, freelancerPaths } from "../../../../assets/constants"
import { freelancerOtherRemainingServicesAsync, freelancerOtherServiceUpdateAsync } from "../../../../slices/freelancer/otherService"
import ServicesList from "../../../../assets/ServicesList"

const metadata = {
    title: "Setup - Other Services",
    description: "Kindly proceed to add additional services for more leads",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/su/freelancer/other-services/`
}

const skipToPath = freelancerPaths.servicedetails

const OtherServicesForm = () => {
    const { user } = useSelector((state) => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [selectedServices, setSelectedServices] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        if (selectedServices.length > 0) {
            const formData = {
                token: csrfToken,
                serviceIds: selectedServices
            }
            setLoading(true)
            await dispatch(freelancerOtherServiceUpdateAsync(formData))
                .unwrap()
                .then(() => navigate(skipToPath))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        }
        else setErrorMsg("No services selected, make sure you select atleast one or click 'skip' to continue.")
    }

    // Set primary service icon
    const [icon, setIcon] = useState("")
    useEffect(() => {
        const serviceIcons = ServicesList.filter(s => s.uid === user.primaryService.uid)
        if (serviceIcons.length > 0) {
            setIcon(serviceIcons[0].icon)
        }
    }, [user])
    return (
        <div>
            <Meta data={metadata} />
            <BrandBar />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <form className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight" onSubmit={handleSubmit}>
                    <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                    <div className="space-y-3 pb-5 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium">Maximize your leads</h1>
                        <p className="text-versich-label text-xs sm:text-sm font-medium">Add other services you can provide</p>
                    </div>
                    <div className="pt-5 pb-7 border-b border-[#EFEEEE99]">
                        <div className="bg-brandeis-blue/[0.08] text-start flex max-sm:flex-col gap-5 py-4 sm:py-6 px-6 sm:px-12 rounded-lg">
                            <div>
                                <img className="min-w-10 h-10 smaller:min-w-14 smaller:h-14" src={icon} alt={user.primaryService.title} />
                            </div>
                            <div>
                                <h3 className="text-versich-dark-blue font-medium">{user.primaryService.title}</h3>
                                <p className="text-versich-label text-sm mt-[5px]">You’ve signed up for</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-start space-y-3 py-5">
                        <h2 className="text-versich-dark-blue text-xl sm:text-2xl font-medium">Offer more services?</h2>
                        <p className="text-versich-label text-xs sm:text-sm font-medium">You can also search below for more services your can offer,  we will also show you leads from here.</p>
                        <ComboServicesInputField otherRemainingServicesAsync={freelancerOtherRemainingServicesAsync} onSelect={(e) => setSelectedServices(e)} />
                    </div>
                    {errorMsg && <div className="text-sm text-red-500 text-left !mt-5">{errorMsg}</div>}
                    <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                        <button className="bg-columbia-blue hover:opacity-80 text-versich-light-blue border border-columbia-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Back</button>
                        <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(skipToPath)}>Skip</button>
                        <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Next</button>
                    </div>
                </form>
                {loading && <FormLoader />}
            </div>
        </div>
    )
}
export default OtherServicesForm