import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const userRequestsAsync = createAsyncThunk("service/userRequests", async (isClient) => {
    try {
        const { data } = isClient ? await api.clientUserRequests() : await api.nonClientUserRequests()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const latestClientUserRequestAsync = createAsyncThunk("service/latestClientUserRequest", async () => {
    try {
        const { data } = await api.latestClientUserRequest()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestAsync = createAsyncThunk("service/getRequest", async (refId) => {
    try {
        const { data } = await api.getRequest(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getRequestTitleAsync = createAsyncThunk("service/getRequestTitle", async (refId) => {
    try {
        const { data } = await api.getRequestTitle(refId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})