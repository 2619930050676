import { useNavigate } from "react-router-dom"

import pattern1 from "../../../assets/images/pattern-1.png"
import { findProfessionalsPath } from "../../../assets/constants"

const FindTalentByCategory = () => {
    const navigate = useNavigate()
    return (
        <div className="py-12 md:py-16 px-8 md:px-16 xl:px-28">
            <div className="max_screen_w">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-10">
                    <div className="text-left flex flex-col px-4 py-0 md:p-6">
                        <h2 className="text-versich-darktext-color text-2xl smaller:text-3xl lg:text-4xl font-bold mt-4 pb-2 relative">Find Talent by Service Category</h2>
                        <p className="max-smaller:text-sm xl:text-[18px] inline-flex flex-col my-2 md:my-4 relative">Discover skilled professionals across diverse fields such as design, writing, marketing, and more. Find the perfect fit for your project.</p>
                        <div className="max-md:mb-2 my-6 relative">
                            <button className="primary_btn" onClick={() => navigate(findProfessionalsPath)}>Find Talent</button>
                        </div>
                    </div>
                    <div className="md:flex items-center justify-center md:justify-end">
                        <div className="bg-[#6473B6] pl-[22px] pb-[30px] sm:pl-7 sm:pb-8 md:pl-8 md:pb-9 rounded-2xl">
                            <img className="max-md:mx-auto" src={pattern1} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FindTalentByCategory
