import { memo, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"

import ProposalStatusCard from "./ProposalStatusCard"
import JobDetailsCard from "./JobDetailsCard"
import JobProposalForm from "./JobProposalForm"
import { getHasRequiredBalance } from "./FreelancerProposalStatus"
import {
    BUSINESS, FREELANCER,
    businessPricingPath, buyVersiGemsPath,
    COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION,
} from "../../../assets/constants"
import { getFullJobPath } from "../../../assets/path"

const JobDetailsWithProposalForm = ({ request }) => {
    const { refId, restriction } = request
    const { pathname, search } = useLocation()
    const navigate = useNavigate()

    // Get user auth details.
    const { user } = useSelector(state => state.auth)
    const isBusiness = user.accountType === BUSINESS
    const isFreelancer = user.accountType === FREELANCER

    // Derived navigation: full view job path
    const viewFullJobPath = getFullJobPath(refId)

    // Check if a proposal was already sent
    const restrictionReason = restriction?.reason

    // Navigation helpers: redirect to upgrade or buy VersiGems pages
    const upgradeOrBuyVersiGems = useCallback(
        (toPath) => {
            const path = window.encodeURIComponent(pathname + search)
            window.sessionStorage.setItem(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, path)
            navigate(toPath)
        },
        [pathname, search, navigate]
    )
    const upgradeToPremium = useCallback(
        () => upgradeOrBuyVersiGems(businessPricingPath),
        [upgradeOrBuyVersiGems]
    )
    const navigateToBuyVersiGemsPage = useCallback(
        () => upgradeOrBuyVersiGems(buyVersiGemsPath),
        [upgradeOrBuyVersiGems]
    )

    // Freelancer 'VersiGems' balance
    const freelancerHasRequiredBalance = isFreelancer && getHasRequiredBalance(user.versigems, restriction?.gems)

    // Premium Plan check
    const businessUserHasPremiumPlan = isBusiness && user.plan && user.plan.name === "premium" && user.plan.status === "active"
    return (<>
        {restrictionReason && <ProposalStatusCard restriction={restriction} />}
        <JobDetailsCard
            request={request}
            viewFullJobPath={viewFullJobPath}
            restrictionReason={restrictionReason}
            isFreelancer={isFreelancer}
            isBusiness={isBusiness}
            businessUserHasPremiumPlan={businessUserHasPremiumPlan}
            userVersiGems={user.versigems}
            upgradeToPremium={upgradeToPremium}
            navigateToBuyVersiGemsPage={navigateToBuyVersiGemsPage}
        />
        {!restrictionReason &&
            <JobProposalForm
                refId={refId}
                viewFullJobPath={viewFullJobPath}
                freelancerHasRequiredBalance={freelancerHasRequiredBalance}
                businessUserHasPremiumPlan={businessUserHasPremiumPlan}
            />
        }
    </>)
}
export default memo(JobDetailsWithProposalForm)