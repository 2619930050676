import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const globalSubmitRequestAsync = createAsyncThunk("website/globalSubmitRequest",
    async ({ email, accounttype, description, attachment, verifytext, token }) => {
        try {
            const formData = new FormData()
            formData.append("email", email)
            formData.append("accounttype", accounttype)
            formData.append("description", description)
            formData.append("attachment", attachment)
            formData.append("verifytext", verifytext)
            formData.append("csrfmiddlewaretoken", token)
            const { data } = await api.globalSubmitRequest(formData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })