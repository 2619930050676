import { useState } from "react"

import UnlockWithVersiGemsDialog, { unlockWithVersiGemsDialog } from "../../Dashboard/freelancer/UnlockWithVersiGemsDialog"
import RequestCardLayout from "./RequestCardLayout"
import DashboardConfirmBtn from "../../Buttons/DashboardConfirmBtn"
import { openDialog } from "../../DialogBox"
import { freelancerUnlockOptions } from "../../../assets/constants"
import { unlockFreelancerRequestInviteRestrictionAsync } from "../../../slices/message"

const FreelancerRequestCard = ({ data, viewRequest, setRequiredVersiGems }) => {
    // Handle 'unlock' button click
    const handleUnlockClick = (e) => {
        openDialog(e)
        setRequiredVersiGems({
            ...freelancerUnlockOptions.request,
            value: data.restriction.gems,
            title: data.details.title,
            id: data.restriction.restriction_id
        })
    }
    return (
        <RequestCardLayout data={data}>
            <DashboardConfirmBtn
                onClick={
                    data.refId
                        ? () => viewRequest(data.refId)
                        : handleUnlockClick
                }
                dataParentId={unlockWithVersiGemsDialog}
            >
                View Request
            </DashboardConfirmBtn>
        </RequestCardLayout>
    )
}

const FreelancerRequestCards = ({ requests, viewRequest }) => {
    const [requiredVerisGems, setRequiredVersiGems] = useState(0)
    const [versiGemsSpent, setVersiGemsSpent] = useState(null)

    // Unlock action
    const unlockRequestInviteAsync = (id) => {
        return unlockFreelancerRequestInviteRestrictionAsync(id)
    }
    const navigateToRequest = (data) => viewRequest(data.refId)
    return (<>
        <UnlockWithVersiGemsDialog
            requiredVerisGems={requiredVerisGems}
            unlockAsync={unlockRequestInviteAsync}
            triggerAfterSuccess={navigateToRequest}
            versiGemsSpent={versiGemsSpent}
            setVersiGemsSpent={setVersiGemsSpent}
        />
        {requests.map((data, index) => (
            <FreelancerRequestCard
                data={data}
                viewRequest={viewRequest}
                setRequiredVersiGems={setRequiredVersiGems}
                key={index}
            />
        ))}
    </>)
}
export default FreelancerRequestCards