import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import SearchButton from "../Buttons/SearchButton"

const SearchBar = ({ id, placeholder, setQuery, initialSearch, parentPath }) => {
    const [phrase, setPhrase] = useState(initialSearch || "")

    // Set default
    useEffect(() => {
        setPhrase(initialSearch || "")
    }, [initialSearch])

    // Update search query and navigate.
    const navigate = useNavigate()
    const navigateToPhrase = () => {
        setQuery(phrase)
        const string = phrase ? `?search=${encodeURIComponent(phrase)}` : ""
        navigate(parentPath + string, { replace: true })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        navigateToPhrase()
        if (submitBtn) {
            submitBtn.disabled = false
        }
    }

    // // Reset 'search' when empty (Can be changed to clear search & filter on button click)
    // useEffect(() => {
    //     phrase === "" && navigateToPhrase()
    // }, [phrase])
    return (
        <form className="bg-white flex sm:mt-2 pl-1.5 p-[3px] sm:p-1.5 lg:p-2 border border-versich-darktext-color/20 rounded-xl relative" onSubmit={handleSubmit}>
            <label htmlFor={id} className="w-full h-full absolute inset-0"></label>
            <div className="flex-1 flex flex-col gap-y-4">
                <div className="h-full relative z-50">
                    <input
                        id={id}
                        className="text-black max-sm:text-sm w-full min-w-0 h-full p-1.5 lg:p-2 outline-none rounded-xl"
                        name="phrase"
                        type="text"
                        onChange={(e) => setPhrase(e.target.value)}
                        value={phrase}
                        placeholder={placeholder}
                    />
                </div>
            </div>
            <SearchButton />
        </form>
    )
}
export default SearchBar
