
const SERVICE_MAP = {
    "web-design-and-development": "Web Design and Development",
    "mobile-app": "Mobile App",
    "data-and-analytics": "Data and Analytics",
    "software-development": "Software Development",
    "finance-transformation": "Finance Transformation",
    "machine-learning-and-ai": "Machine Learning and AI",
}

const DATA_ANALYTICS_MAP = {
    "data-and-analytics--business": "Data and Analytics (Business)",
    "data-and-analytics--personal": "Data and Analytics (Personal)",
}

const FINANCE_TRANSFORMATION_MAP = {
    "finance-transformation--fp-and-a-revolution": "FP & A Revolution",
    "finance-transformation--financial-reporting-and-advanced-analytics":
        "Financial Reporting and Advanced Analytics",
    "finance-transformation--enterprise": "ERP / EPM / System Implementation",
    "finance-transformation--system-administration": "Systems Administration",
    "finance-transformation--digital-transformation": "Digital Transformation",
    // "finance-transformation--other": "Finance Transformation",
}

export const fetchService = (id) => {
    return SERVICE_MAP[id] || null
}

export const fetchSubService = (service, id) => {
    if (service === "data-and-analytics") {
        return DATA_ANALYTICS_MAP[id] || null
    } else if (service === "finance-transformation") {
        return FINANCE_TRANSFORMATION_MAP[id] || null
    }
    return null
}
