import { FormProvider, useForm } from "react-hook-form"
import { useState } from "react"
import { useDispatch } from "react-redux"

import Meta from "../../components/Meta"
import hero from "../../assets/images/contact-hero.jpg"
import messageIcon from "../../assets/icons/dashboard/message.svg"
import callingIcon from "../../assets/icons/dashboard/calling.svg"
import ConfirmationDialog, { confirmationDialog } from "../../components/ConfirmationDialog"
import FormLoader from "../../components/loader/FormLoader"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import InputText from "../../components/Inputs/InputTextField"
import TextAreaField from "../../components/Inputs/TextAreaField"
import PhoneNumberField from "../../components/Inputs/PhoneNumberField"
import toastify from "../../utils/toastify"
import { TOASTTYPE_ERROR } from "../../assets/constants"
import { closeDialog, openDialog } from "../../components/DialogBox"
import { sendContactMessageAsync } from "../../slices/website/contact"

const metadata = {
    title: "Contact Us | Get in Touch",
    description: "Reach out to us for any inquiries, support, or feedback. We're here to help you with all your needs.",
    url: `${process.env.REACT_APP_CLIENT_URL}/contact-us/`
}

const ContactUs = () => {
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [message, setMessage] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneCode("")
        setPhoneNumber("")
        setPhoneNumberValid(false)
        setMessage("")
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                firstname: data.firstName,
                lastname: data.lastName,
                email: data.email,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                message: data.message,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(sendContactMessageAsync(formData))
                .unwrap()
                .then(() => openDialog(event))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div>
            <Meta data={metadata} />
            <div style={{ backgroundImage: `url(${hero})` }} className="bg-cover bg-center bg-no-repeat">
                <div className="bg-versich-light-blue/60 text-white w-full h-full px-8 md:px-20 lg:px-40 xl:px-60 largeLaptop:px-80 pt-12 sm:pt-16 lg:pt-24 pb-16 sm:pb-24 lg:pb-32">
                    <div className="text-4xl lg:text-5xl font-bold">
                        <h1 className="leading-tight">Contact Us</h1>
                    </div>
                </div>
            </div>
            <div className="bg-white py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 sm:py-12 rounded-md shadow-formLight">
                    <div className="space-y-5 pb-6 sm:pb-10 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-darktext-color text-xl sm:text-2xl font-bold flex justify-center">Fill out the form below to get in touch with us.</h1>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-8 sm:mt-12 space-y-5" data-parent-id={confirmationDialog}>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                <InputText name="firstName" inputType="text" label="First Name" rules={{ required: "First name is required" }} onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                                <InputText name="lastName" inputType="text" label="Last Name" rules={{ required: "Last name is required" }} onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                            </div>
                            <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                <InputText
                                    name="email"
                                    inputType="email"
                                    label="Email Address"
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                    rules={{ required: "Email is required" }}
                                />
                                <PhoneNumberField
                                    name="phonenumber"
                                    rules={{ required: "Phone number is required" }}
                                    setPhoneCode={setPhoneCode}
                                    setPhoneNumber={setPhoneNumber}
                                    setPhoneNumberValid={setPhoneNumberValid}
                                    value={phoneCode + phoneNumber}
                                />
                            </div>
                            <TextAreaField
                                name="message"
                                label="Message"
                                onChange={(e) => setMessage(e.target.value)}
                                defaultValue={message}
                                rules={{ required: "Message is required" }}
                                height="min-h-60 max-h-[528px]"
                            />
                            {errorMsg && <div className="text-sm text-red-500 text-left !mt-6">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full py-6">
                                <button className="primary_btn md:py-3 !px-6 lg:!px-10" type="submit">Send</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
            <div className="mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20">
                    <h2 className="text-versich-darktext-color text-xl sm:text-2xl font-bold text-left">Alternatively, Get in Touch</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:gap-10 mt-10">
                        <a
                            className="bg-versich-light-blue/5 min-h-40 flex flex-col items-center justify-center gap-4 p-5 sm:p-6 border border-transparent hover:border-versich-light-blue/10 shadow-card4 rounded-xl group transition-colors"
                            href="mailto:Hello@versimarket.com"
                            style={{ overflowWrap: "anywhere" }}
                        >
                            <img className="w-12 h-12 opacity-70" src={messageIcon} alt="" />
                            <p>Email us</p>
                            <span className="text-versich-blue group-hover:underline underline-offset-2 transition-colors" >Hello@versimarket.com</span>
                        </a>
                        <a
                            className="bg-versich-light-blue/5 min-h-40 flex flex-col items-center justify-center gap-4 p-5 sm:p-6 border border-transparent hover:border-versich-light-blue/10 shadow-card4 rounded-xl group transition-colors"
                            href="tel:+447782350419"
                            style={{ overflowWrap: "anywhere" }}
                        >
                            <img className="w-12 h-12 opacity-70" src={callingIcon} alt="" />
                            <p>Call us</p>
                            <span className="text-versich-blue group-hover:underline underline-offset-2 transition-colors" >+44 7782 350419</span>
                        </a>
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                title="Message Sent"
                description="Thank you for reaching out. We will respond as soon as possible."
                customCloseEvent={customCloseDialog}
            />
            {loading && <FormLoader />}
        </div>
    )
}
export default ContactUs
