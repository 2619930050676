import { BiSolidLockOpen } from "react-icons/bi"

const UnlockButton = ({ className, onClick, label, dataParentId }) => {
    return (
        <button className={"text-versich-blue hover:bg-versich-blue/10 text-sm font-medium inline-flex items-center gap-1 border-2 border-versich-blue rounded-xl relative "
            + (className ? className : "px-2.5 lg:px-3.5 py-2.5")
        }
            onClick={onClick}
            data-parent-id={dataParentId}
        >
            <div className="w-full h-full absolute z-10 inset-0" data-parent-id={dataParentId} />
            <BiSolidLockOpen /> {label}
        </button>
    )
}
export default UnlockButton