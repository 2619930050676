import { useState } from "react"

import applicationIcon from "../../../../../../../assets/icons/request/application.png"
import RequestFormLayout from "../../../components/RequestFormLayout"
import CheckboxSearchOptions from "../../../components/CheckboxSearchOptions"
import { allExpertServiceAsync } from "../../../../../../../slices/client/service/financeTransformation/FRAA"

const ExpertService = ({ totalForms, formPosition, updateFormPosition, defaultValue, setServices, goBack }) => {
    const [values, setValues] = useState("")
    const [error, setError] = useState(false)

    const handleContinue = () => {
        if (values) {
            setServices(values.map(tool => tool.uid))
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <RequestFormLayout
            icon={applicationIcon}
            description="Finance Reporting / Advanced Analytics Service you need"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">Select services you need</p>
                <CheckboxSearchOptions
                    actionAsync={allExpertServiceAsync}
                    updateFormPosition={updateFormPosition}
                    noSearchBar={true}
                    defaultValue={defaultValue}
                    values={values}
                    setValues={setValues}
                    error={error}
                />
            </div>
        </RequestFormLayout>
    )
}
export default ExpertService
