import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../ServiceRequestLayout"
import SoftwareType from "./components/SoftwareType"
import SoftwareNature from "./components/SoftwareNature"
import OperatingSystem from "./components/OperatingSystem"
import IndustryType from "./components/IndustryType"
import Budget from "../components/Budget"
import ServiceEngagement from "./components/ServiceEngagement"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import TitleAndDescription from "../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_SD } from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/softwareDevelopment"

const metadata = {
    title: "New Request - Software Development Service",
    description: "Software development new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/software-development/`
}

const SoftwareDevelopment = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [softwareType, setSoftwareType] = useState("")
    const [otherSoftwareType, setOtherSoftwareType] = useState(null)
    const [softwareNature, setSoftwareNature] = useState("")
    const [otherSoftwareNature, setOtherSoftwareNature] = useState(null)
    const [operatingSystem, setOperatingSystem] = useState("")
    const [otherOperatingSystem, setOtherOperatingSystem] = useState(null)
    const [industryType, setIndustryType] = useState("")
    const [otherIndustryType, setOtherIndustryType] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [serviceEngagement, setServiceEngagement] = useState("")
    const [otherServiceEngagement, setOtherServiceEngagement] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.softwareType && setSoftwareType(parsedData.softwareType)
        parsedData.otherSoftwareType && setOtherSoftwareType(parsedData.otherSoftwareType)
        parsedData.softwareNature && setSoftwareNature(parsedData.softwareNature)
        parsedData.otherSoftwareNature && setOtherSoftwareNature(parsedData.otherSoftwareNature)
        parsedData.operatingSystem && setOperatingSystem(parsedData.operatingSystem)
        parsedData.otherOperatingSystem && setOtherOperatingSystem(parsedData.otherOperatingSystem)
        parsedData.industryType && setIndustryType(parsedData.industryType)
        parsedData.otherIndustryType && setOtherIndustryType(parsedData.otherIndustryType)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.serviceEngagement && setServiceEngagement(parsedData.serviceEngagement)
        parsedData.otherServiceEngagement && setOtherServiceEngagement(parsedData.otherServiceEngagement)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
    }, [])

    // Generate request data
    const formData = {
        softwareType,
        ...(otherSoftwareType && { otherSoftwareType }),
        softwareNature,
        ...(otherSoftwareNature && { otherSoftwareNature }),
        operatingSystem,
        ...(otherOperatingSystem && { otherOperatingSystem }),
        industryType,
        ...(otherIndustryType && { otherIndustryType }),
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        serviceEngagement,
        ...(otherServiceEngagement && { otherServiceEngagement }),
        hiringDecision,
        ...(otherHiringDecision && { otherHiringDecision }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 9
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!softwareType ? (
                <SoftwareType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    defaultValue={searchParams.get("softwareType")}
                    setSoftwareType={setSoftwareType}
                    setOtherSoftwareType={setOtherSoftwareType}
                    goBack={() => navigate(newRequestPath)}
                />
            ) : !softwareNature ? (
                <SoftwareNature
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    setSoftwareNature={setSoftwareNature}
                    setOtherSoftwareNature={setOtherSoftwareNature}
                    goBack={() => setSoftwareType("")}
                />
            ) : !operatingSystem ? (
                <OperatingSystem
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    defaultValue={searchParams.get("operatingSystem")}
                    setOperatingSystem={setOperatingSystem}
                    setOtherOperatingSystem={setOtherOperatingSystem}
                    goBack={() => setSoftwareNature("")}
                />
            ) : !industryType ? (
                <IndustryType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    setIndustryType={setIndustryType}
                    setOtherIndustryType={setOtherIndustryType}
                    goBack={() => setOperatingSystem("")}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setIndustryType("")}
                />
            ) : !serviceEngagement ? (
                <ServiceEngagement
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(6)}
                    setServiceEngagement={setServiceEngagement}
                    setOtherServiceEngagement={setOtherServiceEngagement}
                    goBack={() => setBudget("")}
                />
            ) : !hiringDecision ? (
                <HiringDecision
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(7)}
                    setHiringDecision={setHiringDecision}
                    setOtherHiringDecision={setOtherHiringDecision}
                    goBack={() => setServiceEngagement("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(8)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setHiringDecision("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(9)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setHiringDecision("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const SoftwareDevelopmentLayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_SD
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <SoftwareDevelopment cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default SoftwareDevelopmentLayout