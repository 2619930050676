import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const getConversationsAsync = createAsyncThunk("message/getConversations", async () => {
    try {
        const { data } = await api.getConversations()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createConversationAsync = createAsyncThunk("message/createConversation", async ({ requestRefId, participantId, content, token }) => {
    try {
        const { data } = await api.createConversation({ requestRefId, participantId, content, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getConversationMessagesAsync = createAsyncThunk("message/getConversationMessages", async (conversationId) => {
    try {
        const { data } = await api.getConversationMessages(conversationId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const sendConversationMessageAsync = createAsyncThunk("message/sendConversationMessage", async ({ conversationId, content, token }, { dispatch }) => {
    try {
        const { data } = await api.sendConversationMessage({ id: conversationId, content, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getClientRequestInvitesAsync = createAsyncThunk("message/getClientRequestInvites", async (requestRefId) => {
    try {
        const { data } = await api.getClientRequestInvites(requestRefId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const unlockFreelancerRequestInviteRestrictionAsync = createAsyncThunk("service/unlockFreelancerRequestInviteRestriction", async (id, { dispatch }) => {
    try {
        const { data } = await api.unlockFreelancerRequestInviteRestriction(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const directContactAsGuestAsync = createAsyncThunk("account/directContactAsGuest",
    async ({ userId, firstname, lastname, email, phonecode, phonenumber, content, token }) => {
        try {
            const { data } = await api.directContactAsGuest({ userId, firstname, lastname, email, phonecode, phonenumber, content, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })