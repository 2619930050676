import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import { getUser } from "../actions/auth"
import apiErrorParser from "../utils/apiErrorParser"

export const allLanguagesAsync = createAsyncThunk("account/all-languages", async () => {
    try {
        const { data } = await api.getAllLanguages()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data?.payload
    }
    catch (error) {
        throw error
    }
})

export const updateProfilePictureAsync = createAsyncThunk("account/updateProfilePicture", async ({ file, token }, { dispatch }) => {
    try {
        const formData = new FormData()
        formData.append("image", file)
        formData.append("csrfmiddlewaretoken", token)
        const { data } = await api.updateProfilePicture(formData)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const checkEmailAndSendVerificationCodeAsync = createAsyncThunk("account/checkEmailAndSendVerificationCode", async ({ email, token }) => {
    try {
        const { data } = await api.checkEmailAndSendVerificationCode({ email, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const verifyCodeAndUpdateEmailAsync = createAsyncThunk("account/verifyCodeAndUpdateEmail", async ({ email, code, token }, { dispatch }) => {
    try {
        const { data } = await api.verifyCodeAndUpdateEmail({ email, code, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})