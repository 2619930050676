import { useNavigate } from "react-router-dom"

import AddPlus from "../../svgs/AddPlus"
import RequestCardLayout from "./RequestCardLayout"
import DashboardConfirmBtn from "../../Buttons/DashboardConfirmBtn"
import { unlockWithVersiGemsDialog } from "../../Dashboard/freelancer/UnlockWithVersiGemsDialog"
import { newRequestPath } from "../../../assets/constants"

export const ClientRequestCard = ({ data, viewRequestDetails }) => (
    <RequestCardLayout data={data}>
        <DashboardConfirmBtn onClick={viewRequestDetails} dataParentId={unlockWithVersiGemsDialog}>View Request</DashboardConfirmBtn>
    </RequestCardLayout>
)

const ClientRequestCards = ({ requests, viewRequest }) => {
    const navigate = useNavigate()
    const navigateToNewRequest = () => navigate(newRequestPath)
    return (<>
        <div className="flex gap-4 justify-end max-smaller:items-center pb-3">
            <button className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group" onClick={navigateToNewRequest} type="button" title="New request">
                <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                <span className="max-smaller:hidden">New request</span>
            </button>
        </div>
        {requests.map((data, index) => (
            <ClientRequestCard data={data} viewRequestDetails={() => viewRequest(data.refId)} key={index} />
        ))}
    </>)
}
export default ClientRequestCards