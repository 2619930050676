import { useForm } from "react-hook-form"
import { useState } from "react"
import { useDispatch } from "react-redux"

import DirectContactDialogLayout from "./DirectContactDialogLayout"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import InputText from "../../Inputs/InputTextField"
import PhoneNumberField from "../../Inputs/PhoneNumberField"
import TextAreaField from "../../Inputs/TextAreaField"
import { closeDialog, getDialog } from "../../DialogBox"
import { TOASTTIMER, TOASTTYPE_ERROR } from "../../../assets/constants"
import { directContactAsGuestAsync } from "../../../slices/message"
import toastify from "../../../utils/toastify"

const GuestDirectContactDialog = ({ userId, setContacted }) => {
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [message, setMessage] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setPhoneCode("")
        setPhoneNumber("")
        setPhoneNumberValid(false)
        setMessage("")
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            setLoading(true)
            const formData = {
                userId,
                firstname: data.firstName,
                lastname: data.lastName,
                email: data.email,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                content: message,
                token: csrfToken,
            }
            const closeDialogAndSetNotification = (form, message, conversationId = 0) => {
                const dialog = getDialog(form)
                if (dialog) {
                    closeDialog(null, dialog)
                }
                setContacted({
                    status: true,
                    message, conversationId
                })
                setTimeout(() => {
                    setContacted({
                        status: false,
                        message: "",
                        conversationId: 0
                    })
                }, TOASTTIMER * 6)
            }
            await dispatch(directContactAsGuestAsync(formData))
                .unwrap()
                .then((data) => {
                    closeDialogAndSetNotification(event.target, "Message sent to business.")
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setLoading(false)
                    if (data.status === TOASTTYPE_ERROR && data.shortInfo === "duplicate-contact") {
                        return closeDialogAndSetNotification(event.target, "Already contacted business, wait for them to reply back.")
                    }
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <DirectContactDialogLayout
            customCloseDialog={customCloseDialog}
            description="Reach out to businesses by providing your details"
            onSubmit={onSubmit}
            errorMsg={errorMsg}
            loading={loading}
        >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-5">
                <InputText name="firstName" inputType="text" placeholder="e.g. Steve" label="First Name" onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} rules={{ required: "First name is required" }} />
                <InputText name="lastName" inputType="text" placeholder="e.g. Rogers" label="Last Name" onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} rules={{ required: "Last name is required" }} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-5">
                <InputText
                    name="email"
                    inputType="email"
                    label="Email Address"
                    placeholder="Enter your email"
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={email}
                    rules={{ required: "Email is required" }}
                />
                <PhoneNumberField
                    name="phonenumber"
                    rules={{ required: "Phone number is required" }}
                    setPhoneCode={setPhoneCode}
                    setPhoneNumber={setPhoneNumber}
                    setPhoneNumberValid={setPhoneNumberValid}
                    value={phoneCode + phoneNumber}
                />
            </div>
            <TextAreaField
                name="message"
                label="Message"
                placeholder="Write your message here"
                onChange={(e) => setMessage(e.target.value)}
                defaultValue={message}
                rules={{ required: "Message is required" }}
                height="min-h-40 max-h-[400px]"
            />
            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
        </DirectContactDialogLayout>
    )
}
export default GuestDirectContactDialog