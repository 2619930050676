import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"

const RequestCardLayout = ({ children, data }) => {
    return (
        <div className="border p-3 py-6 rounded-xl flex flex-col md:flex-row gap-y-3 gap-x-4 justify-between">
            <div className="space-y-2">
                <p className="text-base lg:text-lg font-semibold text-versich-dark-blue">{data.details.title}</p>
                <div className="text-versich-light-gray text-sm inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Updated {formatDistanceToNow(data.updatedAt, { addSuffix: true })}</div>
                <p className="text-sm text-versich-label">{data.details.shortDesc}</p>
            </div>
            <div className="min-w-[140px]">
                {children}
            </div>
        </div>
    )
}
export default RequestCardLayout