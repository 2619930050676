import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, Link } from "react-router-dom"
import { FormProvider, useForm } from "react-hook-form"
import { IoIosArrowBack } from "react-icons/io"

import Meta from "../../../components/Meta"
import AuthImgBanner from "../../../components/AuthImgBanner"
import Logo from "../../../components/Logo"
import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import InputTextField from "../../../components/Inputs/InputTextField"
import ConfirmButton from "../../../components/Buttons/ConfirmButton"
import SocialAccounts from "../socialAccounts/SocialAccounts"
import {
    SS_ACCOUNT_TYPE,
    signupPath, loginPath,
    termsOfServicePath, privacyPolicyPath
} from "../../../assets/constants"
import { signupFreelancerUser } from "../../../actions/auth"

const metadata = {
    title: "Freelancer Signup",
    description: "Sign up for a new freelancer account.",
    url: `${process.env.REACT_APP_CLIENT_URL}/auth/freelancer/signup/`
}

const FreelancerSignupForm = () => {
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [notEqual, setNotEqual] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-populate user data from request forms
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const qEmail = searchParams.get("email")
        if (qEmail) {
            setEmail(qEmail)
        }
        // Fill first & last name based on user entered name
        const qName = searchParams.get("name")
        if (qName) {
            const names = String(qName).split(" ")
            if (names.length > 2) {
                setFirstName(names.slice(0, names.length - 1).join(" "))
                setLastName(names[names.length - 1])
            }
            else if (names.length === 2) {
                setFirstName(names[0])
                setLastName(names[1])
            }
            else {
                setFirstName(qName)
                setLastName("")
            }
        }
    }, [])

    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        if (data.password !== data.confirmPassword) {
            return setNotEqual(true)
        }
        setLoading(true)
        dispatch(signupFreelancerUser(firstName, lastName, email, password, csrfToken))
            .then((data) => {
                if (data.status !== "success") {
                    setErrorMsg(data.message)
                }
                sessionStorage.removeItem(SS_ACCOUNT_TYPE.key)
                methods.reset()
            })
            .catch((error) => {
                if (error.response && error.response.status) {
                    setErrorMsg(error.message)
                    return error
                }
                setErrorMsg(error.message)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const location = useLocation()
    return (
        <FormProvider {...methods}>
            <div className="grid grid-cols-1 lg:grid-cols-2 min-h-svh">
                <Meta data={metadata} />
                <AuthImgBanner />
                <div className="w-full m-auto">
                    <div className="text-start m-6 lg:m-8 !mb-0">
                        <Link className="bg-versich-border/20 inline-flex p-2 rounded-full cursor-pointer group" to={signupPath + location.search} title="Back">
                            <IoIosArrowBack className="text-xl group-hover:fill-versich-blue-hover group-active:fill-versich-blue-hover" />
                        </Link>
                    </div>
                    <div className="w-full m-auto space-y-5 lg:space-y-7 p-5 smaller:p-10 max-w-[580px] rounded-lg">
                        <Logo />
                        <div className="text-center space-y-2">
                            <h2 className="text-xl md:text-2xl lg:text-3xl leading-normal text-versich-darktext-color font-semibold">Create a <span className="text-versich-blue">Freelancer</span> account</h2>
                            <p className="text-versich-label">Join our global network of professionals today!</p>
                        </div>
                        <form className="space-y-6 lg:space-y-8 pb-6 lg:pb-8" onSubmit={methods.handleSubmit(onSubmit)}>
                            <div className="space-y-4 md:space-y-5 text-start">
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                    <InputTextField name="firstname" inputType="text" label="First Name" rules={{ required: "First name is required" }} onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                                    <InputTextField name="lastname" inputType="text" label="Last Name" rules={{ required: "Last name is required" }} onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                                </div>
                                <InputTextField
                                    name="email"
                                    inputType="email"
                                    label="Email Address"
                                    rules={{
                                        required: "Email is required",
                                        pattern: {
                                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                            message: "Email is invalid",
                                        },
                                    }}
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                />
                                <InputTextField
                                    name="password"
                                    inputType="password"
                                    label="Password"
                                    placeholder="e.g. example123&_"
                                    rules={{
                                        required: "Password is required",
                                        minLength: {
                                            value: 8,
                                            message: "Password must be at least 8 characters",
                                        },
                                    }}
                                />
                                <InputTextField
                                    name="confirmPassword"
                                    inputType="password"
                                    label="Confirm Password"
                                    placeholder="e.g. example123&_"
                                    rules={{ required: "Password is required" }}
                                    onChange={(e) => {
                                        setNotEqual(false);
                                        setPassword(e.target.value)
                                    }}
                                />
                                {notEqual && <span className="text-sm text-red-500">Passwords do not match</span>}
                                {errorMsg && <div className="text-sm text-red-500 text-left">{errorMsg}</div>}
                                <p className="text-sm text-left">
                                    By continuing, you agree to the <Link className="text-versich-blue hover:text-versich-blue-hover" to={termsOfServicePath} target="_blank">Terms of use</Link> and <Link className="text-versich-blue hover:text-versich-blue-hover" to={privacyPolicyPath} target="_blank">Privacy Policy</Link>.
                                </p>
                            </div>
                            <div className="space-y-3 md:space-y-4 text-start">
                                <ConfirmButton type="submit" loading={loading}>Create account</ConfirmButton>
                                <SocialAccounts google={{ url: process.env.REACT_APP_API_GOOGLE_OAUTH2_URL, text: "Continue with Google" }} />
                                <p className="text-sm text-center">
                                    Already have an account?{" "}
                                    <Link to={loginPath + location.search} className="text-versich-blue hover:text-versich-blue-hover hover:underline font-semibold">Log in</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </FormProvider>
    )
}
export default FreelancerSignupForm
