import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState, useRef, useEffect } from "react"

import Meta from "../../../../components/Meta"
import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import RequestConfirmationDialogForGuest, { requestConfirmationDialog } from "./components/RequestConfirmationDialogForGuest"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import {
    loginPath,
    TOASTTIMER, TOASTTYPE_ERROR
} from "../../../../assets/constants"
import { getRequestConfirmationPath } from "../../../../assets/path"
import { closeDialog, getDialog } from "../../../../components/DialogBox"
import toastify from "../../../../utils/toastify"

const ServiceRequestLayout = ({ children, metadata, clientDetails, title, setTitleError, description, setDescError, formData, createRequestAsync }) => {
    const { user } = useSelector(state => state.auth)
    const [csrfToken, setCsrfToken] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Request IDs from API response (refId, pid, serviceId)
    const [requestIDs, setRequestIDs] = useState({ refId: null, pid: null, sid: null })

    // Create a ref to latest value of requestIDs.
    const requestIDsRef = useRef(requestIDs)
    useEffect(() => {
        requestIDsRef.current = requestIDs
    }, [requestIDs])

    // Show request confirmation
    const navigate = useNavigate()
    const customOpenDialog = (event) => {
        const dialog = getDialog(event.target)
        if (!dialog) {
            return
        }
        dialog.showModal()
        dialog.addEventListener("click", confirmationCloseListener)
        return () => {
            dialog.removeEventListener("click", confirmationCloseListener)
        }
    }
    const confirmationCloseListener = (e) => {
        const dialog = e.currentTarget
        if (!dialog) {
            return
        }
        if (e.target === dialog) {
            navigateGuestToLogin(dialog)
        }
    }
    const navigateGuestToLogin = (dialog) => {
        const dynamicPath = getRequestConfirmationPath(requestIDsRef.current.refId, requestIDsRef.current.pid, requestIDsRef.current.sid)
        const encodedPath = window.encodeURIComponent(dynamicPath)
        navigate(`${loginPath}?next=${encodedPath}&email=${clientDetails.email}&name=${clientDetails.name}`)
        closeDialog(null, dialog)
    }

    // Create request
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!title) {
            setTitleError(true)
            return setTimeout(() => {
                setTitleError(false)
                submitBtn.disabled = false
            }, 2000)
        }
        if (!description) {
            setDescError(true)
            return setTimeout(() => {
                setDescError(false)
                submitBtn.disabled = false
            }, 2000)
        }

        setLoading(true)
        const formdata = {
            ...formData,
            token: csrfToken
        }
        await dispatch(createRequestAsync(formdata))
            .unwrap()
            .then((data) => {
                const payload = data.payload
                if (payload) {
                    const dynamicPath = getRequestConfirmationPath(payload.refId, payload.pid, payload.sid)
                    if (user) {
                        return navigate(dynamicPath)
                    }
                    setRequestIDs({ refId: payload.refId, pid: payload.pid, sid: payload.serviceId })
                    customOpenDialog(e)
                }
                else toastify("Something went wrong, while creating request!", TOASTTYPE_ERROR, submitBtn)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setTimeout(() => {
                    setErrorMsg("")
                    submitBtn.disabled = false
                }, TOASTTIMER)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (<>
        <Meta data={metadata} />
        <BrandBar />
        <div className="bg-versich-primary-bg mb-12 py-10 md:py-14 px-3">
            <form
                className="flex flex-col"
                onSubmit={handleSubmit}
                onKeyDown={e => e.key === "Enter" && !clientDetails && e.preventDefault()}
                data-parent-id={requestConfirmationDialog}
            >
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                {children}
            </form>
            <RequestConfirmationDialogForGuest pid={requestIDsRef.current.pid} redirectToLogin={navigateGuestToLogin} />
            <div className="max_screen_w">
                {errorMsg && <div className="text-red-500 text-sm text-right max-w-[900px] !mt-10 mx-auto">{errorMsg}</div>}
            </div>
            {loading && <FormLoader />}
        </div>
    </>)
}
export default ServiceRequestLayout