import { Link } from "react-router-dom"

import { aboutUsPath, contactUsPath, signupPath } from "../../assets/constants"

const About = () => {
    return (
        <div className="text-versich-darktext-color">
            <p className="max-smaller:text-sm xl:text-[18px] font-bold pb-4">About</p>
            <ul className="flex flex-col gap-y-3 max-smaller:text-sm">
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={aboutUsPath}>About Versimarket</Link>
                </li>
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={contactUsPath}>Contact us</Link>
                </li>
                {/* <li className="hover:text-versich-blue transition-colors">
                    <Link to="#blog">Blog</Link>
                </li> */}
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={signupPath}>Sign up</Link>
                </li>
            </ul>
        </div>
    )
}
export default About
