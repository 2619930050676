import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import UnlockedDetails from "./UnlockedDetails"
import LockedDetails from "./LockedDetails"
import { getConversationMessagesAsync } from "../../../../slices/message"

const ConversationDetails = ({ conversation }) => {
    const [messages, setMessages] = useState([])

    // Load messages
    const dispatch = useDispatch()
    const fetchMessages = () => {
        setMessages([])
        dispatch(getConversationMessagesAsync(conversation.id))
            .unwrap()
            .then(data => {
                setMessages(data.payload)
            })
            .catch(() => setMessages(null))
    }
    useEffect(() => {
        fetchMessages()
    }, [conversation])
    return (
        <div className="flex-1 flex flex-col p-4 sm401:p-6 shadow-ring2 rounded-lg">
            {messages
                ? <UnlockedDetails conversation={conversation} messages={messages} setMessages={setMessages} />
                : <LockedDetails conversation={conversation} />
            }
        </div>
    )
}
export default ConversationDetails