import { useState, useEffect } from "react"

import MiddleTextSeparator from "../../../../../pages/auth/socialAccounts/MiddleTextSeparator"
import LanguageDropdownFieldInput from "../../../../Services/LanguageDropdownFieldInput"
import DropdownFieldInput from "../../../../Services/DropdownFieldInput"
import AddPlus from "../../../../svgs/AddPlus"
import { languageProficiencyOptions } from "../../../../../assets/constantOptions"

const LanguageFields = ({ data, submitted, position, minimum, fieldStatus, removeLanguage, setLanguages }) => {
    const [languageCode, setLanguageCode] = useState("")
    const [languageCodeError, setLanguageCodeError] = useState(false)
    const [proficiency, setProficiency] = useState("")
    const [proficiencyError, setProficiencyError] = useState(false)

    // Pre-fill fields
    useEffect(() => {
        if (data && data.length >= position) {
            const d = data[position - 1]
            setLanguageCode(d.language.alpha2)
            setProficiency(d.proficiency.uid)
        }
    }, [data])

    // Track changes in languages
    const updateLanguages = (add) => {
        setLanguages(preLanguages => {
            let details
            if (add) {
                details = {
                    ...preLanguages, [position]: {
                        languageCode,
                        proficiency,
                    }
                }
            }
            else {
                let d = Object.entries({ ...preLanguages }).filter(([key, value]) => parseInt(key) <= fieldStatus.count)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateLanguages(true)
    }, [languageCode, proficiency])

    // Add/Remove language
    useEffect(() => {
        updateLanguages(fieldStatus.added)
    }, [fieldStatus])

    useEffect(() => {
        setLanguageCodeError(submitted && languageCode === "")
        setProficiencyError(submitted && proficiency === "")
    }, [submitted])
    return (
        <div className="space-y-5 language">
            {position > 1 &&
                <MiddleTextSeparator word={
                    <p className="w-[128px] mb-2.5">Language #{position}</p>
                } />
            }
            <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                <LanguageDropdownFieldInput
                    name={`languageCode_${position}`}
                    label="Select Language"
                    value={languageCode}
                    placeholder="Select"
                    setLanguageValue={setLanguageCode}
                    required="Language is required"
                    error={languageCodeError}
                />
                <DropdownFieldInput
                    name={`proficiency_${position}`}
                    label="Select Proficiency"
                    options={languageProficiencyOptions}
                    value={proficiency}
                    placeholder="Select"
                    onChange={(e) => setProficiency(e.target.value)}
                    required="Proficiencyh is required"
                    error={proficiencyError}
                />
            </div>
            <div className={"hover:opacity-80 items-center justify-end gap-x-1.5 group cursor-pointer remove_button" + (
                position > minimum ? " flex" : " hidden"
            )} onClick={removeLanguage}>
                <AddPlus className="[&>*]:stroke-red-500 rotate-45 group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform" />
                <span className="text-red-500 text-sm font-medium">Remove Language</span>
            </div>
        </div>
    )
}
export default LanguageFields