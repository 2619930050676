import { Link } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"
import { FaRegEye } from "react-icons/fa6"
import { BsEyeSlash } from "react-icons/bs"

import Avatar from "../../../../components/Avatars/Avatar"
import { BUSINESS } from "../../../../assets/constants"

const MessageCard = ({ data }) => {
    const userDetail = data.participant.details
    const userProfilePath = data.participant.accountType === BUSINESS ? `/business/${userDetail.companyId}/` : `/freelancer/${userDetail.profileId}/`
    return (
        <div className="flex flex-col p-4 sm:p-6 py-6 border border-versich-light-blue/20 rounded-2xl overflow-auto space-y-5">
            <div className="flex-1 flex max-sm:flex-col sm:items-center gap-2.5 sm:gap-3.5">
                <Link to={userProfilePath}>
                    <Avatar
                        user={data.participant}
                        fullName={userDetail.name}
                        ringClass="min-w-12 w-12 h-12 sm401:h-14 sm401:w-14 border border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10"
                        initialClass="text-sm lg:text-base"
                    />
                </Link>
                <div>
                    <h3 className="text-lg lg:text-xl font-semibold mb-1.5">
                        <Link className="text-versich-dark-blue hover:text-versich-lighter-blue inline-block transition-colors" to={userProfilePath}>
                            {userDetail.name}
                        </Link>
                    </h3>
                    <div className="text-versich-darktext-color/80 text-xs md:text-sm flex flex-col smaller:flex-row gap-y-1 gap-x-4">
                        <div className="inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Contacted {formatDistanceToNow(data.createdAt, { addSuffix: true })}</div>
                        <div className="inline-flex items-center gap-1 md:gap-1.5">
                            {data.read
                                ? <><FaRegEye className="text-versich-blue" /> <span>Message viewed</span></>
                                : <><BsEyeSlash /> <span>Message not viewed</span></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-xs sm401:text-sm pt-4 pb-2 px-2 md:pt-6 md:pb-4 border-t border-versich-light-blue/10">
                {data.proposal.split("\n").map((line, index) => (
                    <p className="min-h-[9.6px] sm401:min-h-4" key={index}>{line}</p>
                ))}
            </div>
        </div>
    )
}
export default MessageCard