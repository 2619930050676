import { Link } from "react-router-dom"
import { memo } from "react"

import Avatar from "../../Avatars/Avatar"
import ContactProfessionalViaProposalDialog from "./ContactProfessionalViaProposalDialog"
import { BUSINESS } from "../../../assets/constants"
import { titleCase } from "../../../utils"
import StartOrNavigateMessageButton from "./StartOrNavigateMessageButton"

const ProfessionalContactCard = ({ proposal }) => {
    const { professional, conversation, uid } = proposal
    const { details, accountType } = professional
    const userProfilePath = accountType === BUSINESS ? `/business/${details.companyId}/` : `/freelancer/${details.profileId}/`

    return (
        <div className="p-3 border border-versich-light-blue/20 rounded-2xl">
            <div className="flex flex-col smaller:flex-row smaller:items-center gap-y-2.5 smaller:gap-10 max-md:px-2 p-3">
                <div className="flex-grow">
                    <div className="flex max-sm:flex-col sm:items-center gap-2.5 sm:gap-3.5">
                        <Link to={userProfilePath}>
                            <Avatar
                                user={professional}
                                fullName={details.name}
                                ringClass="min-w-12 w-12 h-12 sm401:h-14 sm401:w-14 border border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10"
                                initialClass="text-sm lg:text-base"
                            />
                        </Link>
                        <div>
                            <h3 className="text-versich-darktext-color text-lg lg:text-xl font-bold lg:mb-1">
                                <Link to={userProfilePath} className="hover:text-versich-lighter-blue inline-block transition-colors">
                                    {details.name}
                                </Link>
                            </h3>
                            {details.tagline
                                ? <span className="text-versich-darktext-color text-sm lg:text-base">{details.tagline}</span>
                                : <span className="text-versich-blue text-sm lg:text-base">{titleCase(accountType)}</span>
                            }
                        </div>
                    </div>
                </div>
                <StartOrNavigateMessageButton conversation={conversation} />
            </div>
            <ContactProfessionalViaProposalDialog proposalId={uid} />
        </div>
    )
}
export default memo(ProfessionalContactCard)