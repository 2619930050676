import { useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"

import { ACCOUNT_TYPES, CLIENT, loginPath, SS_ACCOUNT_TYPE } from "../../../assets/constants"
import ErrorPage from "../../ErrorPage/ErrorPage"
import AccountSelectionFlow from "../../AccountSelectionFlow/AccountSelectionFlow"
import { useState } from "react"

const ClientOnlyRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const [accountType, setAccountType] = useState(window.sessionStorage.getItem(SS_ACCOUNT_TYPE.key))
    window.sessionStorage.removeItem(SS_ACCOUNT_TYPE.key)
    if (user) {
        if (user.accountType !== CLIENT) {
            return <ErrorPage status={403} />
        }
        return <Outlet />
    }
    if (ACCOUNT_TYPES.includes(accountType)) {
        if (accountType === CLIENT) {
            return <Outlet />
        }
        return <Navigate to={loginPath} replace />
    }
    return <AccountSelectionFlow setAccountType={setAccountType} />
}
export default ClientOnlyRoutes