import { useFormContext } from "react-hook-form"
import { useEffect } from "react"

const TextAreaField = ({ name, label, placeholder, rules, onChange, defaultValue, height, readOnly }) => {
    const { register, formState, setValue, clearErrors } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, defaultValue)
    }, [defaultValue, name])

    // Remove errors on value change
    useEffect(() => {
        clearErrors(name)
    }, [defaultValue])

    const handleChange = (e) => {
        setValue(name, e.target.value)
        onChange && onChange(e)
    }
    return (
        <div className="space-y-2">
            {label && <label htmlFor={name} className="text-charcoal block text-sm text-start">{label}{rules?.required && <span className="text-red-500 ml-1">*</span>}</label>}
            <textarea
                id={name}
                className={"text-sm w-full px-3 py-2 border-[1px] border-gray-300 focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg "
                    + (height ? `${height} ` : "min-h-32 max-h-[384px] ")
                    + (errors[name] ? "border-red-500" : "")
                }
                name={name}
                {...register(name, rules)}
                value={defaultValue}
                placeholder={placeholder}
                onChange={handleChange}
                readOnly={readOnly}
            />
            {errors[name] &&
                <div className="text-sm text-red-500 text-left">
                    {errors[name].message}
                </div>
            }
        </div>
    )
}
export default TextAreaField
