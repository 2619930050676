
const Businesses = ({ className }) => {
    return (
        <svg className={className} width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M1788 4989 c-861 -68 -1556 -744 -1659 -1613 -15 -126 -6 -390 16
                    -516 102 -569 469 -1054 993 -1315 271 -135 551 -193 872 -182 388 14 738 142
                    1047 384 l96 75 138 -138 139 -139 -114 -115 c-63 -63 -119 -125 -125 -137
                    -15 -30 -14 -69 3 -101 8 -15 227 -239 488 -497 505 -503 514 -510 653 -551
                    78 -22 202 -22 280 0 96 29 156 64 231 140 114 114 161 238 151 401 -5 104
                    -29 174 -86 261 -53 81 -966 990 -1003 999 -52 13 -81 -5 -206 -128 l-122
                    -121 -139 138 -140 139 63 81 c230 295 359 629 385 993 55 788 -402 1522
                    -1132 1817 -254 102 -553 147 -829 125z m420 -449 c553 -112 975 -534 1094
                    -1093 28 -136 30 -378 4 -517 -56 -290 -182 -527 -391 -735 -211 -211 -464
                    -342 -753 -390 -127 -21 -394 -16 -512 10 -465 103 -849 432 -1016 872 -55
                    148 -76 257 -81 440 -6 202 9 319 63 482 72 218 172 380 339 547 232 232 523
                    372 850 408 86 9 304 -3 403 -24z"/>
                <path d="M1840 4348 c-405 -27 -778 -281 -959 -653 -58 -118 -86 -205 -106
                    -329 -82 -504 174 -1008 628 -1237 646 -327 1423 -6 1651 681 47 142 60 240
                    53 418 -6 189 -34 300 -116 467 -71 142 -130 222 -243 331 -248 238 -554 347
                    -908 322z m280 -303 c391 -82 700 -449 700 -831 0 -72 -3 -84 -24 -107 -73
                    -79 -186 -21 -186 95 0 78 -28 186 -73 278 -32 66 -59 103 -122 166 -124 125
                    -259 189 -423 201 -61 4 -92 12 -115 27 -55 37 -61 113 -12 162 21 21 33 24
                    104 24 44 0 112 -7 151 -15z"/>
            </g>
        </svg>

    )
}
export default Businesses