import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../../ServiceRequestLayout"
import BusinessSize from "../../components/BusinessSize"
import ExpertService from "./components/ExpertService"
import ChoiceOfTool from "./components/ChoiceOfTool"
import Budget from "../../components/Budget"
import ProjectDuration from "../../components/ProjectDuration"
import ClientDetails from "../../components/ClientDetails"
import TitleAndDescription from "../../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_FT_FPAR } from "../../../../../../assets/constants"
import { createRequestAsync } from "../../../../../../slices/client/service/financeTransformation/FPandARevolution"

const metadata = {
    title: "New Request - FP and A Revolution: Finance Transformation Service",
    description: "FP and A Revolution finance transformation new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/finance-transformation/fp-and-a-revolution/`
}

const FPandARevolution = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [businessSize, setBusinessSize] = useState("")
    const [services, setServices] = useState(null)
    const [tools, setTools] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.businessSize && setBusinessSize(parsedData.businessSize)
        parsedData.services && setServices(parsedData.services)
        parsedData.tools && setTools(parsedData.tools)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
    }, [])

    // Generate request data
    const formData = {
        businessSize,
        services,
        tools,
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        projectDuration,
        ...(otherProjectDuration && { otherProjectDuration }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 7
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!businessSize ? (
                <BusinessSize
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    setBusinessSize={setBusinessSize}
                    goBack={() => navigate(newRequestPath + "finance-transformation/")}
                />
            ) : !services ? (
                <ExpertService
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    defaultValue={searchParams.get("service")}
                    setServices={setServices}
                    goBack={() => setBusinessSize("")}
                />
            ) : !tools ? (
                <ChoiceOfTool
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    setTools={setTools}
                    goBack={() => setServices(null)}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setTools(null)}
                />
            ) : !projectDuration ? (
                <ProjectDuration
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    setProjectDuration={setProjectDuration}
                    setOtherProjectDuration={setOtherProjectDuration}
                    goBack={() => setBudget("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(6)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setProjectDuration("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(7)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setProjectDuration("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const FPandARevolutionLayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_FT_FPAR
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <FPandARevolution cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default FPandARevolutionLayout