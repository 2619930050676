import { useState, useEffect } from "react"

import AddPlus from "../../../../svgs/AddPlus"
import ExperienceFields from "./ExperienceFields"

const AddExperience = ({ maximum = 1, minimum = 1, submitted, experience, setExperience }) => {
    const [experienceCount, setExperienceCount] = useState(minimum)
    const [fieldStatus, setFieldStatus] = useState({ added: true, count: minimum })

    useEffect(() => {
        if (experience && experience.length > 0) {
            setFieldStatus({ added: true, count: experience.length })
            setExperienceCount(experience.length)
        }
    }, [experience])

    const addMoreExperience = (e) => {
        const button = e.currentTarget
        button.disabled = true

        setTimeout(() => {
            const updatedCount = experienceCount + 1
            setFieldStatus({ added: true, count: updatedCount })
            setExperienceCount(updatedCount)
            button.disabled = false
        }, 300)
    }
    const removeExperience = () => {
        const updatedCount = experienceCount - 1
        setFieldStatus({ added: false, count: updatedCount })
        setExperienceCount(experienceCount - 1)
    }
    return (
        <div id="add-experience" className="space-y-7 pt-8">
            {[...Array(experienceCount)].map((_, index) => (
                <ExperienceFields
                    data={experience}
                    submitted={submitted}
                    position={index + 1}
                    minimum={minimum}
                    fieldStatus={fieldStatus}
                    removeExperience={removeExperience}
                    setExperience={setExperience}
                    key={index}
                />
            ))}
            {maximum >= experienceCount &&
                <button
                    className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group"
                    type="button"
                    onClick={addMoreExperience}
                >
                    <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                    <span className="text-sm">Add Experience</span>
                </button>
            }
        </div>
    )
}
export default AddExperience