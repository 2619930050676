
export const getEventDate = (start, end, showYear) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const startMonthName = startDate.toLocaleString("default", { month: "short" })
    const endMonthName = endDate.toLocaleString("default", { month: "short" })
    const startMonth = startDate.getMonth()
    const endMonth = endDate.getMonth()
    const startDateNumber = startDate.getDate()
    const endDateNumber = endDate.getDate()
    const startYear = startDate.getFullYear()
    const endYear = endDate.getFullYear()
    let eventDate = `${startMonthName} ${startDateNumber}`
    if (startYear !== endYear || showYear) {
        eventDate += `, ${startYear} - ${endMonthName} ${endDateNumber}, ${endYear}`
    }
    else if (startMonth !== endMonth) {
        eventDate += ` - ${endMonthName} ${endDateNumber}`
    }
    else if (startDateNumber !== endDateNumber) {
        eventDate += ` - ${endDateNumber}`
    }
    return eventDate
}

export const getYears = (thisYear) => {
    const endYear = thisYear + 15
    return Array.from({ length: 105 }, (_, i) => endYear - i)
}

export const MONTHS = [...Array(12).keys()].map(key => {
    const name = new Date(0, key).toLocaleString("en", { month: "long" })
    return {
        name, id: key + 1
    }
})