import { useParams, useNavigate, Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import chevronRightIcon from "../../../../assets/svgs/chevron-right.svg"
import noPictureIcon from "../../../../assets/images/no-picture-taking.png"
import curvyArrowIcon from "../../../../assets/svgs/full-curvyarrow-right.svg"
import emptyBoxIcon from "../../../../assets/images/empty-box.png"
import ServicesList from "../../../../assets/ServicesList"
import { privateServicesPath } from "../../../../assets/constants"
import { getAddServiceProjectPath, getServiceProjectDetailsPath } from "../../../../assets/path"
import { allFreelancerServiceProjectAsync } from "../../../../slices/freelancer/serviceProject"

const metadata = {
    title: "My Services - Projects",
}

const ServiceCard = ({ data }) => {
    const serviceProjectDetailsPath = getServiceProjectDetailsPath(data.service.uid, data.id)
    return (
        <Link to={serviceProjectDetailsPath} className="max-md:max-w-[460px] max-md:mx-auto p-3 shadow-card5 rounded-lg">
            <div className="bg-[#F6F9FF] flex items-end justify-center aspect-[4/3] rounded-lg overflow-hidden">
                <div className="max-w-[420px] w-11/12 aspect-[4/3] rounded-[19px] -mb-4 overflow-hidden">
                    {data.coverImage
                        ? <img className="w-full border border-brandeis-blue/[0.15] rounded-[19px]" src={data.coverImage} />
                        : <div className="inline-flex flex-col items-center justify-center w-full h-full border border-brandeis-blue/[0.15] rounded-[19px]" title="No images found">
                            <img className="w-32" src={noPictureIcon} />
                            <span className="text-versich-label text-sm">No images found</span>
                        </div>
                    }
                </div>
            </div>
            <div className="mt-4 px-2">
                <div>
                    <div className="flex justify-start">
                        <div className="bg-brandeis-blue/[0.08] text-versich-light-blue text-sm py-1.5 px-2.5 rounded-full">{data.service.title}</div>
                    </div>
                    <div className="text-versich-dark-blue mt-2.5">
                        <div className="text-xl font-semibold">{data.projectName}</div>
                        <div className="text-sm mt-0.5">{data.shortDesc}</div>
                    </div>
                </div>
                <div className="text-versich-label text-sm inline-flex items-center gap-[5px] mt-[14px]">
                    View details <img className="w-5 h-5" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}

const ServiceProjects = () => {
    const { id } = useParams()
    const [projects, setProjects] = useState([])

    // Set Service title
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === id)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [id])

    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allFreelancerServiceProjectAsync(id))
            .unwrap()
            .then(data => {
                setProjects(data.payload)
            })
            .catch(() => { })
    }, [])

    const dynamicAddServiceProjectPath = getAddServiceProjectPath(id)
    return (
        service &&
        <DashboardLayout metadata={metadata} activePage="My Services">
            <div className="flex flex-col gap-y-2.5 h-full">
                <div className="space-y-1.5">
                    <div className="flex justify-start pt-1">
                        <button className="inline-flex items-center gap-0.5" onClick={() => navigate(privateServicesPath)}>
                            <img src={chevronRightIcon} alt="" />
                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                        </button>
                    </div>
                    <div className="flex max-sm:flex-col sm:items-center gap-2.5">
                        <div className="text-versich-dark-blue text-start flex-1 space-y-[2px]">
                            <div className="text-lg lg:text-xl font-semibold">{service.name}</div>
                            <div className="text-sm">See all projects handled under {service.name.toLowerCase()}</div>
                        </div>
                        <div className="font-semibold flex flex-wrap py-1">
                            <button
                                className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm min-h-12 py-2 lg:py-3 px-4 lg:px-6 border-2 border-versich-blue hover:border-versich-blue-hover rounded-[10px]"
                                type="button"
                                onClick={() => navigate(dynamicAddServiceProjectPath)}>
                                <span>Add Project</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="bg-white text-start flex-grow py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    {projects.length > 0
                        ? <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
                            {projects.map((project, index) => <ServiceCard data={project} key={index} />)}
                        </div>
                        : <div className="h-full flex flex-col items-center justify-center p-4">
                            <img className="w-[180px] sm:w-[220px] opacity-60" src={emptyBoxIcon} alt="no projects" />
                            <span className="text-versich-label text-sm">No Projects</span>
                        </div>
                    }
                </div>
            </div>
        </DashboardLayout >
    )
}
export default ServiceProjects