import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FaRegEye } from "react-icons/fa"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/ExpertCard"
import ContactFreelancerDialog from "../../../../components/Requests/client/ContactFreelancerDialog"
import { toConversationDetails, toProposalDetails } from "../../../../assets/path"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"

const metadata = {
    title: "Service Related Freelancers",
    description: "List of freelancers based on services and popularity",
}

const RelatedFreelancers = () => {
    const { id } = useParams()
    const [relatedFreelancers, setRelatedFreelancers] = useState([])
    const [recommendedFreelancers, setRecommendedFreelancers] = useState([])
    const [expertId, setExpertId] = useState("")
    const [contactState, setContactState] = useState({
        conversationID: null, proposalID: null
    })

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(serviceRelatedFreelancersAsync(id))
            .unwrap()
            .then(data => {
                setRelatedFreelancers(data.relatedFreelancers)
                setRecommendedFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    return (
        <DashboardLayout activePage="My Requests">
            <div className="text-start mb-5 lg:mb-12">
                <Meta data={metadata} />
                <div className="space-y-3">
                    {contactState.conversationID &&
                        <div className="bg-brandeis-blue/5 border border-brandeis-blue/20 text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 shadow-sticker rounded-xl">
                            <p>Message sent to expert.</p>
                            <button
                                className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5"
                                onClick={() => toConversationDetails(contactState.conversationID, navigate)}
                                title="View Message">
                                <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View message
                            </button>
                        </div>
                    }
                    {contactState.proposalID &&
                        <div className="bg-brandeis-blue/5 border border-brandeis-blue/20 text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 shadow-sticker rounded-xl">
                            <p>Already got a proposal from the expert.</p>
                            <button
                                className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5"
                                onClick={() => toProposalDetails(contactState.proposalID, navigate)}
                                title="View Proposal">
                                <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View proposal
                            </button>
                        </div>
                    }
                    <div className="bg-white shadow-card rounded-xl">
                        <div className="px-5 md:px-10 py-6 md:py-12 space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancers</p>
                            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                                {recommendedFreelancers.map(freelancer => (
                                    <FreelancerCard user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                    {relatedFreelancers.length > 0 && (<>
                        <div className="text-versich-dark-blue font-semibold text-lg lg:text-xl text-start w-full px-2 py-2 sm:px-4 sm:py-4">
                            Other Related Freelancers
                        </div>
                        <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                            {relatedFreelancers.map(freelancer => (
                                <FreelancerCard className="bg-white border-none shadow-card" user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                            ))}
                        </div>
                    </>)}
                </div>
                <ContactFreelancerDialog expertId={expertId} setContactState={setContactState} />
            </div>
        </DashboardLayout>
    )
}
export default RelatedFreelancers