import { useState, useEffect, useRef } from "react"
import { useDispatch } from "react-redux"

import DropdownFieldInput from "../Services/DropdownFieldInput"
import { businessAboutAllMinimumProjectSizeAsync } from "../../slices/business/about"
import { sortbyOptions, verifiedOptions } from "../../assets/constantOptions"

const businessSortbyOptions = [
    ...sortbyOptions,
    {
        uid: "highest_rated",
        title: "Highest Rated"
    }, {
        uid: "most_reviewed",
        title: "Most Reviewed"
    }
]

const Filters = ({ setFilters, initialSortby = "", initialVerified = "", initialBudget = "", }) => {
    const [sortby, setSortby] = useState(initialSortby)
    const [verified, setVerified] = useState(initialVerified)
    const [budgetOptions, setBudgetOptions] = useState([])
    const [budget, setBudget] = useState(initialBudget)
    const manualChange = useRef(false) // track user manual filter change using this

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(businessAboutAllMinimumProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setBudgetOptions(data.payload)
            })
    }, [])

    // Set default
    useEffect(() => {
        setSortby(initialSortby)
        setVerified(initialVerified)
        setBudget(initialBudget)
    }, [initialSortby, initialVerified, initialBudget])

    // Track value changes
    useEffect(() => {
        setFilters({ sortby, verified, budget }, manualChange.current)
    }, [sortby, verified, budget, manualChange])

    const dropdowns = [
        { name: "sortby", options: businessSortbyOptions, value: sortby, placeholder: "Sort by", setValue: setSortby },
        { name: "verified", options: verifiedOptions, value: verified, placeholder: "All", setValue: setVerified },
        { name: "budget", options: budgetOptions, value: budget, placeholder: "Budget", setValue: setBudget },
    ]
    return (
        <div className="grid grid-cols-1 sm401:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 sm:gap-5 lg:gap-6">
            {dropdowns.map(dropdown => (
                <DropdownFieldInput
                    name={dropdown.name}
                    options={dropdown.options}
                    value={dropdown.value}
                    placeholder={dropdown.placeholder}
                    hidePlaceholder={false}
                    onChange={(e) => {
                        dropdown.setValue(e.target.value)
                        manualChange.current = true
                    }}
                    key={dropdown.name}
                />
            ))}
        </div>
    )
}
export default Filters