import { Link } from "react-router-dom"

import Meta from "../../components/Meta"
import {
    findJobsPath, findProfessionalsPath, businessListingPath,
    signupPath
} from "../../assets/constants"

const metadata = {
    title: "About Us | Our Story & Mission",
    description: "Learn about our journey, values, and mission. Discover what drives us and how we strive to make a difference.",
    url: `${process.env.REACT_APP_CLIENT_URL}/about-us/`
}

const AboutUs = () => {
    return (
        <div>
            <Meta data={metadata} />
            <div className="bg-white py-10 md:py-14 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <div className="pb-6 sm:pb-10 border-b border-versich-light-blue/10">
                        <h1 className="text-versich-darktext-color text-2xl sm:text-3xl font-bold">About VersiMarket</h1>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 space-y-12 sm:space-y-16">
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">About VersiMarket</h2>
                            <div className="text-sm lg:text-base">
                                Welcome to VersiMarket—the all-in-one marketplace where freelancers, consultants, and businesses connect, collaborate, and grow. Whether you’re looking to land your next project, find top-tier talent, or boost your business’s online presence, VersiMarket is designed to empower you every step of the way.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">Our Story</h2>
                            <div className="text-sm lg:text-base">
                                Founded by <a className="text-brandeis-blue underline underline-offset-2" href="https://versich.com" target="_blank">Versich</a>, a trailblazer in digital innovation, VersiMarket was born from a desire to break down traditional barriers between independent professionals and businesses. Leveraging Versich’s expertise in technology and entrepreneurship, we recognized that the evolving world of work needed a unified space—one that combined the dynamic opportunities of freelancing with the strategic advantages of business listings. The result? A platform where every connection leads to new opportunities.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">Our Mission</h2>
                            <div className="text-sm lg:text-base">
                                <div>At VersiMarket, our mission is simple: to create a seamless, integrated platform that drives professional success by connecting the right talent with the right opportunities. We empower you to:</div>
                                <ul className="mt-1.5 sm:mt-3 pl-6 sm:pl-8 list-disc">
                                    <li><Link className="text-brandeis-blue hover:underline underline-offset-2 transition-colors" to={findJobsPath}>Find Work</Link>: Discover freelance projects and consulting gigs that match your expertise.</li>
                                    <li><Link className="text-brandeis-blue hover:underline underline-offset-2 transition-colors" to={findProfessionalsPath}>Find Talent</Link>: Access a diverse pool of skilled professionals ready to elevate your projects.</li>
                                    <li><Link className="text-brandeis-blue hover:underline underline-offset-2 transition-colors" to={businessListingPath}>List Your Business</Link>: Showcase your services, attract new clients, and expand your online reach.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">Why Choose VersiMarket?</h2>
                            <div className="text-sm lg:text-base">
                                <div>Unlike traditional freelancing platforms or standalone business directories, VersiMarket brings you the best of both worlds. Here’s what sets us apart:</div>
                                <ul className="mt-1.5 sm:mt-3 pl-6 sm:pl-8 list-disc">
                                    <li>Dual Functionality: Enjoy a comprehensive solution that caters to both freelance matchmaking and business listings.</li>
                                    <li>User-Centric Design: Our intuitive platform makes it easy to navigate, connect, and grow your professional network.</li>
                                    <li>Secure & Reliable: With robust security measures and streamlined processes, your success is our top priority.</li>
                                    <li>Community Focus: Join a vibrant community of professionals and businesses committed to collaboration and mutual growth.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">Join Our Community</h2>
                            <div className="text-sm lg:text-base">
                                <div>At VersiMarket, you’re not just another user—you’re part of a thriving ecosystem designed to foster growth, innovation, and success. Whether you’re an independent professional or a forward-thinking business, we invite you to explore our platform, make meaningful connections, and take your career or company to new heights.</div>
                                <div className="mt-2 sm:mt-4">Ready to connect and grow? <Link className="text-brandeis-blue hover:underline underline-offset-2 transition-colors" to={signupPath}>Join VersiMarket today</Link>.</div>
                            </div>
                        </div>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 pt-2 sm:pt-4 border-t border-versich-light-blue/20">
                        <p className="text-versich-dark-blue text-sm lg:text-base font-medium">Discover the future of professional collaboration with VersiMarket—where opportunities meet talent, and businesses shine.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AboutUs
