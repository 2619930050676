import { useSelector } from "react-redux"

import Meta from "../../../../components/Meta"
import { CLIENT, FREELANCER } from "../../../../assets/constants"
import ClientProfileDetails from "./ClientProfileDetails"
import FreelancerProfileDetails from "./FreelancerProfileDetails"

const metadata = {
    title: "Complete Profile Details",
    description: "Complete user profile details to become a verified user",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/complete/profile/`
}

const ProfileDetails = () => {
    const { user } = useSelector((state) => state.auth)
    return (<>
        <Meta data={metadata} />
        {user.accountType === CLIENT && <ClientProfileDetails />}
        {user.accountType === FREELANCER && <FreelancerProfileDetails />}
    </>)
}
export default ProfileDetails
