import { useSelector } from "react-redux"

import AccountSettingsLayout from "../../../components/Dashboard/accountSettings/AccountSettingsLayout"
import Profile from "../../../components/Dashboard/accountSettings/Profile"
import Experience from "../../../components/Dashboard/accountSettings/freelancer/Experience"
import Education from "../../../components/Dashboard/accountSettings/freelancer/Education"
import Languages from "../../../components/Dashboard/accountSettings/freelancer/Languages"
import PersonalDetails from "../../../components/Dashboard/accountSettings/freelancer/PersonalDetails"
import BusinessDetails from "../../../components/Dashboard/accountSettings/freelancer/BusinessDetails"
import PrimaryService from "../../../components/Dashboard/accountSettings/freelancer/PrimaryService"
import OtherServices from "../../../components/Dashboard/accountSettings/freelancer/OtherServices"
import LeadsLocation from "../../../components/Dashboard/accountSettings/freelancer/LeadLocation"
import AccountAndSecurity from "../../../components/Dashboard/accountSettings/accountAndSecurity/AccountAndSecurity"

const FreelancerAccountSettings = ({ metadata }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <AccountSettingsLayout metadata={metadata}>
            <Profile>
                <Experience />
                <Education />
                <Languages />
            </Profile>
            <div className="md:col-span-3 xl:col-span-2 space-y-5">
                <PersonalDetails />
                <BusinessDetails />
                {user.primaryService &&
                    <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
                        <div className="font-medium py-3 border-b border-[#EFEEEE99]">
                            <span className="text-versich-dark-blue text-xl">Lead Settings</span>
                        </div>
                        <div className="text-versich-dark-blue my-5 space-y-5">
                            <PrimaryService />
                            <OtherServices />
                            <LeadsLocation />
                        </div>
                    </div>
                }
                <AccountAndSecurity isSocial={user.social} />
            </div>
        </AccountSettingsLayout>
    )
}
export default FreelancerAccountSettings