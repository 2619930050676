import { Routes, Route, Navigate } from "react-router-dom"

import HeaderFooterRoutes from "./HeaderFooterRoutes"
import Home from "../../pages/home/Home"
import Services from "../../pages/services/Services"
import BusinessListing from "../../pages/business-listing/BusinessListing"
import FindBusinesses from "../../pages/find/businesses/Businesses"
import Professionals from "../../pages/find/professionals/Professionals"
import BusinessOrFreelancerRoutes from "./BusinessOrFreelancerRoutes"
import Jobs from "../../pages/find/jobs/Jobs"
import BusinessOrFreelancerOnlyRoutes from "./BusinessOrFreelancerOnlyRoutes"
import JobDetails from "../../pages/jobs/JobDetails"
import JobProposalSubmit from "../../pages/jobs/JobProposalSubmit"
import HelpCenter from "../../pages/help-center/HelpCenter"
import ContactUs from "../../pages/contact-us/ContactUs"
import AboutUs from "../../pages/about-us/AboutUs"
import TermsAndConditions from "../../pages/legal/TermsAndConditions"
import PrivacyPolicy from "../../pages/legal/PrivacyPolicy"
import CookiesPolicy from "../../pages/legal/CookiesPolicy"
import NotAuthRoutes from "./NotAuthRoutes"
import { signupPath, messagesPath } from "../../assets/constants"
import AccountSelection from "../../pages/auth/signup/AccountSelectionForm"
import ClientSignupForm from "../../pages/auth/signup/ClientSignupForm"
import FreelancerSignupForm from "../../pages/auth/signup/FreelancerSignupForm"
import BusinessSignupForm from "../../pages/auth/signup/BusinessSignupForm"
import Login from "../../pages/auth/Login"
import ForgotPassword from "../../pages/auth/ForgotPassword"
import ResetPassword from "../../pages/auth/ResetPassword"
import GoogleOAuthVerification from "../../pages/auth/verification/GoogleOAuthVerification"
import AuthRoutes from "./AuthRoutes"
import EmailVerificationRoutes from "./EmailVerificationRoutes"
import EmailTokenLinkVerification from "../../pages/auth/verification/EmailTokenLinkVerification"
import EmailVerification from "../../pages/auth/verification/email/EmailVerification"
import ProfilePictureForm from "../../pages/auth/setup/ProfilePictureForm"
import FreelancerSetupRoutes from "./FreelancerRoutes/FreelancerSetupRoutes"
import PrimaryServiceForm from "../../pages/auth/setup/freelancer/PrimaryServiceForm"
import LeadsForm from "../../pages/auth/setup/freelancer/LeadsForm"
import OtherServicesForm from "../../pages/auth/setup/freelancer/OtherServicesForm"
import ServiceDetailsForm from "../../pages/auth/setup/freelancer/ServiceDetailsForm"
import BusinessSetupRoutes from "./BusinessRoutes/BusinessSetupRoutes"
import BusinessDetailsForm from "../../pages/auth/setup/business/BusinessDetailsForm"
import BusinessPersonalContactForm from "../../pages/auth/setup/business/BusinessPersonalContactForm"
import BusinessAboutForm from "../../pages/auth/setup/business/BusinessAboutForm"
import BusinessLocationForm from "../../pages/auth/setup/business/BusinessLocationForm"
import BusinessSocialProfilesForm from "../../pages/auth/setup/business/BusinessSocialProfilesForm"
import BusinessCompetitorsForm from "../../pages/auth/setup/business/BusinessCompetitorsForm"
import BusinessReviewForm from "../../pages/auth/setup/business/BusinessReviewForm"
import Dashboard from "../../pages/dashboard/Dashboard"
import BusinessPricing from "../../pages/pricing/BusinessPricing"
import BuyVersiGems from "../../pages/dashboard/BuyVersiGems"
import ProfileDetails from "../../pages/dashboard/complete/Profile/ProfileDetails"
import FreelancerEducationForm from "../../pages/dashboard/complete/FreelancerEducationForm"
import FreelancerExperienceForm from "../../pages/dashboard/complete/FreelancerExperienceForm"
import FreelancerLanguagesForm from "../../pages/dashboard/complete/FreelancerLanguagesForm"
import ClientRoutes from "./ClientRoutes/ClientRoutes"
import RequestConfirmation from "../../pages/dashboard/request/confirmation/RequestConfirmation"
import RequestDetails from "../../pages/dashboard/request/details/RequestDetails"
import Requests from "../../pages/dashboard/request/Requests"
import FreelancerRoutes from "./FreelancerRoutes/FreelancerRoutes"
import MyServices from "../../pages/dashboard/freelancer/services/MyServices"
import ServiceProjects from "../../pages/dashboard/freelancer/services/ServiceProjects"
import AddServiceProject from "../../pages/dashboard/freelancer/services/AddServiceProject"
import ServiceProjectDetails from "../../pages/dashboard/freelancer/services/ServiceProjectDetails"
import EditServiceProject from "../../pages/dashboard/freelancer/services/EditServiceProject"
import Proposals from "../../pages/dashboard/proposal/Proposals"
import ProposalDetails from "../../pages/dashboard/proposal/details/ProposalDetails"
import Messages from "../../pages/dashboard/message/Messages"
import RelatedFreelancers from "../../pages/dashboard/freelancer/service/RelatedFreelancers"
import AccountSettings from "../../pages/dashboard/accountSettings/AccountSettings"
import GoogleOAuthAccountChangeVerification from "../../pages/dashboard/accountSettings/GoogleOAuthAccountChangeVerification"
import PaymentSuccess from "../../pages/payments/Success"
import PaymentCanceled from "../../pages/payments/Canceled"
import ClientOnlyRoutes from "./ClientRoutes/ClientOnlyRoutes"
import NewRequest from "../../pages/dashboard/request/new/NewRequest"
import WebDesignAndDevelopment from "../../pages/dashboard/request/new/webDesignAndDevelopment/WebDesignAndDevelopment"
import MobileApp from "../../pages/dashboard/request/new/mobileApp/MobileApp"
import DataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/DataAndAnalytics"
import PersonalDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/personal/PersonalDataAndAnalytics"
import BusinessDataAndAnalytics from "../../pages/dashboard/request/new/dataAndAnalytics/business/BusinessDataAndAnalytics"
import SoftwareDevelopment from "../../pages/dashboard/request/new/softwareDevelopment/SoftwareDevelopment"
import FinanceTransformation from "../../pages/dashboard/request/new/financeTransformation/FinanceTransformation"
import FPandARevolution from "../../pages/dashboard/request/new/financeTransformation/FPandARevolution/FPandARevolution"
import FinanceReportingAndAdvancedAnalytics from "../../pages/dashboard/request/new/financeTransformation/FRAA/FRAA"
import Enterprise from "../../pages/dashboard/request/new/financeTransformation/Enterprise/Enterprise"
import SystemAdministration from "../../pages/dashboard/request/new/financeTransformation/SystemAdministration/SystemAdministration"
import DigitalTransformation from "../../pages/dashboard/request/new/financeTransformation/DigitalTransformation/DigitalTransformation"
import OtherFT from "../../pages/dashboard/request/new/financeTransformation/other/OtherFT"
import MachineLearningAndAI from "../../pages/dashboard/request/new/machineLearningAndAI/MachineLearningAndAI"
import BusinessProfile from "../../pages/business/BusinessProfile"
import LeaveBusinessAReview from "../../pages/business/LeaveBusinessAReview"
import FreelancerProfile from "../../pages/freelancer/FreelancerProfile"
import FreelancerServices from "../../pages/freelancer/FreelancerServices"
import FreelancerServiceProjects from "../../pages/freelancer/FreelancerServiceProjects"
import FreelancerServiceProjectDetails from "../../pages/freelancer/FreelancerServiceProjectDetails"
import LeaveFreelancerAReview from "../../pages/freelancer/LeaveFreelancerAReview"
import ErrorPage from "../ErrorPage/ErrorPage"

const AllRoutes = () => {
    return (
        <Routes>
            <Route element={<HeaderFooterRoutes />}>
                <Route element={<NotAuthRoutes />}>
                    <Route path="/" element={<Home />} />
                </Route>
                <Route path="services/" element={<Services />} />
                <Route path="business-listing/" element={<BusinessListing />} />
                <Route path="find/">
                    <Route path=":service/businesses" element={<FindBusinesses />} />
                    <Route path="businesses/" element={<FindBusinesses />} />
                    <Route path="professionals/" element={<Professionals />} />
                    <Route element={<BusinessOrFreelancerRoutes />}>
                        <Route path=":service/jobs/" element={<Jobs />} />
                        <Route path="jobs/" element={<Jobs />} />
                    </Route>
                </Route>
                <Route element={<BusinessOrFreelancerRoutes />}>
                    <Route path="jobs/:id/">
                        <Route path="" element={<JobDetails />} />
                        <Route element={<BusinessOrFreelancerOnlyRoutes />}>
                            <Route path="proposals/submit" element={<JobProposalSubmit />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="help-center/" element={<HelpCenter />} />
                <Route path="contact-us/" element={<ContactUs />} />
                <Route path="about-us/" element={<AboutUs />} />
                <Route path="legal/">
                    <Route path="terms/" element={<TermsAndConditions />} />
                    <Route path="privacy-policy/" element={<PrivacyPolicy />} />
                    <Route path="cookies-policy/" element={<CookiesPolicy />} />
                </Route>
            </Route>
            <Route path="auth/">
                <Route element={<NotAuthRoutes />}>
                    <Route path="" element={<Navigate to={signupPath} replace />} />
                    <Route path="signup/" element={<AccountSelection />} />
                    <Route path="client/signup/" element={<ClientSignupForm />} />
                    <Route path="freelancer/signup/" element={<FreelancerSignupForm />} />
                    <Route path="business/signup/" element={<BusinessSignupForm />} />
                    <Route path="login/" element={<Login />} />
                    <Route path="forgot-password/" element={<ForgotPassword />} />
                    <Route path="reset-password/:token/" element={<ResetPassword />} />
                    <Route path="social-accounts/verify/google-oauth2/" element={<GoogleOAuthVerification />} />
                </Route>
                <Route element={<AuthRoutes />}>
                    <Route element={<EmailVerificationRoutes />}>
                        <Route path="verification/:token/email/" element={<EmailTokenLinkVerification />} />
                        <Route path="verification/email/" element={<EmailVerification />} />
                    </Route>
                    <Route path="su/">
                        <Route path="profile/" element={<ProfilePictureForm />} />
                        <Route path="freelancer/" element={<FreelancerSetupRoutes />}>
                            <Route path="primary-service/" element={<PrimaryServiceForm />} />
                            <Route path="leads/" element={<LeadsForm />} />
                            <Route path="other-services/" element={<OtherServicesForm />} />
                            <Route path="service-details/" element={<ServiceDetailsForm />} />
                        </Route>
                        <Route path="business/" element={<BusinessSetupRoutes />}>
                            <Route path="details/" element={<BusinessDetailsForm />} />
                            <Route path="personal-contact/" element={<BusinessPersonalContactForm />} />
                            <Route path="about/" element={<BusinessAboutForm />} />
                            <Route path="location/" element={<BusinessLocationForm />} />
                            <Route path="social-profiles/" element={<BusinessSocialProfilesForm />} />
                            <Route path="competitors/" element={<BusinessCompetitorsForm />} />
                            <Route path="review-and-references/" element={<BusinessReviewForm />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            <Route path="dashboard/" element={<AuthRoutes />}>
                <Route path="" element={<Dashboard />} />
                <Route path="business/pricing/" element={<BusinessPricing />} />
                <Route path="plans/versigems/buy/" element={<BuyVersiGems />} />
                <Route path="complete/">
                    <Route path="profile/" element={<ProfileDetails />} />
                    <Route path="education/" element={<FreelancerEducationForm />} />
                    <Route path="experience/" element={<FreelancerExperienceForm />} />
                    <Route path="languages/" element={<FreelancerLanguagesForm />} />
                </Route>
                <Route path="requests/">
                    <Route path="" element={<Requests />} />
                    <Route path=":id/details/" element={<RequestDetails />} />
                    <Route element={<ClientRoutes />}>
                        <Route path=":id/confirmation/:pid/:sid/" element={<RequestConfirmation />} />
                    </Route>
                </Route>
                <Route path="services/" element={<FreelancerRoutes />} >
                    <Route path="" element={<MyServices />} />
                    <Route path=":id/projects/">
                        <Route path="" element={<ServiceProjects />} />
                        <Route path="add/" element={<AddServiceProject />} />
                        <Route path=":pid/details/" element={<ServiceProjectDetails />} />
                        <Route path=":pid/edit/" element={<EditServiceProject />} />
                    </Route>
                </Route>
                <Route path="proposals/">
                    <Route element={<BusinessOrFreelancerRoutes />}>
                        <Route path="" element={<Proposals />} />
                    </Route>
                    <Route path=":id/details/" element={<ProposalDetails />} />
                </Route>
                <Route path="message/all/" element={<Navigate to={messagesPath} replace />} />
                <Route path="messages/">
                    <Route path="" element={<Messages />} />
                    <Route path="conversation/:id/details/" element={<Messages />} />
                </Route>
                <Route path="freelancer/" element={<ClientOnlyRoutes />}>
                    <Route path="service/:id/" element={<RelatedFreelancers />} />
                </Route>
                <Route path="settings/">
                    <Route path="" element={<AccountSettings />} />
                    <Route path="social-accounts/verify/google-oauth2/change/" element={<GoogleOAuthAccountChangeVerification />} />
                </Route>
            </Route>
            <Route path="payments/checkout/" element={<AuthRoutes />}>
                <Route path="success/" element={<PaymentSuccess />} />
                <Route path="canceled/" element={<PaymentCanceled />} />
            </Route>
            <Route path="request/new/" element={<ClientOnlyRoutes />}>
                <Route path="" element={<NewRequest />} />
                <Route path="web-design-and-development/" element={<WebDesignAndDevelopment />} />
                <Route path="mobile-app/" element={<MobileApp />} />
                <Route path="data-and-analytics/">
                    <Route path="" element={<DataAndAnalytics />} />
                    <Route path="personal/" element={<PersonalDataAndAnalytics />} />
                    <Route path="business/" element={<BusinessDataAndAnalytics />} />
                </Route>
                <Route path="software-development/" element={<SoftwareDevelopment />} />
                <Route path="finance-transformation/">
                    <Route path="" element={<FinanceTransformation />} />
                    <Route path="fp-and-a-revolution/" element={<FPandARevolution />} />
                    <Route path="financial-reporting-and-advanced-analytics/" element={<FinanceReportingAndAdvancedAnalytics />} />
                    <Route path="enterprise/" element={<Enterprise />} />
                    <Route path="system-administration/" element={<SystemAdministration />} />
                    <Route path="digital-transformation/" element={<DigitalTransformation />} />
                    <Route path="other/" element={<OtherFT />} />
                </Route>
                <Route path="machine-learning-and-ai/" element={<MachineLearningAndAI />} />
            </Route>
            <Route path="business/" >
                <Route path=":id/" element={<BusinessProfile />} />
                <Route path="review/:token/leave/" element={<LeaveBusinessAReview />} />
            </Route>
            <Route path="freelancer/" >
                <Route path=":id/" element={<FreelancerProfile />} />
                <Route path=":id/services/" element={<FreelancerServices />} />
                <Route path=":id/services/:sid/projects/" element={<FreelancerServiceProjects />} />
                <Route path=":id/services/:sid/projects/:pid/details/" element={<FreelancerServiceProjectDetails />} />
                <Route path="review/:token/leave/" element={<LeaveFreelancerAReview />} />
            </Route>
            <Route path="*" element={<ErrorPage status={404} />} />
        </Routes>
    )
}
export default AllRoutes
