import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import { getUser } from "../../actions/auth"
import apiErrorParser from "../../utils/apiErrorParser"

export const taglineUpdateAsync = createAsyncThunk("account/taglineUpdate", async ({ tagline, token }, { dispatch }) => {
    try {
        const { data } = await api.freelancerTaglineUpdate({ tagline, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        await dispatch(getUser())
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const personalDetailsUpdateAsync = createAsyncThunk("account/personalDetailsUpdate",
    async ({ firstname, lastname, profileid, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerPersonalDetailsUpdate({ firstname, lastname, profileid, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })

export const businessDetailsUpdateAsync = createAsyncThunk("account/businessDetailsUpdate",
    async ({ companyname, address, phonecode, phonenumber, website, companysize, token }, { dispatch }) => {
        try {
            const { data } = await api.freelancerBusinessDetailsUpdate({ companyname, address, phonecode, phonenumber, website, companysize, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            await dispatch(getUser())
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })