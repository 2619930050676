import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import chevronRightIcon from "../../../assets/svgs/chevron-right.svg"
import planet from "../../../assets/images/planet.png"
import ClientRequestCards from "../../../components/Requests/requestCards/ClientRequestCards"
import FreelancerRequestCards from "../../../components/Requests/requestCards/FreelancerRequestCards"
import BusinessRequestCards from "../../../components/Requests/requestCards/BusinessRequestCards"
import DashboardConfirmBtn from "../../../components/Buttons/DashboardConfirmBtn"
import {
    CLIENT, FREELANCER, BUSINESS,
    dashboardPath, newRequestPath
} from "../../../assets/constants"
import { toRequestDetails } from "../../../assets/path"
import { userRequestsAsync } from "../../../slices/client/service"

const metadata = {
    title: "Requests | VersiMarket",
    description: "Responses page for requests",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/requests/`
}

const Requests = () => {
    const { user } = useSelector(state => state.auth)
    const [requests, setRequests] = useState([])

    const isClient = user.accountType === CLIENT
    const isFreelancer = user.accountType === FREELANCER
    const isBusiness = user.accountType === BUSINESS

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(userRequestsAsync(isClient))
            .unwrap()
            .then(data => {
                setRequests(data.payload)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    const navigateToNewRequest = () => navigate(newRequestPath)
    const viewRequest = (refId) => toRequestDetails(refId, navigate)
    return (
        <DashboardLayout metadata={metadata} activePage="Requests">
            <div className="flex flex-col gap-y-2.5 h-full">
                <div className="space-y-1.5">
                    <div className="flex justify-start pt-1">
                        <button className="inline-flex items-center gap-0.5" onClick={() => navigate(dashboardPath)}>
                            <img src={chevronRightIcon} alt="" />
                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                        </button>
                    </div>
                    <div className="text-versich-dark-blue text-start space-y-[2px]">
                        <div className="text-lg lg:text-xl font-semibold">Requests</div>
                        {isClient && <div className="text-sm">Manage all your requests</div>}
                    </div>
                </div>
                <div className="bg-white text-start flex-grow py-12 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    {requests.length > 0 ? (
                        <div className="text-start px-3 space-y-5">
                            {isClient &&
                                <ClientRequestCards requests={requests} viewRequest={viewRequest} />
                            }
                            {isFreelancer &&
                                <FreelancerRequestCards requests={requests} viewRequest={viewRequest} />
                            }
                            {isBusiness &&
                                <BusinessRequestCards plan={user.plan} requests={requests} viewRequest={viewRequest} />
                            }
                        </div>
                    ) : (
                        <div className="h-full flex flex-col items-center justify-center">
                            <div>
                                <img src={planet} alt="planet" />
                            </div>
                            <div className="text-center space-y-1">
                                <p className="text-versich-darktext-color text-base lg:text-lg font-semibold">There are no active requests</p>
                                {isClient && <p className="text-versich-darktext-color text-xs lg:text-sm">Please create a new request. Need something else?</p>}
                            </div>
                            {isClient &&
                                <div className="text-center mt-4">
                                    <DashboardConfirmBtn onClick={navigateToNewRequest}>Place new request</DashboardConfirmBtn>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}
export default Requests