import Search2 from "../svgs/Search2"
import ConfirmButton from "./ConfirmButton"

const SearchButton = ({ label = "Search" }) => {
    return (
        <ConfirmButton className="primary_btn h-full flex items-center gap-2 max-sm401:px-2 !py-2 relative z-[1]" type="submit">
            <Search2 className="w-6 h-6" />
            <span className="max-sm:hidden">{label}</span>
        </ConfirmButton>
    )
}
export default SearchButton