import { useMemo } from "react"
import { formatDistanceToNow } from "date-fns"

/**
 * useFormattedDate
 *
 * Accepts a date input (string or Date) and returns:
 * - localDate: The date formatted to the locale string.
 * - distance: The relative time to now.
 *
 * @param {string|Date} inputDate - The date to format.
 * @returns {{ localDate: string, distance: string }}
 */
const useFormattedDate = (inputDate) => {
    return useMemo(() => {
        const date = new Date(inputDate)
        const localDate = date.toLocaleDateString()
        const distance = formatDistanceToNow(date, { addSuffix: true })
        return { localDate, distance }
    }, [inputDate])
}

export default useFormattedDate
