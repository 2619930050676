import languageIcon from "../../../assets/icons/dashboard/language.svg"

const Languages = ({ data }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Languages Spoken</div>
            <div>
                {data.length > 0 && data.map((language, index) => (
                    <div className="text-versich-dark-blue text-sm mt-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EDF0F5]/50" key={index}>
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={languageIcon} alt="" />
                                </div>
                            </div>
                            <div className="space-y-1.5">
                                <p className="font-semibold">{language.language.name}</p>
                                <p>{language.proficiency.title}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Languages