import { useNavigate } from "react-router-dom"
import { useCallback, memo } from "react"
import { PiChatCircleTextBold } from "react-icons/pi"

import useClientMessageHandler from "../../../hooks/useClientMessageHandler"
import { contactProfessionalViaProposalDialog } from "./ContactProfessionalViaProposalDialog"

const StartOrNavigateMessageButton = ({ conversation }) => {
    const navigate = useNavigate()

    // Get the client messaging handler
    const clientMessageHandler = useClientMessageHandler(navigate)

    // Wrap the returned handler in a callback that passes the conversation and event.
    const handleMessageClick = useCallback((e) => {
        clientMessageHandler(conversation, e)
    }, [clientMessageHandler, conversation])
    return (
        <div className="text-sm sm:text-base">
            <button className="inline-flex items-center gap-2 relative accent_btn" onClick={handleMessageClick} data-parent-id={contactProfessionalViaProposalDialog}>
                <div className="w-full h-full absolute z-10 inset-0" data-parent-id={contactProfessionalViaProposalDialog} />
                <PiChatCircleTextBold className="w-4 h-4 sm:w-5 sm:h-5" />
                <span>Message</span>
            </button>
        </div>
    )
}
export default memo(StartOrNavigateMessageButton)