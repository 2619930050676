import { useSelector, useDispatch } from "react-redux"

import curvedEditIcon from "../../../assets/svgs/curved-edit.svg"
import ProfileUploader from "../../ProfileUploader"
import Tagline from "./freelancer/Tagline"
import { titleCase } from "../../../utils"
import { BUSINESS, FREELANCER } from "../../../assets/constants"

const Profile = ({ children }) => {
    const { user } = useSelector(state => state.auth)

    const fullName = user.accountType === BUSINESS ? user.business.details.data.companyName : user.about.firstName + " " + user.about.lastName
    const dispatch = useDispatch()

    const isFreelancer = user.accountType === FREELANCER
    return (
        <div className="md:col-span-2 xl:col-span-1 space-y-5">
            <div className="flex flex-col items-center justify-center gap-[25px] py-[42px] px-5 shadow-card5 rounded-lg">
                <ProfileUploader user={user} name="companyLogo" dispatch={dispatch}>
                    <label htmlFor={"companyLogo"} className="bg-[#FAFCFF] group-hover:bg-blue-100 flex items-center justify-center w-[35px] h-[35px] sm:w-10 sm:h-10 rounded-full cursor-pointer absolute right-0 -bottom-[5px]">
                        <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" />
                    </label>
                </ProfileUploader>
                <div className="text-versich-dark-blue text-center">
                    <div className="text-xl font-semibold">{fullName}</div>
                    <div className="text-sm mt-0.5">{titleCase(user.accountType)}</div>
                </div>
                {isFreelancer &&
                    <Tagline />
                }
            </div>
            {children}
        </div>
    )
}
export default Profile
