import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"

import ErrorPage from "../ErrorPage/ErrorPage"
import { FREELANCER_TYPES } from "../../assets/constants"

const BusinessOrFreelancerRoutes = () => {
    const { user } = useSelector(state => state.auth)
    if (user && FREELANCER_TYPES.includes(user.accountType) === false) {
        return <ErrorPage status={403} />
    }
    return <Outlet />
}
export default BusinessOrFreelancerRoutes