import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const getHumanCalculationsAsync = createAsyncThunk("website/HumanCalculations", async () => {
    try {
        const { data } = await api.getHumanCalculations()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})