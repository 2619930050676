import { Link } from "react-router-dom"

import {
    homePath,
    termsOfServicePath, privacyPolicyPath, cookiesPolicyPath
} from "../../assets/constants"
import logo from "../../assets/logo.svg"
import SocialIcons from "./SocialIcons"
import ForClients from "./ForClients"
import ForProfessionals from "./ForProfessionals"
import ForBusinesses from "./ForBusinesses"
import About from "./About"
import { useMemo } from "react"

const Footer = () => {
    const date = useMemo(() => {
        return new Date().getFullYear()
    }, [])
    return (
        <div className="bg-[#F6F6F6] py-4 px-8 md:px-16 xl:px-28 shadow-top2">
            <div className="max_screen_w">
                <div className="flex flex-col lg:flex-row gap-2 border-b border-versich-light-blue/40 border-solid py-7 justify-between">
                    <div className="flex flex-col text-start gap-y-4 pb-3 w-full lg:w-[350px]">
                        <Link to={homePath} className="flex items-center">
                            <img src={logo} alt="logo" className="w-[140px] smaller:w-[160px] lg:w-[180px]" />
                        </Link>
                        <p className="text-sm">Discover talent, showcase your business, and unlock new opportunities—all in one platform.</p>
                    </div>
                    <SocialIcons />
                </div>
                <div className="text-start py-12 flex flex-col md:flex-row justify-between gap-y-6 border-b-2 border-white/20 border-solid gap-x-7 lg:gap-x-16">
                    <ForClients />
                    <ForProfessionals />
                    <ForBusinesses />
                    <About />
                </div>
                <div className="max-smaller:text-sm py-4 flex flex-col items-center justify-center gap-1.5">
                    <p>{date} VersiMarket, a <a className="hover:underline underline-offset-2" href="https://versich.com" target="_blank">Versich</a> division.</p>
                    <p className="opacity-80">
                        <Link className="hover:text-versich-blue-hover" to={termsOfServicePath}>Terms of Service</Link> | <Link className="hover:text-versich-blue-hover" to={privacyPolicyPath}>Privacy Policy</Link> | <Link className="hover:text-versich-blue-hover" to={cookiesPolicyPath}>Cookies Policy</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer
