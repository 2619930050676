import { ImAttachment } from "react-icons/im"

const ProposalDetailsCard = ({ data }) => {
    const { content, attachment } = data
    return (
        <div className="px-5 md:px-6 py-3.5 border border-versich-light-blue/20 rounded-2xl">
            <h3 className="text-base sm:text-lg lg:text-xl font-medium pt-2 md:pt-4">Cover Letter</h3>
            <div className="my-3 md:my-6">
                <div className="text-sm font-medium">
                    {content?.split("\n").map((line, index) => (
                        <p className="min-h-4" key={index}>{line}</p>
                    ))}
                </div>
                {attachment &&
                    <div className="font-medium inline-flex flex-col gap-2 mt-3 md:mt-4">
                        <p className="text-sm sm:text-base lg:text-lg pt-4">Attachments</p>
                        <div
                            className="bg-majorelle-blue/[0.04] hover:bg-majorelle-blue/10 text-versich-darktext-color flex items-center justify-center gap-1 p-2 rounded-[10px] cursor-pointer group transition-colors"
                            onClick={() => window.open(attachment.url)}>
                            <ImAttachment />
                            <span className="group-hover:text-black text-xs text-center transition-colors">file{attachment.extension ? `.${attachment.extension}` : ""}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default ProposalDetailsCard