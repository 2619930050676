import { useState } from "react"

import scheduleIcon from "../../../../../assets/icons/request/schedule.png"
import RequestFormLayout from "./RequestFormLayout"
import RadioOptions from "./RadioOptions"
import { allProjectDurationAsync } from "../../../../../slices/service"

const ProjectDuration = ({ totalForms, formPosition, updateFormPosition, setProjectDuration, setOtherProjectDuration, goBack }) => {
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setProjectDuration(value)
                setOtherProjectDuration(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setProjectDuration(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <RequestFormLayout
            icon={scheduleIcon}
            description="When do you need an expert?"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">How soon would you like the projects to begin?</p>
                <RadioOptions
                    actionAsync={allProjectDurationAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </RequestFormLayout>
    )
}
export default ProjectDuration
