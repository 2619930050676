import { memo } from "react"
import { IoWarning } from "react-icons/io5"

import versiGemIcon from "../../../assets/images/versigem2.0.png"

export const getHasRequiredBalance = (available, required) => {
    // Has required 'VersiGems' balance
    return available && required && available >= required
}

const FreelancerProposalStatus = memo(({ availableVersiGems, requiredVersiGems, navigateToBuyVersiGemsPage }) => {
    const hasRequiredBalance = getHasRequiredBalance(availableVersiGems, requiredVersiGems)
    return (
        <div>
            <div className="bg-versich-lighter-blue/[0.03] flex justify-between gap-2 p-4 sm:p-6 lg:p-8 rounded-xl">
                <div className="space-y-2">
                    <p>Available VersiGems: {availableVersiGems}</p>
                    <p>VersiGems per proposal: {requiredVersiGems || 0}</p>
                    {hasRequiredBalance
                        ? <p>Balance after proposal: {availableVersiGems ? availableVersiGems - requiredVersiGems : 0}</p>
                        : (<>
                            <p className="text-majorelle-blue inline-flex items-center gap-1">
                                <IoWarning className="w-4 h-4" />
                                <span>Not enough VersiGems</span>
                            </p>
                            <div>
                                <button className="accent_btn" onClick={navigateToBuyVersiGemsPage}>Buy VersiGems</button>
                            </div>
                        </>)
                    }
                </div>
                <img className="w-6 h-6 opacity-75" src={versiGemIcon} alt="VersiGems" />
            </div>
        </div>
    )
})
export default FreelancerProposalStatus