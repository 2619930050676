import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"

import DashboardLayout from "../../components/Dashboard/DashboardLayout"
import Meta from "../../components/Meta"
import FormLoader from "../../components/loader/FormLoader"
import versiGemIcon from "../../assets/images/versigem2.0.png"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import { getFreelancerVersiGemsPlansAsync, createFreelancerCheckoutSessionAsync } from "../../slices/payments/freelancer"
import { dashboardPath } from "../../assets/constants"

const metadata = {
    title: "Buy VersiGems - VersiMarket",
    description: "Buy versigems for freelancer account",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/plans/versigems/buy/`
}

const BuyVersiGems = () => {
    const { user } = useSelector((state) => state.auth)
    const navigate = useNavigate()

    const [plans, setPlans] = useState([])
    const [csrfToken, setCsrfToken] = useState("")
    const [plan, setPlan] = useState({ priceId: "", amount: 0, quantity: 0, title: "" })
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Fetch plans
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getFreelancerVersiGemsPlansAsync())
            .unwrap()
            .then(data => {
                setPlans(data.payload)
                setPlan(data.payload[0]) // set default
            })
            .catch(() => { })
    }, [])
    const handlePlanChange = (e) => {
        const priceId = e.target.value
        const filteredPlans = plans.filter(plan => plan.priceId === priceId)
        if (filteredPlans.length > 0) {
            setPlan(filteredPlans[0])
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true
        setLoading(true)
        await dispatch(createFreelancerCheckoutSessionAsync({ priceId: plan.priceId, token: csrfToken }))
            .unwrap()
            .then(data => {
                window.location.href = data.url
            })
            .catch((error) => {
                submitBtn.disabled = false
                const data = JSON.parse(error?.message)
                setErrorMsg(data.message)
                setLoading(false)
            })
    }
    return (
        <DashboardLayout activePage="VersiGems">
            <div className="py-10 md:py-14 mb-12 flex items center justify-center">
                <Meta data={metadata} />
                <div className="w-full bg-white shadow-formLight py-5 md:py-10 px-5 md:px-10 max-w-[800px] rounded-md space-y-6">
                    <div className="space-y-5">
                        <h2 className="text-xl sm:text-2xl leading-normal text-versich-dark-blue font-medium flex items-center justify-center gap-1.5 sm:gap-3 sm401:px-12 py-4">
                            <img className="w-8 h-8 sm:w-12 sm:h-12" src={versiGemIcon} alt="" />
                            <span>Buy VersiGems</span>
                        </h2>
                    </div>
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        <div className="text-start">
                            <p className="text-versich-label mb-1">Your available VersiGems</p>
                            <p className="mb-3">{user.versigems ? user.versigems : <span className="text-red-500">0</span>}</p>
                        </div>
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <label htmlFor="plan" className="text-versich-label text-sm">Select the amount to buy</label>
                            </div>
                            <div className="relative">
                                <select className="bg-white w-full h-10 rounded-lg border-[1px] border-versich-border px-3 pr-8 appearance-none focus:outline-none focus:border-versich-blue cursor-pointer"
                                    id="plan"
                                    name="plan"
                                    value={plan.priceId}
                                    onChange={handlePlanChange}
                                >
                                    {plans.map(plan => (
                                        <option value={plan.priceId} key={plan.priceId}>{plan.title}</option>
                                    ))}
                                </select>
                                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                                </div>
                            </div>
                        </div>
                        <div className="text-start">
                            <p className="text-versich-label mb-1">Your charges will be</p>
                            <p className="mb-3">${plan.amount} + Tax</p>
                        </div>
                        <div className="text-start">
                            <p className="text-versich-label mb-1">Your new VersiGems balance will be</p>
                            <p className="mb-3">{(user.versigems ? user.versigems : 0) + plan.quantity}</p>
                        </div>
                        {errorMsg && <div className="text-sm text-red-500 text-left !mt-8 pt-6 border-t border-t-versich-border/50">{errorMsg}</div>}
                        <div className="flex justify-between pt-6">
                            <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-5 lg:px-7" type="submit">Buy</button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </div>
        </DashboardLayout>
    )
}
export default BuyVersiGems