import { useForm } from "react-hook-form"
import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import DirectContactDialogLayout from "./DirectContactDialogLayout"
import Avatar from "../../Avatars/Avatar"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import TextAreaField from "../../Inputs/TextAreaField"
import { closeDialog, getDialog } from "../../DialogBox"
import { TOASTTIMER, TOASTTYPE_ERROR } from "../../../assets/constants"
import { createConversationAsync } from "../../../slices/message"
import { titleCase } from "../../../utils"
import toastify from "../../../utils/toastify"

const ClientDirectContactDialog = ({ userId, setContacted }) => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [message, setMessage] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Close dialog
    const customCloseDialog = (e) => {
        setMessage("")
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            setLoading(true)
            const formData = {
                participantId: userId,
                content: message,
                token: csrfToken,
            }
            const closeDialogAndSetNotification = (form, message, conversationId) => {
                const dialog = getDialog(form)
                if (dialog) {
                    closeDialog(null, dialog)
                }
                setContacted({
                    status: true,
                    message, conversationId
                })
                setTimeout(() => {
                    setContacted({
                        status: false,
                        message: "",
                        conversationId: 0
                    })
                }, TOASTTIMER * 6)
            }
            await dispatch(createConversationAsync(formData))
                .unwrap()
                .then((data) => {
                    closeDialogAndSetNotification(event.target, "Message sent to business.", data.payload)
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setLoading(false)
                    if (data.status === TOASTTYPE_ERROR && data.shortInfo === "duplicate-conversation") {
                        return closeDialogAndSetNotification(event.target, "Already contacted business!", data.payload)
                    }
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <DirectContactDialogLayout
            customCloseDialog={customCloseDialog}
            description="Send your message directly to business"
            onSubmit={onSubmit}
            errorMsg={errorMsg}
            loading={loading}
        >
            <div className="flex max-sm:flex-wrap items-center gap-2 sm401:gap-2.5 mb-6 sm401:mb-8">
                <Avatar
                    user={user}
                    fullName={user.name}
                    ringClass="min-w-16 w-16 h-16 sm401:h-20 sm401:w-20 border border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10"
                    initialClass="text-lg sm401:text-xl lg:text-2xl"
                />
                <div className="grid items-center gap-x-4 gap-y-0.5">
                    <h2 className="text-versich-dark-blue text-lg md:text-xl font-semibold">
                        {user.name}
                    </h2>
                    <p className="text-versich-darktext-color text-xs sm401:text-sm">{titleCase(user.accountType)}</p>
                </div>
            </div>
            <TextAreaField
                name="message"
                label="Message"
                placeholder="Write your message here"
                onChange={(e) => setMessage(e.target.value)}
                defaultValue={message}
                rules={{ required: "Message is required" }}
                height="min-h-40 max-h-[400px]"
            />
            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
        </DirectContactDialogLayout>
    )
}
export default ClientDirectContactDialog