import hero from "../../../assets/images/mask-hero.png"
import SearchBar from "./SearchBar"

const Hero = () => {
    return (
        <div style={{ backgroundImage: `url(${hero})` }} className="background-image bg-cover bg-center bg-no-repeat">
            <div className="text-versich-darktext-color w-full h-full px-8 md:px-20 lg:px-48 pt-[120px] pb-[80px]">
                <div className="max_screen_w">
                    <div className="text-3xl sm:text-4xl lg:text-5xl font-[700]">
                        <p className="text-versich-light-blue text-2xl sm:text-3xl lg:text-4xl font-bold pb-4">All-in-One Marketplace for </p>
                        <h1 className="leading-tight">
                            <div>Freelancers, Consultants, & </div>
                            <div>Businesses to Connect and Grow.</div>
                        </h1>
                        <p className="text-base sm:text-lg lg:text-xl tracking-wide py-6 font-normal">Find Talent. Find Work. List Your Business.</p>
                    </div>
                    <SearchBar />
                </div>
            </div>
        </div>
    )
}
export default Hero
