import { GoChevronLeft, GoChevronRight } from "react-icons/go"

const chevronButtonClass = "hover:bg-versich-lighter-blue/5 disabled:bg-transparent w-8 h-8 sm401:w-9 sm:w-10 sm401:h-9 sm:h-10 inline-flex justify-center items-center border border-versich-light-blue/25 hover:border-versich-blue/60 disabled:border-versich-light-blue/15 rounded-full disabled:cursor-not-allowed transition-colors group"
const chevronClass = "fill-versich-light-blue/80 text-sm sm401:text-base sm:text-xl group-disabled:fill-versich-light-blue/40"
const pageNumberClass = "text-xs sm401:text-sm sm:text-base w-7 sm401:w-8 sm:w-9 h-7 sm401:h-8 sm:h-9 inline-flex items-center justify-center border border-versich-light-blue/20 rounded-lg"

const Paginator = ({ pages, currentPage, setCurrentPage, maxPages = 3 }) => {
    let _pages = []
    let startingPage = currentPage > 2 ? currentPage - 1 : 1
    let endingPage = startingPage + maxPages
    if (endingPage > pages) {
        endingPage = pages
        startingPage = pages > maxPages ? pages - maxPages : 1
    }
    if (pages <= startingPage) {
        return null
    }
    for (let i = startingPage; i <= endingPage; i++) {
        _pages.push(i)
    }
    const noPreviousPage = currentPage === 1
    const noNextPage = currentPage === pages
    return (
        <div className="flex justify-center gap-3 sm401:gap-4 sm:gap-5 mt-6 mb-12">
            <button
                className={chevronButtonClass}
                onClick={
                    noPreviousPage
                        ? null
                        : () => setCurrentPage((prev) => Math.max(prev - 1, 1))
                }
                disabled={noPreviousPage}
                title="Previous"
            >
                <GoChevronLeft className={chevronClass} />
            </button>
            <div className="text-base flex items-center gap-1.5 sm401:gap-2 sm:gap-2.5">
                {_pages.map(page => (
                    currentPage === page
                        ? <span className={pageNumberClass + " bg-versich-light-blue text-white cursor-default"} key={page}>{page}</span>
                        : <span className={pageNumberClass + " hover:bg-versich-lighter-blue/5 hover:border-versich-blue/60 cursor-pointer transition-colors"} onClick={() => setCurrentPage(page)} key={page}>{page}</span>
                ))}
            </div>
            <button
                className={chevronButtonClass}
                onClick={
                    noNextPage
                        ? null
                        : () => setCurrentPage((prev) => prev < pages ? prev + 1 : prev)
                }
                disabled={noNextPage}
                title="Next"
            >
                <GoChevronRight className={chevronClass} />
            </button>
        </div>
    )
}
export default Paginator