import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const createRequestAsync = createAsyncThunk("service/FT/createRequest",
    async ({
        businessSize, service, projectDuration, otherProjectDuration, paymentType, currency, budget, otherBudget,
        clientEmail, clientName, title, description, token
    }) => {
        try {
            const reqData = {
                requestType: "finance-transformation--other",
                businessSizeId: businessSize,
                service: service,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                projectDurationId: projectDuration,
                otherProjectDuration,
                clientEmail,
                clientName,
                title,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = await api.createRequest(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })