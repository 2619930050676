import { useState } from "react"

import checkListIcon from "../../../../../../assets/icons/request/checklist.png"
import RequestFormLayout from "../../components/RequestFormLayout"
import RadioOptions from "../../components/RadioOptions"
import { allOperatingSystemAsync } from "../../../../../../slices/client/service/softwareDevelopment"

const OperatingSystem = ({ totalForms, formPosition, updateFormPosition, defaultValue, setOperatingSystem, setOtherOperatingSystem, goBack }) => {
    const [value, setValue] = useState(defaultValue || "")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setOperatingSystem(value)
                setOtherOperatingSystem(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setOperatingSystem(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <RequestFormLayout
            icon={checkListIcon}
            description="Select Operating system type."
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">Which operating system compatibility would you like to have?</p>
                <RadioOptions
                    actionAsync={allOperatingSystemAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </RequestFormLayout>
    )
}
export default OperatingSystem
