import { useEffect } from "react"

import documentEditIcon from "../../../../../assets/icons/request/document-edit.png"
import RequestFormLayout from "./RequestFormLayout"
import RichTextField from "../../../../../components/RichTextField/RichTextField"

const TitleAndDescription = ({ totalForms, formPosition, updateFormPosition, title, setTitle, description, setDescription, titleError, descError, goBack }) => {
    useEffect(() => {
        updateFormPosition()
    }, [])
    return (
        <RequestFormLayout
            icon={documentEditIcon}
            description="Explain what you need so we get you experts to meet your needs"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            widerForm={true}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-5">Title is the first thing freelancers see, so make it clear and specific</p>
                <div className="flex flex-col gap-y-2 mt-1 mb-10">
                    <input
                        className={"max-md:text-sm flex-1 h-10 py-2 px-3 border rounded-lg outline-none " + (titleError ? "border-red-500" : "border-versich-border")}
                        name="title" type="text" placeholder="Enter title" onChange={(e) => setTitle(e.target.value)} value={title}
                    />
                    {titleError && <p className="text-red-500 text-start text-sm">Title can't be empty</p>}
                </div>
                <p className="text-versich-dark-blue text-lg font-semibold pb-5">Describe what you need, The more detailed, the better</p>
                {descError && <p className="text-red-500 text-start text-sm mb-3">Required description, can't leave it empty.</p>}
                <div className={"mb-5 no-twp "
                    + (descError ? "_error" : "")
                }>
                    <RichTextField onChange={(value) => setDescription(value)} value={description} />
                </div>
            </div>
        </RequestFormLayout>
    )
}
export default TitleAndDescription
