import { useSelector } from "react-redux"
import { useLocation, Navigate } from "react-router-dom"
import { Outlet } from "react-router-dom"

import ErrorPage from "../ErrorPage/ErrorPage"
import { FREELANCER_TYPES, loginPath } from "../../assets/constants"

const BusinessOrFreelancerOnlyRoutes = () => {
    const { user } = useSelector(state => state.auth)
    const location = useLocation()
    const path = window.encodeURIComponent(location.pathname + location.search)
    if (user) {
        if (FREELANCER_TYPES.includes(user.accountType) === false) {
            return <ErrorPage status={403} />
        }
        return <Outlet />
    }
    return <Navigate to={`${loginPath}?next=${path}`} replace={true} />
}
export default BusinessOrFreelancerOnlyRoutes