import { useSelector } from "react-redux"

import AccountSettingsLayout from "../../../components/Dashboard/accountSettings/AccountSettingsLayout"
import Profile from "../../../components/Dashboard/accountSettings/Profile"
import PersonalDetails from "../../../components/Dashboard/accountSettings/client/PersonalDetails"
import ProfileDetails from "../../../components/Dashboard/accountSettings/client/ProfileDetails"
import AccountAndSecurity from "../../../components/Dashboard/accountSettings/accountAndSecurity/AccountAndSecurity"

const CLientAccountSettings = ({ metadata }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <AccountSettingsLayout metadata={metadata}>
            <Profile />
            <div className="md:col-span-3 xl:col-span-2 space-y-5">
                <PersonalDetails />
                <ProfileDetails />
                <AccountAndSecurity isSocial={user.social} />
            </div>
        </AccountSettingsLayout>
    )
}
export default CLientAccountSettings