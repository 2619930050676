import { useParams } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import useBackNavigation from "../../../hooks/useBackNavigation"
import ErrorPage from "../../../components/ErrorPage/ErrorPage"
import Meta from "../../../components/Meta"
import SideNavbar from "../../../components/Navbar/DashSideNavbar"
import Header from "../../../components/Dashboard/Header/Header"
import chevronRightIcon from "../../../assets/svgs/chevron-right.svg"
import noMessages from "../../../assets/images/no-messages.svg"
import ConversationList from "../../../components/Dashboard/messages/ConversationList"
import SectionLoader from "../../../components/loader/SectionLoader"
import ConversationDetails from "../../../components/Dashboard/messages/conversationDetails/ConversationDetails"
import {
    CLIENT, BUSINESS, SS_VERIFICATION_STATUS,
    messagesPath, dashboardPath
} from "../../../assets/constants"
import {
    BusinessMenu, FreelancerMenu, ClientMenu,
    FindBusinesses, FindJobs
} from "../../../components/Dashboard/constants"
import { getConversationsAsync } from "../../../slices/message"
import "./index.css"

const metadata = {
    title: "Messages",
    description: "Messages page to view all messages",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/messages/`
}

const Messages = () => {
    const { id } = useParams()
    const { user } = useSelector(state => state.auth)
    const [conversations, setConversations] = useState([])
    const [selectedConversation, setSelectedConversation] = useState(undefined)

    // Load conversations
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getConversationsAsync())
            .unwrap()
            .then(data => {
                if (data.payload && data.payload.length > 0) {
                    setConversations(data.payload)
                    if (!id) {
                        setSelectedConversation(false)
                        return
                    }
                    const conversations = data.payload.filter(c => c.id === window.parseInt(id))
                    if (conversations.length === 0) {
                        return setSelectedConversation(null)
                    }
                    setSelectedConversation(conversations[0])
                }
                else setConversations(null)
            })
            .catch(() => { })
    }, [id, user])

    // Clean session cookie
    window.sessionStorage.removeItem(SS_VERIFICATION_STATUS.key)

    let navMenu, usefullLinks = null
    if (user.accountType === CLIENT) {
        navMenu = ClientMenu
        usefullLinks = { ...FindBusinesses }
    }
    else if (user.accountType === BUSINESS) {
        navMenu = BusinessMenu
        usefullLinks = { ...FindJobs, ...FindBusinesses }
    }
    else {
        navMenu = FreelancerMenu
        usefullLinks = { ...FindJobs }
    }

    // Navigation callback for the "Back" button
    const handleBack = useBackNavigation(selectedConversation ? messagesPath : dashboardPath)
    return (
        selectedConversation !== null
            ? <div className="bg-[#FBFBFF] flex">
                <Meta data={metadata} />
                <SideNavbar navlinks={navMenu} activePage="Messages" usefullLinks={usefullLinks} />
                <div className="w-full h-svh flex flex-col">
                    <Header usefullLinks={usefullLinks} />
                    <div className="flex-grow w-full h-full max-h-[calc(100%-80px)] pt-2.5 px-[14px] pb-5 space-y-2.5">
                        <div className="flex flex-col gap-y-2.5 h-full">
                            <div className={"space-y-1.5 "
                                + (selectedConversation ? "" : "max-xl1080:hidden")
                            }>
                                <div className="flex justify-start pt-1">
                                    <button className="inline-flex items-center gap-0.5" onClick={handleBack}>
                                        <img src={chevronRightIcon} alt="" />
                                        <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                                    </button>
                                </div>
                            </div>
                            <div className="bg-white text-start flex-grow flex flex-col max-xl1080:mt-5 py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                                <div className="text-versich-dark-blue text-start">
                                    <div className="text-lg lg:text-xl font-semibold">Messages</div>
                                </div>
                                {conversations
                                    ? conversations.length > 0
                                        ? <div className="flex-grow flex mt-8">
                                            <ConversationList
                                                className={selectedConversation ? "hidden lg900:flex" : "flex"}
                                                user={user}
                                                conversations={conversations}
                                                selectedConversationId={selectedConversation?.id}
                                            />
                                            {selectedConversation
                                                ? <ConversationDetails conversation={selectedConversation} />
                                                : selectedConversation === false
                                                    ? <div className="flex-grow hidden lg900:flex flex-col items-center justify-center">
                                                        <div>
                                                            <img className="w-full h-full max-w-[400px] max-h-[400px]" src={noMessages} alt="no messages" />
                                                        </div>
                                                        <p className="text-versich-darktext-color text-sm -mt-5">Select a conversation to start messaging</p>
                                                    </div>
                                                    : <SectionLoader />
                                            }
                                        </div>
                                        : <SectionLoader />
                                    : <div className="flex-grow flex flex-col items-center justify-center">
                                        <img className="w-[400px]" src={noMessages} alt="no messages" />
                                        <p className="text-versich-darktext-color text-sm -mt-5">No messages to start a conversation</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            : <ErrorPage status={404} />
    )
}
export default Messages