import { Link, useLocation, useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdCreate, MdOutlineUpdate, MdCalendarMonth } from "react-icons/md"
import { FaSackDollar } from "react-icons/fa6"
import { GrMoney } from "react-icons/gr"
import { GiMoneyStack } from "react-icons/gi"
import Cookies from "js-cookie"
import parse from "html-react-parser"

import DashboardConfirmBtn from "../../../../components/Buttons/DashboardConfirmBtn"
import { fetchPrimaryAndSubService } from "../../../../components/FindJobs/utils"
import { COOKIE_REQUEST_REFID, findJobsPath } from "../../../../assets/constants"
import { getRelaventExpertsPath, getServiceJobsPath, toConversationDetails } from "../../../../assets/path"

const tagClass = "bg-brandeis-blue/[0.08] text-versich-light-blue text-xs md:text-sm capitalize py-1 px-2.5 border border-versich-lighter-blue/5 rounded-2xl"

const RequestCard = ({ data, ctaButton }) => {
    const { refId, createdAt, updatedAt, details } = data

    const navigate = useNavigate()
    const viewExpert = () => {
        Cookies.set(COOKIE_REQUEST_REFID, refId, { expires: 1 })
        const relaventExpertsPath = getRelaventExpertsPath(details.serviceId)
        navigate(relaventExpertsPath)
    }

    // Fetch service 'title & uid'
    const service = fetchPrimaryAndSubService(details.serviceId, details.requestType)

    // Search job by 'tag' name
    const location = useLocation()
    const searchByTag = (name) => {
        const term = `search=${encodeURIComponent(name)}`
        const string = location.search ? `${location.search}&${term}` : `?${term}`
        navigate(findJobsPath + string)
    }
    return (
        <div className="p-5 md:p-10 space-y-4">
            <div className="text-start space-y-5">
                <div className="flex max-md:flex-col mb-1.5 md:mb-8">
                    <div className="flex-1 p-3">
                        <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">{details.title}</p>
                        <div className="text-xs md:text-sm flex flex-col smaller:flex-row gap-y-3 gap-x-4">
                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdCreate /> Posted {formatDistanceToNow(createdAt, { addSuffix: true })}</div>
                            <div className="inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Updated {formatDistanceToNow(updatedAt, { addSuffix: true })}</div>
                        </div>
                    </div>
                    <div className="pt-3 pl-3">
                        {ctaButton
                            ? ctaButton
                            : data.conversationId
                                ? <DashboardConfirmBtn onClick={() => toConversationDetails(data.conversationId, navigate)}>Message Client</DashboardConfirmBtn>
                                : <DashboardConfirmBtn onClick={viewExpert}>View Expert</DashboardConfirmBtn>
                        }
                    </div>
                </div>
                <div className="text-sm p-3 py-4 md:py-6 border-t no-twp render_richtext">
                    {parse(details.description)}
                </div>
                <div className="text-sm grid md:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><FaSackDollar />Payment Type: <span className="text-versich-dark-blue font-medium">{details.paymentType.title}</span></div>
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><GrMoney />Budget: <span className="text-versich-dark-blue font-medium">{details.price}</span></div>
                    <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><GiMoneyStack />Currency: <span className="text-versich-dark-blue font-medium">{details.currency.title}</span></div>
                    {details.duration &&
                        <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5"><MdCalendarMonth />Project Duration: <span className="text-versich-dark-blue font-medium">{
                            details.duration.uid === "other" ? details.otherDuration : details.duration.title
                        }</span></div>
                    }
                </div>
                {(details.requirements || details.projectType || details.appPlatform
                    || details.needs || details.serviceNeed || details.businessType || details.businessSize
                    || details.industry || details.monetizationStrategy || details.decision)
                    &&
                    <div className="text-sm grid lg:grid-cols-2 gap-y-6 gap-x-4 justify-between p-3 py-4 md:py-6 border-t">
                        {details.requirements &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Requirements: <span className="text-versich-dark-blue font-medium">{details.requirements.title}</span></div>
                        }
                        {details.projectType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Project Type: <span className="text-versich-dark-blue font-medium">{
                                details.projectType.uid === "other" ? details.otherProjectType : details.projectType.title
                            }</span></div>
                        }
                        {details.appPlatform &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">App Platform: <span className="text-versich-dark-blue font-medium">{
                                details.appPlatform.uid === "other" ? details.otherAppPlatform : details.appPlatform.title
                            }</span></div>
                        }
                        {details.softwareType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Software Type: <span className="text-versich-dark-blue font-medium">{
                                details.softwareType.uid === "other" ? details.otherSoftwareType : details.softwareType.title
                            }</span></div>
                        }
                        {details.softwareNature &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Software Nature: <span className="text-versich-dark-blue font-medium">{
                                details.softwareNature.uid === "other" ? details.otherSoftwareNature : details.softwareNature.title
                            }</span></div>
                        }
                        {details.os &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Operating System: <span className="text-versich-dark-blue font-medium">{
                                details.os.uid === "other" ? details.otherOs : details.os.title
                            }</span></div>
                        }
                        {details.needs &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Needs: <span className="text-versich-dark-blue font-medium">{
                                details.needs.uid === "other" ? details.otherNeed : details.needs.title
                            }</span></div>
                        }
                        {details.serviceNeed &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Service Need: <span className="text-versich-dark-blue font-medium">{
                                details.serviceNeed.uid === "other" ? details.otherServiceNeed : details.serviceNeed.title
                            }</span></div>
                        }
                        {details.businessType &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Business Type: <span className="text-versich-dark-blue font-medium">{
                                details.businessType.uid === "other" ? details.otherBusiness : details.businessType.title
                            }</span></div>
                        }
                        {details.businessSize &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Business Size: <span className="text-versich-dark-blue font-medium">{details.businessSize.title} employees</span></div>
                        }
                        {details.industry &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Industry: <span className="text-versich-dark-blue font-medium">{
                                details.industry.uid === "other" ? details.otherIndustry : details.industry.title
                            }</span></div>
                        }
                        {details.monetizationStrategy &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Monetization Strategy: <span className="text-versich-dark-blue font-medium">{
                                details.monetizationStrategy.uid === "other" ? details.otherMonetizationStrategy : details.monetizationStrategy.title
                            }</span></div>
                        }
                        {details.serviceEngagement &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Service Engagement: <span className="text-versich-dark-blue font-medium">{
                                details.serviceEngagement.uid === "other" ? details.otherServiceEngagement : details.serviceEngagement.title
                            }</span></div>
                        }
                        {details.decision &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Hiring Decision: <span className="text-versich-dark-blue font-medium">{
                                details.decision.uid === "other" ? details.otherDecision : details.decision.title
                            }</span></div>
                        }
                        {details.service &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Custom Service: <span className="text-versich-dark-blue font-medium">{details.service}</span></div>
                        }
                        {details.expert &&
                            <div className="inline-flex max-smaller:flex-col smaller:items-center gap-1 md:gap-1.5">Expert: <span className="text-versich-dark-blue font-medium">{details.expert.title}</span></div>
                        }
                    </div>
                }
                {/* Skills & Expertise */}
                {(service.primary || service.sub || details.tags) &&
                    <div className="text-sm p-3 py-4 md:py-6 border-t space-y-4">
                        <div className="text-versich-dark-blue font-semibold text-lg">Skills & Expertise: </div>
                        <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                            {service.primary && (
                                <Link to={getServiceJobsPath(service.primary.uid)} className={tagClass} key={service.primary.uid}>
                                    {service.primary.title}
                                </Link>
                            )}
                            {service.sub && (
                                <Link to={getServiceJobsPath(service.sub.uid)} className={tagClass} key={service.sub.uid}>
                                    {service.sub.title}
                                </Link>
                            )}
                            {!details.services && !details.tools &&
                                details.tags &&
                                details.tags.map((tag) => (
                                    tag.uid !== "other" ? (
                                        <button onClick={() => searchByTag(tag.title)} className={tagClass} key={tag.uid}>
                                            {tag.title}
                                        </button>
                                    ) : null
                                ))
                            }
                        </div>
                    </div>
                }
                {/* Expert Services */}
                {details.services &&
                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                        {details.services &&
                            <div className="space-y-4">
                                <div className="text-versich-dark-blue font-semibold text-lg">Expert Services: </div>
                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                    {details.services.map(service => (
                                        <div className={tagClass} key={service.uid}>{service.title}</div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* Tools */}
                {details.tools &&
                    <div className="text-sm p-3 py-4 md:py-6 border-t">
                        {details.tools &&
                            <div className="space-y-4">
                                <div className="text-versich-dark-blue font-semibold text-lg">Prefered Tools: </div>
                                <div className="flex flex-wrap flex-col smallest:flex-row gap-2">
                                    {details.tools.map(tool => (
                                        <div className={tagClass} key={tool.uid}>{tool.title}</div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}
export default RequestCard