import { useState } from "react"

import officeIcon from "../../../../../assets/icons/request/office.png"
import RequestFormLayout from "./RequestFormLayout"
import RadioOptions from "./RadioOptions"
import { allIndustryTypeAsync } from "../../../../../slices/service"

const IndustryType = ({ totalForms, formPosition, updateFormPosition, setIndustryType, setOtherIndustryType, goBack }) => {
    const [value, setValue] = useState("")
    const [otherValue, setOtherValue] = useState("")
    const [error, setError] = useState(false)
    const [otherInputError, setOtherInputError] = useState(false)

    const handleContinue = () => {
        if (value === "other") {
            if (otherValue) {
                setIndustryType(value)
                setOtherIndustryType(otherValue)
            }
            else {
                setOtherInputError(true)
                setTimeout(() => {
                    setOtherInputError(false)
                }, 2000)
            }
        }
        else if (value) {
            setIndustryType(value)
        }
        else {
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        }
    }
    return (
        <RequestFormLayout
            icon={officeIcon}
            description="Type of industry you belong to"
            totalForms={totalForms} currentForm={formPosition}
            handleBackClick={goBack}
            handleContinueClick={handleContinue}
        >
            <div className="relative">
                <p className="text-versich-dark-blue text-lg font-semibold pb-3">What industry do you operate in?</p>
                <RadioOptions
                    actionAsync={allIndustryTypeAsync}
                    updateFormPosition={updateFormPosition}
                    value={value}
                    setValue={setValue}
                    error={error}
                    setOtherValue={setOtherValue}
                    otherInputError={otherInputError}
                />
            </div>
        </RequestFormLayout>
    )
}
export default IndustryType
