import { useState } from "react"
import { useDispatch } from "react-redux"
import Cookies from "js-cookie"

import DialogBox, { closeDialog, getDialog } from "../../DialogBox"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import ConfirmButton from "../../Buttons/ConfirmButton"
import { contactFreelancerDialog, COOKIE_REQUEST_REFID, TOASTTYPE_ERROR, TOASTTYPE_INFO } from "../../../assets/constants"
import { createConversationAsync } from "../../../slices/message"
import toastify from "../../../utils/toastify"

const ContactFreelancerDialog = ({ requestRefId, expertId, setContactState }) => {
    const [csrfToken, setCsrfToken] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!message) {
            setError(true)
            return setTimeout(() => {
                setError(false)
                submitBtn.disabled = false
            }, 2000)
        }

        setLoading(true)
        const refId = requestRefId ? requestRefId : Cookies.get(COOKIE_REQUEST_REFID)
        const formData = {
            requestRefId: refId,
            participantId: expertId,
            content: message,
            token: csrfToken
        }
        const closeDialogAndSetMessageLink = (form, conversationID = null, proposalID = null) => {
            const dialog = getDialog(form)
            if (dialog) {
                closeDialog(null, dialog)
            }
            setContactState({
                conversationID, proposalID
            })
        }
        await dispatch(createConversationAsync(formData))
            .unwrap()
            .then((data) => {
                closeDialogAndSetMessageLink(e.target, data.payload.conversationId)
                submitBtn.disabled = false
                return setLoading(false)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                setLoading(false)
                if (data.status === TOASTTYPE_ERROR) {
                    if (data.shortInfo === "duplicate-conversation") {
                        closeDialogAndSetMessageLink(e.target, data.payload.conversationId)
                        return toastify("Already contacted expert!", TOASTTYPE_INFO, submitBtn)
                    }
                    else if (data.shortInfo === "invalid-conversation") {
                        closeDialogAndSetMessageLink(e.target, null, data.payload.proposalId)
                        return toastify("Already received proposal from expert!", TOASTTYPE_INFO, submitBtn)
                    }
                }
                toastify(data.message, data.status, submitBtn, contactFreelancerDialog)
            })
    }
    return (
        <DialogBox
            id={contactFreelancerDialog}
            className="max-sm:max-w-full backdrop:bg-oxford-blue/20 sm:!max-w-[920px] !w-11/12 xl:!w-3/4"
            toastStacked={false}
            customCloseEvent={(e) => { setMessage(""); closeDialog(e) }}
        >
            <form className="bg-lighter bg-opacity-20 sm:col-span-2 mx-auto p-4 lg:p-4 !pt-0" onSubmit={handleSubmit} data-parent-id={contactFreelancerDialog}>
                <h2 className="text-versich-dark-blue font-semibold text-lg lg:text-xl text-start  flex items-center gap-1 sm:gap-2 lg:gap-3 mb-1.5 sm:mb-3">
                    <span>Tell the expert what you need</span>
                </h2>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <textarea className={"w-full min-h-60 max-h-[600px] mb-5 px-3 py-2 border outline-none rounded-lg " + (error ? "border-red-500" : "border-versich-border")}
                    name="message" placeholder="Your message"
                    onChange={e => setMessage(e.target.value)} value={message}
                />
                {error && <p className="text-red-500 text-start text-sm mb-1.5">Please enter a message</p>}
                <div className="max-w-fit mx-auto">
                    <ConfirmButton className="bg-versich-blue hover:bg-versich-blue-hover text-sm lg:text-base w-full text-white font-medium px-4 lg:px-5 py-3 rounded-lg" type="submit" loading={loading}>Send Message</ConfirmButton>
                </div>
            </form>
        </DialogBox >
    )
}
export default ContactFreelancerDialog