export const companySizes = [{
    uid: "soletrader",
    title: "Self-employed, Sole trader"
}, {
    uid: "2-10",
    title: "2-10"
}, {
    uid: "11-50",
    title: "11-50"
}, {
    uid: "51-200",
    title: "51-200"
}]

export const languageProficiencyOptions = [{
    uid: "basic",
    title: "Basic"
}, {
    uid: "conversational",
    title: "Conversational"
}, {
    uid: "fluent",
    title: "Fluent"
}, {
    uid: "native",
    title: "Native or Bilingual"
}]

// Filters
export const sortbyOptions = [{
    uid: "latest",
    title: "Latest"
}, {
    uid: "oldest",
    title: "Oldest"
}]
export const verifiedOptions = [{
    uid: "true",
    title: "Verified"
}, {
    uid: "false",
    title: "Unverified"
}]