import { useSelector, useDispatch } from "react-redux"
import { useForm, FormProvider } from "react-hook-form"
import { Navigate, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import BrandBar from "../../../../components/Navbar/BrandBar"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import InputText from "../../../../components/Inputs/InputTextField"
import TextAreaField from "../../../../components/Inputs/TextAreaField"
import PhoneNumberField, { validateNumber } from "../../../../components/Inputs/PhoneNumberField"
import ChoiceButton from "../../../../components/Buttons/ChoiceButton"
import DropdownField from "../../../../components/Inputs/DropdownField"
import toastify from "../../../../utils/toastify"
import { companySizes } from "../../../../assets/constantOptions"
import { TOASTTYPE_ERROR, dashboardPath, completeEducationPath } from "../../../../assets/constants"
import { freelancerUpdateAboutAsync } from "../../../../slices/freelancer/about"

const ProfileDetails = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [profileId, setProfileId] = useState("")
    const [tagline, setTagline] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [address, setAddress] = useState("")
    const [phoneCode, setPhoneCode] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [phoneNumberValid, setPhoneNumberValid] = useState(false)
    const [hasWebsite, setHasWebsite] = useState(false)
    const [website, setWebsite] = useState("")
    const [companySize, setCompanySize] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    useEffect(() => {
        if (!user || !user.about) {
            return
        }
        setFirstName(user.about.firstName)
        setLastName(user.about.lastName)
        if (user.about.profileId) {
            setProfileId(user.about.profileId)
        }
        setTagline(user.about.tagline)
        setCompanyName(user.about.companyName)
        setAddress(user.about.address)
        if (!user.about.phone) {
            return
        }
        const code = user.about.phone.code.replace("+", "")
        const fullPhoneNumber = code + user.about.phone.number
        setPhoneCode(code)
        setPhoneNumber(user.about.phone.number)
        setPhoneNumberValid(validateNumber(fullPhoneNumber))
        if (user.about.website) {
            setHasWebsite(true)
            setWebsite(user.about.website)
        }
        setCompanySize(user.about.companySize.uid)
    }, [user])

    useEffect(() => {
        if (user?.about?.profileId) {
            return
        }
        let idFromName = (firstName + lastName).trim().toLowerCase().replace(/ /g, "")
        setProfileId(idFromName)
    }, [firstName, lastName])

    const dispatch = useDispatch()
    const onSubmit = async (data) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid || !phoneNumberValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                token: csrfToken,
                firstname: data.firstname,
                lastname: data.lastname,
                profileid: data.profileId,
                tagline: data.tagline,
                companyname: data.companyName,
                address: data.address,
                phonecode: `+${phoneCode}`,
                phonenumber: phoneNumber,
                ...(hasWebsite && {
                    website: hasWebsite ? data.website : null,
                }),
                companysize: companySize,
            }
            setLoading(true)
            await dispatch(freelancerUpdateAboutAsync(formData))
                .unwrap()
                .then(() => {
                    navigate(completeEducationPath)
                    methods.reset()
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        !user.about.phone
            ? <FormProvider {...methods}>
                <BrandBar />
                <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                    <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight">
                        <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                            <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Complete your profile details</h1>
                        </div>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-5">
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                <InputText name="firstname" inputType="text" label="First Name" rules={{ required: "First name is required" }} onChange={(e) => setFirstName(e.target.value)} defaultValue={firstName} />
                                <InputText name="lastname" inputType="text" label="Last Name" rules={{ required: "Last name is required" }} onChange={(e) => setLastName(e.target.value)} defaultValue={lastName} />
                            </div>
                            <InputText
                                name="profileId"
                                inputType="text"
                                placeholder="e.g. steverogers"
                                label="Profile Identifier"
                                hint={`Your public profile link will look like: ${process.env.REACT_APP_CLIENT_URL}/freelancer/${profileId}`}
                                onChange={(e) => setProfileId(e.target.value)}
                                defaultValue={profileId}
                                rules={{ required: "Profile identifier is required" }}
                            />
                            <InputText
                                name="tagline"
                                inputType="text"
                                placeholder="Stand out with a short tagline that describes what you do."
                                label="Tagline"
                                onChange={(e) => setTagline(e.target.value)}
                                defaultValue={tagline}
                                rules={{ required: "Tagline is required" }}
                                maxLength={80}
                            />
                            <InputText name="companyName" inputType="text" label="Company Name" onChange={(e) => setCompanyName(e.target.value)} defaultValue={companyName} />
                            <TextAreaField name="address" label="Address" onChange={(e) => setAddress(e.target.value)} defaultValue={address} rules={{ required: "Address is required" }} />
                            <PhoneNumberField
                                name="phonenumber"
                                rules={{ required: "Phone number is required" }}
                                setPhoneCode={setPhoneCode}
                                setPhoneNumber={setPhoneNumber}
                                setPhoneNumberValid={setPhoneNumberValid}
                                value={phoneCode + phoneNumber}
                            />
                            <div className="text-start flex flex-col">
                                <p className="mb-2 text-versich-label text-sm">Does your company have a website?</p>
                                <div className="flex gap-2.5">
                                    <ChoiceButton selected={hasWebsite} onClick={() => setHasWebsite(prev => !prev)}>Yes</ChoiceButton>
                                    <ChoiceButton selected={!hasWebsite} onClick={() => setHasWebsite(prev => !prev)}>No</ChoiceButton>
                                </div>
                            </div>
                            {hasWebsite &&
                                <InputText name="website" inputType="url" placeholder="Website address" onChange={(e) => setWebsite(e.target.value)} defaultValue={website} rules={{ required: "Field is required" }} />
                            }
                            <DropdownField
                                name="companySize"
                                label="Company Size or No of Employees"
                                options={companySizes}
                                value={companySize}
                                placeholder="Select number of Employee"
                                rules={{ required: "Company size is required" }}
                                onChange={(e) => setCompanySize(e.target.value)}
                            />
                            {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && <FormLoader />}
            </FormProvider>
            : <Navigate to={completeEducationPath} replace />
    )
}
export default ProfileDetails
