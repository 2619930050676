import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { FaRegEye } from "react-icons/fa"
import Cookies from "js-cookie"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import FreelancerCard from "../../../../components/Requests/ExpertCard"
import ContactFreelancerDialog from "../../../../components/Requests/client/ContactFreelancerDialog"
import { serviceRelatedFreelancersAsync } from "../../../../slices/freelancer"
import { COOKIE_REQUEST_REFID } from "../../../../assets/constants"
import { toRequestDetails, toConversationDetails, toProposalDetails } from "../../../../assets/path"

const metadata = {
    title: "Request Confirmation",
    description: "VersiMarket user dashboard",
}

const RequestConfirmation = () => {
    const { id, pid, sid } = useParams()
    const [freelancers, setFreelancers] = useState([])
    const [expertId, setExpertId] = useState("")
    const [contactState, setContactState] = useState({
        conversationID: null, proposalID: null
    })

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(serviceRelatedFreelancersAsync(sid))
            .unwrap()
            .then(data => {
                Cookies.set(COOKIE_REQUEST_REFID, id, { expires: 1 })
                setFreelancers(data.recommendedFreelancers)
            })
            .catch(() => { })
    }, [])

    const navigate = useNavigate()
    return (
        <DashboardLayout activePage="My Requests">
            <div className="text-start mb-5 lg:mb-12">
                <div className="space-y-4">
                    <Meta data={metadata} />
                    <div className="bg-white shadow-card text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 rounded-xl">
                        <p>Request created with pid: {pid}</p>
                        <button className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5" onClick={() => toRequestDetails(id, navigate)} title="View Request">
                            <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View request
                        </button>
                    </div>
                    {contactState.conversationID &&
                        <div className="bg-brandeis-blue/5 border border-brandeis-blue/20 text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 shadow-sticker rounded-xl">
                            <p>Message sent to expert.</p>
                            <button
                                className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5"
                                onClick={() => toConversationDetails(contactState.conversationID, navigate)}
                                title="View Message">
                                <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View message
                            </button>
                        </div>
                    }
                    {contactState.proposalID &&
                        <div className="bg-brandeis-blue/5 border border-brandeis-blue/20 text-versich-dark-blue font-semibold text-start flex justify-between flex-col sm:flex-row gap-2 w-full px-4 py-4 sm:px-6 sm:py-6 shadow-sticker rounded-xl">
                            <p>Already got a proposal from the expert.</p>
                            <button
                                className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5"
                                onClick={() => toProposalDetails(contactState.proposalID, navigate)}
                                title="View Proposal">
                                <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" /> View proposal
                            </button>
                        </div>
                    }
                    <div className="bg-white shadow-card rounded-xl">
                        <div className="px-5 md:px-10 py-6 md:py-12 space-y-5">
                            <p className="text-xl lg:text-2xl text-versich-dark-blue font-semibold pb-3">Recommended Freelancers</p>
                            <div className="grid sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-y-10 gap-x-4 xl:gap-x-8 text-start">
                                {freelancers.map(freelancer => (
                                    <FreelancerCard user={freelancer} contactExpertClick={() => setExpertId(freelancer.id)} key={freelancer.id} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <ContactFreelancerDialog requestRefId={id} expertId={expertId} setContactState={setContactState} />
            </div>
        </DashboardLayout>
    )
}
export default RequestConfirmation