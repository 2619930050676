import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import DropdownFieldInput from "./DropdownFieldInput"
import { allLanguagesAsync } from "../../slices/account"

const LanguageDropdownFieldInput = ({ label, name, value, placeholder, setLanguageValue, required, error }) => {
    const [languages, setLanguages] = useState([])

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(allLanguagesAsync())
            .then(data => {
                setLanguages(data.payload.map(lang => {
                    return {
                        title: lang.name,
                        uid: lang.alpha2
                    }
                }))
            })
            .catch(() => { })
    }, [])
    return (
        <DropdownFieldInput
            name={name}
            label={label}
            options={languages}
            value={value}
            placeholder={placeholder}
            onChange={(e) => setLanguageValue(e.target.value)}
            required={required}
            error={error}
        />
    )
}
export default LanguageDropdownFieldInput
