import { useNavigate } from "react-router-dom"

import mask1 from "../../../assets/images/mask-pattern-1.2.png"
import mask2 from "../../../assets/images/mask-pattern-2.png"
import mask3 from "../../../assets/images/mask-pattern-3.png"
import icon1 from "../../../assets/icons/find-talent.png"
import icon2 from "../../../assets/icons/find-jobs.png"
import icon3 from "../../../assets/icons/list-business.png"
import { businessListingPath, findJobsPath, findProfessionalsPath } from "../../../assets/constants"

const UserActionsLayout = () => {
    const navigate = useNavigate()
    return (
        <div className="py-12 md:py-16 px-8 md:px-16 xl:px-28">
            <div className="max_screen_w">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5 md:gap-5">
                    <div className="bg-[#6473B6] text-white text-left flex flex-col max-md:px-5 p-6 rounded-2xl relative overflow-hidden">
                        <div style={{ backgroundImage: `url("${mask1}")` }} className="background-image bg-cover bg-center bg-no-repeat w-full h-full absolute inset-0"></div>
                        <span className="max-smaller:text-sm relative">For Clients</span>
                        <div className="flex-grow flex justify-end my-4 relative">
                            <div className="bg-white/30 backdrop-blur-sm inline-flex items-center justify-center w-[80px] h-[80px] rounded-xl">
                                <img className="w-[50px] h-[50px]" src={icon1} alt="" />
                            </div>
                        </div>
                        <h2 className="text-white text-xl smaller:text-2xl lg:text-3xl font-bold mt-4 pb-2 relative">Post a job and Find Talents immediately</h2>
                        <div className="my-6 relative">
                            <button className="text-white hover:bg-gray-100 hover:text-versich-dark-blue max-smaller:text-sm font-medium px-5 lg:px-6 py-2 border border-white/80 rounded-xl transition-colors" onClick={() => navigate(findProfessionalsPath)}>Hire Now</button>
                        </div>
                    </div>
                    <div className="bg-[#1D1E1E] text-white text-left flex flex-col max-md:px-5 p-6 rounded-2xl relative overflow-hidden">
                        <div style={{ backgroundImage: `url(${mask2})` }} className="background-image bg-cover bg-center bg-no-repeat w-full h-full absolute inset-0"></div>
                        <div className="bg-gradient-to-r from-[#1D1E1E] to-[rgba(128,132,132,0)] w-full h-full absolute inset-0"></div>
                        <span className="max-smaller:text-sm relative">For Freelancers and Consultants</span>
                        <div className="flex-grow flex justify-end my-4 relative">
                            <div className="bg-white/30 backdrop-blur-sm inline-flex items-center justify-center w-[80px] h-[80px] rounded-xl">
                                <img className="w-[50px] h-[50px]" src={icon2} alt="" />
                            </div>
                        </div>
                        <h2 className="text-white text-xl smaller:text-2xl lg:text-3xl font-bold mt-4 pb-2 relative">Find Jobs</h2>
                        <p className="max-smaller:text-sm smaller:inline-flex flex-col relative">
                            <span>Meet clients you’re excited to </span>
                            <span>work with. Find work for your skill</span>
                        </p>
                        <div className="my-6 relative">
                            <button className="text-white hover:bg-gray-100 hover:text-versich-dark-blue max-smaller:text-sm font-medium px-5 lg:px-6 py-2 border border-white/80 rounded-xl transition-colors" onClick={() => navigate(findJobsPath)}>Get Hired</button>
                        </div>
                    </div>
                    <div className="bg-versich-light-blue sm:col-span-2 text-white text-left flex flex-col max-md:px-5 p-6 rounded-2xl relative overflow-hidden">
                        <div style={{ backgroundImage: `url(${mask3})` }} className="background-image bg-cover bg-center bg-no-repeat w-full h-full absolute inset-0"></div>
                        <span className="max-smaller:text-sm relative">For Businesses</span>
                        <div className="flex-grow flex justify-end my-4 relative">
                            <div className="bg-white/30 backdrop-blur-sm inline-flex items-center justify-center w-[80px] h-[80px] rounded-xl">
                                <img className="w-[50px] h-[50px]" src={icon3} alt="" />
                            </div>
                        </div>
                        <h2 className="text-white text-xl smaller:text-2xl lg:text-3xl font-bold mt-4 pb-2 relative">List Your Business</h2>
                        <p className="max-smaller:text-sm smaller:inline-flex flex-col relative">
                            <span>Promote services to increase visibility, attract </span>
                            <span>clients and talents</span>
                        </p>
                        <div className="my-6 relative">
                            <button className="text-white hover:bg-gray-100 hover:text-versich-dark-blue max-smaller:text-sm font-medium px-5 lg:px-6 py-2 border border-white/80 rounded-xl transition-colors" onClick={() => navigate(businessListingPath)}>Get Listed</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserActionsLayout
