
import x from "../assets/social-icons/x_black.png"
import linkedin from "../assets/social-icons/linkedin_black.png"
import instagram from "../assets/social-icons/instagram_black.png"
import tiktok from "../assets/social-icons/tiktok_black.png"
import facebook from "../assets/social-icons/facebook_black.png"

const SocialIconsData = [
    {
        id: 1,
        icon: x,
        url: "https://www.x.com/",
    },
    {
        id: 2,
        icon: linkedin,
        url: "https://www.linkedin.com/company/versimarket?trk=public_post_feed-actor-name",
    },
    {
        id: 3,
        icon: instagram,
        url: "https://www.instagram.com/versimarket/",
    },
    {
        id: 4,
        icon: tiktok,
        url: "https://www.tiktok.com/",
    },
    {
        id: 5,
        icon: facebook,
        url: "https://www.facebook.com/",
    },
]
export default SocialIconsData
