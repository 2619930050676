import { Link } from "react-router-dom"

import { signupPath, freelancerSignupPath, helpCenterPath } from "../../assets/constants"

const ForProfessionals = () => {
    return (
        <div className="text-versich-darktext-color">
            <p className="max-smaller:text-sm xl:text-[18px] font-bold pb-4">For Professionals</p>
            <ul className="flex flex-col gap-y-3 max-smaller:text-sm">
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={signupPath}>How it works</Link>
                </li>
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={freelancerSignupPath}>Join as a Professional</Link>
                </li>
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={helpCenterPath}>Help Center</Link>
                </li>
            </ul>
        </div>
    )
}
export default ForProfessionals
