import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import ServicesList from "../../../assets/ServicesList"
import { servicesPath, newRequestPath, dashboardPath } from "../../../assets/constants"

const BrowseTalentByCategory = () => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    const navigateToServiceRequestForm = (id) => {
        const requestFormPath = newRequestPath + window.encodeURIComponent(id)
        if (!user || (user && user.accountType === "client")) {
            return navigate(requestFormPath)
        }
        navigate(dashboardPath)
    }
    return (
        <div className="py-12 md:py-16 px-8 md:px-16 xl:px-28">
            <div className="max_screen_w">
                <div className="text-center">
                    <h2 className="text-versich-darktext-color text-2xl smaller:text-3xl lg:text-4xl font-bold pb-5">Browse Talent by Service Category</h2>
                    <p className="max-smaller:text-sm xl:text-[18px] md:inline-flex flex-col">
                        <span>Search for opportunities in your field. Browse job categories, apply with </span>
                        <span>ease, and elevate your career.</span>
                    </p>
                </div>
                <div className="grid smaller:grid-cols-2 md:grid-cols-3 gap-x-3.5 md:gap-x-5 gap-y-4 md:gap-y-[22px] text-left my-10 md:my-16">
                    {ServicesList.map(service => (
                        <div className="bg-[#F6F6F6] max-md:px-5 p-6 border border-versich-light-blue/10 hover:shadow-sticker rounded-2xl cursor-pointer group" onClick={() => navigateToServiceRequestForm(service.uid)} key={service.id}>
                            <div className="bg-versich-light-blue inline-flex items-center justify-center w-[45px] h-[45px] rounded-xl mb-10">
                                <img className="w-6 h-6" src={service.outlineIcon} alt="" />
                            </div>
                            <div className="text-versich-darktext-color group-hover:text-versich-blue-hover font-bold transition-colors">{service.name}</div>
                            <p className="text-sm mt-2.5">{service.description || "Transform your brand with eye-catching designs by expert graphic designers!"}</p>
                        </div>
                    ))}
                </div>
                <div>
                    <button className="primary_btn" onClick={() => navigate(servicesPath)}>View Services</button>
                </div>
            </div>
        </div>
    )
}
export default BrowseTalentByCategory
