import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import apiErrorParser from "../utils/apiErrorParser"

export const getPublicRequestsAsync = createAsyncThunk("service/publicRequests", async (query) => {
    try {
        const { data } = await api.getPublicRequests(query)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getPublicRequestAsync = createAsyncThunk("service/getPublicRequest", async ({ id, params }) => {
    try {
        const { data } = await api.getPublicRequest({ refId: id, params })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})