import homeIcon from "../../assets/icons/dashboard/home.svg"
import folderIcon from "../../assets/icons/dashboard/folder.svg"
import chatIcon from "../../assets/icons/dashboard/chat.svg"
import documentIcon from "../../assets/icons/dashboard/document.svg"
import gemsIcon from "../../assets/icons/dashboard/diamond.svg"
import chartIcon from "../../assets/icons/dashboard/chart.svg"
import businessesIcon from "../../assets/icons/dashboard/businesses.svg"
import jobsearchIcon from "../../assets/icons/dashboard/job-search.svg"
import contractIcon from "../../assets/icons/dashboard/contract.svg"
import {
    dashboardPath, messagesPath, requestsPath, privateServicesPath,
    buyVersiGemsPath, businessPricingPath,
    proposalsPath,
    findBusinessesPath,
    findJobsPath,
} from "../../assets/constants"

const Dashboard = {
    "dashboard": {
        "id": 1,
        "name": "Dashboard",
        "url": dashboardPath,
        "image": homeIcon,
    }
}
const Requests = {
    "requests": {
        "id": 2,
        "name": "Requests",
        "url": requestsPath,
        "image": documentIcon,
    },
}
const Messages = {
    "messages": {
        "id": 3,
        "name": "Messages",
        "url": messagesPath,
        "image": chatIcon,
    },
}
const MyServices = {
    "myservices": {
        "id": 4,
        "name": "My Services",
        "url": privateServicesPath,
        "image": folderIcon,
    },
}
const VersiGems = {
    "versigems": {
        "id": 5,
        "name": "VersiGems",
        "url": buyVersiGemsPath,
        "image": gemsIcon,
    },
}
const Pricing = {
    "pricing": {
        "id": 6,
        "name": "Pricing",
        "url": businessPricingPath,
        "image": chartIcon,
    },
}

const Proposals = {
    "proposals": {
        "id": 9,
        "name": "Proposals",
        "url": proposalsPath,
        "image": contractIcon,
    },
}

export const ClientMenu = {
    ...Dashboard,
    ...Requests,
    ...Messages,
}

export const FreelancerMenu = {
    ...Dashboard,
    ...MyServices,
    ...Requests,
    ...Proposals,
    ...Messages,
    ...VersiGems,
}

export const BusinessMenu = {
    ...Dashboard,
    ...Requests,
    ...Proposals,
    ...Messages,
    ...Pricing,
}

// Useful links
export const FindBusinesses = {
    "findbusiness": {
        "id": 7,
        "name": "View Businesses",
        "url": findBusinessesPath,
        "image": businessesIcon,
    },
}

export const FindJobs = {
    "findjobs": {
        "id": 8,
        "name": "Find Jobs",
        "url": findJobsPath,
        "image": jobsearchIcon,
    },
}