import { Link } from "react-router-dom"

import dollarIcon from "../../assets/svgs/dollar.svg"
import peopleIcon from "../../assets/svgs/people2.svg"
import locationIcon from "../../assets/svgs/location2.svg"
import verifiedIcon from "../../assets/svgs/verified.svg"
import ReviewStars from "../Dashboard/business/Reviews/components/ReviewStars"
import { findBusinessesPath } from "../../assets/constants"
import { getServiceBusinessesPath } from "../../assets/path"
import { getInitials } from "../../utils"
import { openDialog } from "../DialogBox"
import { directContactDialog } from "./DirectContactDialog/DirectContactDialog"

const BusinessProfileCard = ({ user, contactBusiness }) => {
    const companyProfilePath = `/business/${user.details.companyId}/`

    const getSearchBusinessesPath = (string) => {
        const term = window.encodeURIComponent(string.toLowerCase())
        return findBusinessesPath.concat(`?search=${term}`)
    }
    return (
        <div className="bg-white grid sm:grid-cols-2 md:grid-cols-5 lg:grid-cols-3 p-3 border border-versich-light-blue/20 rounded-2xl">
            <div className="bg-versich-lighter-blue/[0.03] md:col-span-2 lg:col-span-1 space-y-5 p-4 rounded-xl">
                <Link to={companyProfilePath}>
                    <div className="bg-versich-lighter-blue/10 inline-flex items-center justify-center w-20 h-20 rounded-xl">
                        {user.profile?.image
                            ? <img className="w-full h-full object-cover rounded-xl" src={user.profile.image} alt="" />
                            : <div className="text-versich-light-blue text-2xl md:text-3xl font-semibold flex justify-center items-center w-full h-full shadow-card4 rounded-xl">
                                {getInitials(user.details.companyName)}
                            </div>
                        }
                    </div>
                </Link>
                <div className="text-versich-darktext-color/70 text-xs xl:text-sm font-medium space-y-5 pb-3">
                    <div className="space-y-2">
                        <h3 className="text-versich-darktext-color text-lg smaller:text-xl lg:text-2xl font-bold">
                            <Link className="hover:text-versich-light-blue inline-block transition-colors" to={companyProfilePath}>
                                {user.details.companyName}
                            </Link>
                        </h3>
                        <div className="inline-flex flex-wrap items-center gap-2">
                            <ReviewStars className="gap-1" average={user.reviews.average} />
                            <p>({user.reviews.average} of {user.reviews.total} reviews)</p>
                        </div>
                    </div>
                    <div className="space-y-4">
                        <div className="flex smaller:items-center gap-1.5 md:gap-2">
                            <img className="w-4 xl:w-5 h-4 xl:h-5" src={dollarIcon} alt="budget" />
                            <span>{user.about.minimumProjectSize}</span>
                        </div>
                        <div className="flex smaller:items-center gap-1.5 md:gap-2">
                            <img className="w-4 xl:w-5 h-4 xl:h-5" src={peopleIcon} alt="employees" />
                            <span>{user.about.totalEmployees}</span>
                        </div>
                        {user.location &&
                            <div className="flex smaller:items-center gap-1.5 md:gap-2">
                                <img className="w-4 xl:w-5 h-4 xl:h-5" src={locationIcon} alt="location" />
                                <span>{user.location.city}, {user.location.country}</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="text-sm md:text-base md:col-span-3 lg:col-span-2 space-y-5 max-md:px-5 p-6">
                <div className="sm:text-right">
                    {user.verified &&
                        <div className="bg-glaucous text-white inline-flex items-center justify-center gap-1.5 px-3 py-1.5 rounded-full">
                            <img src={verifiedIcon} alt="verified" />
                            <span>Verified</span>
                        </div>
                    }
                </div>
                <p>{user.about.description}</p>
                <div className="text-xs md:text-sm flex gap-1.5 flex-wrap sm:pt-1.5">
                    {user.about?.services.map(service => (
                        service.uid === "other"
                            ? <Link className="bg-brandeis-blue/[0.08] text-versich-light-blue inline-block py-1.5 px-2.5 rounded-full" to={getSearchBusinessesPath(user.about.otherService)} key={service.uid}>
                                {user.about.otherService}
                            </Link>
                            : <Link className="bg-brandeis-blue/[0.08] text-versich-light-blue inline-block py-1.5 px-2.5 rounded-full" to={getServiceBusinessesPath(service.uid)} key={service.uid}>
                                {service.title}
                            </Link>
                    ))}
                </div>
                <div className="flex flex-wrap gap-3 pt-2.5 sm:pt-3.5 md:pt-5 lg:pt-7">
                    {contactBusiness &&
                        <button className="primary_btn" onClick={(e) => { contactBusiness(); openDialog(e) }} data-parent-id={directContactDialog}>Contact</button>
                    }
                    <Link to={companyProfilePath} className="accent_btn">View Profile</Link>
                    {user.details.website &&
                        <Link target="_blank" to={user.details.website} className="secondary_btn">Visit Website</Link>
                    }
                </div>
            </div>
        </div>
    )
}
export default BusinessProfileCard