import { useEffect } from "react"
import { IoIosArrowDown } from "react-icons/io"

import { MONTHS } from "../../utils/date"

const MonthDropdownFieldInput = ({ label, name, value, placeholder, setMonthValue, required, error }) => {

    // Get months based on currentmonth
    const currentMonth = new Date().getMonth() + 1
    useEffect(() => {
        setMonthValue(currentMonth)
    }, [])

    // Set month 'select' as number
    const handleChange = (e) => {
        setMonthValue(() => {
            let val = window.parseInt(e.target.value)
            if (isNaN(val)) {
                val = currentMonth
            }
            return val
        })
    }
    return (
        <div className="space-y-2 text-start">
            {label && <label htmlFor={name} className="text-versich-dark-blue text-base font-medium flex">{label}{required && <span className="text-red-500 ml-1">*</span>}</label>}
            <div className="relative">
                <select
                    id={name}
                    className={"bg-white text-sm font-medium w-full h-10 py-2.5 px-3 border-[1px] focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-lg appearance-none "
                        + (error ? "border-red-500" : "border-gray-300")
                    }
                    name={name}
                    value={value}
                    onChange={handleChange}
                >
                    <option value="" disabled hidden>{placeholder}</option>
                    {MONTHS.map((month) => (
                        <option className="w-full px-3 pr-8 appearance-none focus:outline-none" value={month.id} key={month.id}>{month.name}</option>
                    ))}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                    <IoIosArrowDown className="w-5 h-5 text-gray-400" />
                </div>
            </div>
            {error &&
                <div className="text-sm text-red-500">{required}</div>
            }
        </div>
    )
}
export default MonthDropdownFieldInput
