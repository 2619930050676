import { useCallback, memo } from "react"
import { Link } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"
import { MdOutlineUpdate } from "react-icons/md"

import Avatar from "../../../../components/Avatars/Avatar"
import { contactProfessionalViaProposalDialog } from "../../../../components/Dashboard/proposals/ContactProfessionalViaProposalDialog"
import { BUSINESS } from "../../../../assets/constants"

const ProposalCard = ({ data, viewProposal, handleMessageClick }) => {
    const { professional, shortContent, uid, conversation, createdAt } = data
    const { details, accountType } = professional
    const userProfilePath = accountType === BUSINESS ? `/business/${details.companyId}/` : `/freelancer/${details.profileId}/`

    // Callback to view the proposal
    const handleViewProposal = useCallback(() => {
        viewProposal(uid)
    }, [uid, viewProposal])

    // Wrap the message handler to capture the click event
    const handleMessageButtonClick = useCallback((e) => {
        handleMessageClick(conversation, uid, e)
    }, [conversation, handleMessageClick, uid])
    return (
        <div className="flex flex-col gap-y-2.5 p-4 sm:p-6 py-6 border border-versich-light-blue/20 rounded-2xl overflow-auto">
            <div className="flex max-sm:flex-col sm:items-center gap-2.5 sm:gap-3.5">
                <Link to={userProfilePath}>
                    <Avatar
                        user={professional}
                        fullName={details.name}
                        ringClass="min-w-12 w-12 h-12 sm401:h-14 sm401:w-14 border border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10"
                        initialClass="text-sm lg:text-base"
                    />
                </Link>
                <div>
                    <h3 className="text-versich-darktext-color text-lg lg:text-xl font-bold mb-1.5">
                        <Link to={userProfilePath} className="hover:text-versich-lighter-blue inline-block transition-colors">
                            {details.name}
                        </Link>
                    </h3>
                    <div className="text-versich-darktext-color/80 text-xs md:text-sm inline-flex items-center gap-1 md:gap-1.5"><MdOutlineUpdate /> Received {formatDistanceToNow(createdAt, { addSuffix: true })}</div>
                </div>
            </div>
            <p className="text-xs sm401:text-sm flex-1 mt-1.5 md:mt-2.5 pt-3.5 md:pt-5 border-t border-versich-light-blue/10">
                {shortContent}
            </p>
            <div className="text-sm flex flex-wrap gap-3 mt-3 sm:mt-5 pt-5 lg:pt-7">
                <button onClick={handleViewProposal} className="accent_btn">
                    View Proposal
                </button>
                <button onClick={handleMessageButtonClick} data-parent-id={contactProfessionalViaProposalDialog} className="secondary_btn">
                    Message
                </button>
            </div>
        </div>
    )
}
export default memo(ProposalCard)