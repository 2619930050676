import { fetchService, fetchSubService } from "./service"

export const fetchPrimaryAndSubService = (serviceId, requestType) => {
    const primaryServiceTitle = fetchService(serviceId)
    const primaryService = primaryServiceTitle ? { title: primaryServiceTitle, uid: serviceId } : null

    const subServiceTitle = fetchSubService(serviceId, requestType)
    const subService = subServiceTitle ? { title: subServiceTitle, uid: requestType } : null
    return {
        primary: primaryService,
        sub: subService
    }
}