import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useState } from "react"

import useClientMessageHandler from "../../../hooks/useClientMessageHandler"
import DialogBox, { closeDialog, getDialog } from "../../DialogBox"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import ConfirmButton from "../../Buttons/ConfirmButton"
import { TOASTTYPE_ERROR, TOASTTYPE_INFO } from "../../../assets/constants"
import { createConversationFromProposalAsync } from "../../../slices/proposal"
import toastify from "../../../utils/toastify"

export const contactProfessionalViaProposalDialog = "contactprofessionalviaproposal-dialog"

const ContactProfessionalViaProposalDialog = ({ proposalId }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    // States
    const [csrfToken, setCsrfToken] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    // Get the client messaging handler
    const clientMessageHandler = useClientMessageHandler(navigate)

    /**
   * Closes the dialog and navigates to the conversation details.
   *
   * @param {HTMLElement} form - The form element (used to get the dialog).
   * @param {string} id - The conversation ID returned from the API.
   */
    const closeDialogAndNavigate = (form, id) => {
        const dialog = getDialog(form);
        if (dialog) {
            closeDialog(null, dialog);
        }
        clientMessageHandler(id)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!message) {
            setError(true)
            return setTimeout(() => {
                setError(false)
                submitBtn.disabled = false
            }, 2000)
        }

        setLoading(true)
        const formData = {
            uid: proposalId,
            content: message,
            token: csrfToken
        }
        await dispatch(createConversationFromProposalAsync(formData))
            .unwrap()
            .then((data) => {
                closeDialogAndNavigate(e.target, data.payload)
                submitBtn.disabled = false
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                if (data.status === TOASTTYPE_ERROR && data.shortInfo === "duplicate-conversation") {
                    closeDialogAndNavigate(e.target, data.payload)
                    return toastify("Already contacted professional!", TOASTTYPE_INFO, submitBtn)
                }
                toastify(data.message, data.status, submitBtn, contactProfessionalViaProposalDialog)
            })
            .finally(() => {
                setLoading(false)
            })
    }
    return (
        <DialogBox
            id={contactProfessionalViaProposalDialog}
            className="max-sm:max-w-full backdrop:bg-oxford-blue/20 sm:!max-w-[920px] !w-11/12 xl:!w-3/4"
            toastStacked={false}
            customCloseEvent={(e) => { setMessage(""); closeDialog(e) }}
        >
            <form className="bg-lighter bg-opacity-20 sm:col-span-2 mx-auto p-4 lg:p-4 !pt-0" onSubmit={handleSubmit} data-parent-id={contactProfessionalViaProposalDialog}>
                <h2 className="text-versich-dark-blue font-semibold text-lg lg:text-xl text-start  flex items-center gap-1 sm:gap-2 lg:gap-3 mb-1.5 sm:mb-3">
                    <span>Tell the professional what you need</span>
                </h2>
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <textarea className={"w-full min-h-60 max-h-[600px] mb-5 px-3 py-2 border outline-none rounded-lg " + (error ? "border-red-500" : "border-versich-border")}
                    name="message" placeholder="Your message"
                    onChange={e => setMessage(e.target.value)} value={message}
                />
                {error && <p className="text-red-500 text-start text-sm mb-1.5">Please enter a message</p>}
                <div className="max-w-fit mx-auto">
                    <ConfirmButton className="bg-versich-blue hover:bg-versich-blue-hover text-sm lg:text-base w-full text-white font-medium px-4 lg:px-5 py-3 rounded-lg" type="submit" loading={loading}>Send Message</ConfirmButton>
                </div>
            </form>
        </DialogBox >
    )
}
export default ContactProfessionalViaProposalDialog