import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import proposalIcon from "../../../assets/images/proposal.png"
import ConfirmationDialog, { confirmationDialog } from "../../ConfirmationDialog"
import FormLoader from "../../loader/FormLoader"
import CSRFTokenField from "../../Inputs/CSRFTokenField"
import FileUploadField from "../../FileUploadField"
import { closeDialog, openDialog } from "../../DialogBox"
import { submitProposalAsync } from "../../../slices/proposal"
import { toProposalDetails } from "../../../assets/path"

const JobProposalForm = ({ refId, viewFullJobPath, freelancerHasRequiredBalance, businessUserHasPremiumPlan }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [csrfToken, setCsrfToken] = useState("")
    const [coverLetter, setCoverLetter] = useState("")
    const [coverLetterError, setCoverLetterError] = useState(false)
    const [attachment, setAttachment] = useState(null)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const [proposalId, setProposalId] = useState("")

    // Pre-fill Form
    const resetForm = () => {
        setCoverLetter("")
        setAttachment(null)
        setErrorMsg("")
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
        if (proposalId) {
            toProposalDetails(proposalId, navigate)
        }
    }

    // Handle form submission for a proposal
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (!coverLetter.trim()) {
            setCoverLetterError(true)
            return setTimeout(() => {
                setCoverLetterError(false)
                submitBtn.disabled = false
            }, 2000)
        }

        const formData = {
            refId: refId,
            coverLetter,
            attachment,
            token: csrfToken
        }
        setLoading(true)
        dispatch(submitProposalAsync(formData))
            .unwrap()
            .then((data) => {
                setProposalId(data.payload)
                openDialog(e)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
            })
    }
    return (<>
        <form onSubmit={handleSubmit} data-parent-id={confirmationDialog}>
            <h2 className="text-lg sm:text-xl lg:text-xl font-semibold pt-2 md:pt-4">Cover Letter <span className="text-red-500 ml-1">*</span></h2>
            <div className="my-3 md:my-6">
                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                <textarea className={"text-sm font-medium w-full min-h-64 max-h-[768px] px-3 md:px-5 py-3.5 border focus-visible:border-versich-blue shadow-input focus:shadow-inputFocus outline-none rounded-2xl "
                    + (coverLetterError ? "border-red-500" : "border-versich-light-blue/20")
                }
                    name="coverLetter"
                    onChange={e => setCoverLetter(e.target.value)} value={coverLetter}
                />
                {coverLetterError && <p className="text-red-500 text-start text-sm mb-1.5">Enter a cover letter highlighting what makes you unique.</p>}
                <div className="mt-2 md:mt-4"></div>
                <FileUploadField name="attachments" label="Attachments" maxSizeInMb={10} file={attachment} setFile={setAttachment} />
            </div>
            {errorMsg && <p className="text-red-500 text-start text-sm pt-2 md:pt-4">{errorMsg}</p>}
            <div className="flex flex-wrap gap-3 py-14 lg:py-16">
                {(freelancerHasRequiredBalance || businessUserHasPremiumPlan)
                    &&
                    <button className="primary_btn" type="submit">Submit Proposal</button>
                }
                <button className="secondary_btn" onClick={() => navigate(viewFullJobPath)} type="button">Cancel</button>
            </div>
        </form>
        <ConfirmationDialog
            title="Proposal Submitted"
            description="Your proposal was sent to the client. We'll update you on their response shortly."
            icon={proposalIcon}
            customCloseEvent={customCloseDialog}
        />
        {loading && <FormLoader />}
    </>)
}
export default JobProposalForm