import { useState, useEffect } from "react"

import MiddleTextSeparator from "../../../../../pages/auth/socialAccounts/MiddleTextSeparator"
import TextFieldInput from "../../../../Services/TextFieldInput"
import YearDropdownFieldInput from "../../../../Services/YearDropdownFieldInput"
import AddPlus from "../../../../svgs/AddPlus"
import { TOASTTIMER } from "../../../../../assets/constants"

const EducationFields = ({ data, submitted, position, minimum, fieldStatus, removeEducation, setEducation }) => {
    const [instituteName, setInstituteName] = useState("")
    const [instituteNameError, setInstituteNameError] = useState(false)
    const [programStartYear, setProgramStartYear] = useState(0)
    const [programStartYearError, setProgramStartYearError] = useState(false)
    const [programEndYear, setProgramEndYear] = useState(0)
    const [programEndYearError, setProgramEndYearError] = useState(false)
    const [irrelaventYearsError, setIrrelaventYearsError] = useState(false)
    const [degree, setDegree] = useState("")
    const [degreeError, setDegreeError] = useState(false)
    const [fieldOfStudy, setFieldOfStudy] = useState("")
    const [fieldOfStudyError, setFieldOfStudyError] = useState(false)

    // Pre-fill fields
    useEffect(() => {
        if (data && data.length >= position) {
            const d = data[position - 1]
            setInstituteName(d.instituteName)
            setProgramStartYear(d.programStart)
            setProgramEndYear(d.programEnd)
            setDegree(d.degree)
            setFieldOfStudy(d.fieldOfStudy)
        }
    }, [data])

    // Track changes in education
    const updateEducation = (add) => {
        setEducation(preEducation => {
            let details
            if (add) {
                details = {
                    ...preEducation, [position]: {
                        instituteName,
                        programStartYear,
                        programEndYear,
                        degree,
                        fieldOfStudy,
                    }
                }
            }
            else {
                let d = Object.entries({ ...preEducation }).filter(([key, value]) => parseInt(key) <= fieldStatus.count)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateEducation(true)
    }, [instituteName, programStartYear, programEndYear, degree, fieldOfStudy])

    // Add/Remove education
    useEffect(() => {
        updateEducation(fieldStatus.added)
    }, [fieldStatus])

    useEffect(() => {
        setInstituteNameError(submitted && instituteName === "")
        setProgramStartYearError(submitted && programStartYear === 0)
        setProgramEndYearError(submitted && programEndYear === 0)
        setDegreeError(submitted && degree === "")
        setFieldOfStudyError(submitted && fieldOfStudy === "")

        // Validate program years
        if (submitted && programEndYear && programStartYear
            && programEndYear < programStartYear
        ) {
            setIrrelaventYearsError(true)
            setTimeout(() => {
                setIrrelaventYearsError(false)
            }, TOASTTIMER)
        }
    }, [submitted])
    return (
        <div className="space-y-5 education">
            {position > 1 &&
                <MiddleTextSeparator word={
                    <p className="w-[128px] mb-2.5">Education #{position}</p>
                } />
            }
            <TextFieldInput
                name={`instituteName_${position}`}
                label="Institute Name"
                onChange={(e) => setInstituteName(e.target.value)}
                defaultValue={instituteName}
                required="Institute name is required"
                error={instituteNameError}
            />
            <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                <YearDropdownFieldInput
                    name={`programStart_${position}`}
                    label="Program Start"
                    value={programStartYear}
                    placeholder="Select start year"
                    setYearValue={setProgramStartYear}
                    required="Program start date is required"
                    error={programStartYearError}
                />
                <YearDropdownFieldInput
                    name={`programEnd_${position}`}
                    label="Program End"
                    value={programEndYear}
                    placeholder="Select end year"
                    setYearValue={setProgramEndYear}
                    required={irrelaventYearsError ? "" : "Program end date is required"}
                    error={irrelaventYearsError || programEndYearError}
                />
            </div>
            {irrelaventYearsError &&
                <div className="text-sm text-red-500 mt-2">Program end year must be after the start year. Update the years accordingly.</div>
            }
            <TextFieldInput
                name={`degree_${position}`}
                label="Degree"
                onChange={(e) => setDegree(e.target.value)}
                defaultValue={degree}
                required="Degree is required"
                error={degreeError}
            />
            <TextFieldInput
                name={`fieldOfStudy_${position}`}
                label="Field of Study"
                onChange={(e) => setFieldOfStudy(e.target.value)}
                defaultValue={fieldOfStudy}
                required="Field of study is required"
                error={fieldOfStudyError}
            />
            <div className={"hover:opacity-80 items-center justify-end gap-x-1.5 group cursor-pointer remove_button" + (
                position > minimum ? " flex" : " hidden"
            )} onClick={removeEducation}>
                <AddPlus className="[&>*]:stroke-red-500 rotate-45 group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform" />
                <span className="text-red-500 text-sm font-medium">Remove Education</span>
            </div>
        </div>
    )
}
export default EducationFields