import { useState, useEffect, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import chevronRightIcon from "../../../assets/svgs/chevron-right.svg"
import planet from "../../../assets/images/planet.png"
import ProposalCard from "../../../components/Dashboard/proposals/ProposalCard"
import { dashboardPath } from "../../../assets/constants"
import { toProposalDetails, toConversationDetails } from "../../../assets/path"
import { userProposalsAsync } from "../../../slices/proposal"

const metadata = {
    title: "Proposals | VersiMarket",
    description: "Proposals page for showing list of proposals sent for requests",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/proposals/`
}

const Proposals = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [proposals, setProposals] = useState([])

    // Load proposals on mount
    useEffect(() => {
        const loadProposals = () => {
            dispatch(userProposalsAsync())
                .unwrap()
                .then(data => {
                    setProposals(data.payload)
                })
                .catch(() => { })
        }
        loadProposals()
    }, [dispatch])

    // Navigation callback for the "Back" button
    const handleBackNavigation = useCallback(() => {
        navigate(dashboardPath)
    }, [navigate])

    // Handler to view proposal details
    const viewProposal = useCallback((uid) => {
        toProposalDetails(uid, navigate)
    }, [navigate])

    // Handler to view conversation details
    const viewMessage = useCallback((id) => {
        toConversationDetails(id, navigate)
    }, [navigate])
    return (
        <DashboardLayout metadata={metadata} activePage="Proposals">
            <div className="flex flex-col gap-y-2.5 h-full">
                <div className="space-y-1.5">
                    <div className="flex justify-start pt-1">
                        <button className="inline-flex items-center gap-0.5" onClick={handleBackNavigation}>
                            <img src={chevronRightIcon} alt="" />
                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                        </button>
                    </div>
                    <div className="text-versich-dark-blue text-start space-y-[2px]">
                        <div className="text-lg lg:text-xl font-semibold">Proposals</div>
                        <div className="text-sm">Manage all your proposals</div>
                    </div>
                </div>
                <div className="bg-white text-start flex-grow py-12 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    {proposals.length > 0 ? (
                        <div className="text-start smaller:px-3 space-y-5">
                            {proposals.map((data, index) => (
                                <ProposalCard
                                    data={data}
                                    viewProposal={viewProposal}
                                    viewMessage={viewMessage}
                                    key={index}
                                />
                            ))}
                        </div>
                    ) : (
                        <div className="h-full flex flex-col items-center justify-center">
                            <div>
                                <img src={planet} alt="planet" />
                            </div>
                            <p className="text-versich-darktext-color text-base lg:text-lg font-semibold text-center">There are no active proposals</p>
                        </div>
                    )}
                </div>
            </div>
        </DashboardLayout>
    )
}
export default Proposals
