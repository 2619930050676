import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import curvyArrowIcon from "../../../assets/svgs/full-curvyarrow-right.svg"
import ServicesList from "../../../assets/ServicesList"
import { privateServicesPath } from "../../../assets/constants"
import { getFreelancerServicesPath } from "../../../assets/path"

const ServiceTile = ({ data, servicePath }) => {

    // Set Icon & Color
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === data.uid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [data])

    const serviceProjectsPath = `${servicePath}:sid/projects/`.replace(":sid", data.uid)
    return (
        service &&
        <Link to={serviceProjectsPath} className={`${service.bgColor} bg-opacity-[0.08] text-start flex max-sm401:flex-col md:max-lg800:flex-col gap-3 smaller:gap-5 py-3 sm:py-4 px-6 sm:px-8 rounded-lg`}>
            <div>
                <img className="min-w-10 h-10 smaller:min-w-[50px] smaller:h-[50px] drop-shadow contrast-150" src={service.icon} alt={data.title} />
            </div>
            <div className="flex-grow text-sm">
                <h3 className="text-versich-dark-blue font-medium">{data.title}</h3>
                <div className="text-versich-label text-sm inline-flex items-center gap-[5px] mt-[5px]">
                    View details <img className="w-5 h-5" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}

const MyServices = ({ data, freelancerId }) => {
    let freelancerServicesPath = privateServicesPath
    if (freelancerId) {
        freelancerServicesPath = getFreelancerServicesPath(freelancerId)
    }
    return (
        data.primaryService &&
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold flex justify-between gap-4 pb-4 border-b border-[#EDF0F5]">
                <span>My Services</span>
                <Link className="text-[#4F4F4F] hover:text-versich-light-blue text-sm font-medium group" to={freelancerServicesPath}>
                    See All
                </Link>
            </div>
            <div className="mt-5 space-y-4">
                <ServiceTile data={data.primaryService} servicePath={freelancerServicesPath} />
                {data.otherServices && data.otherServices.map(service => (
                    <ServiceTile data={service} servicePath={freelancerServicesPath} key={service.uid} />
                ))}
            </div>
        </div>
    )
}
export default MyServices