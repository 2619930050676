import { useSelector, useDispatch } from "react-redux"
import { useEffect } from "react"
import { Link } from "react-router-dom"

import curvyArrowIcon from "../../assets/svgs/full-curvyarrow-right.svg"
import CircularProgressBar from "../CircularProgressBar"
import { profileCompletionScoreAsync } from "../../slices/profileCompletion"
import {
    CLIENT, setupPath, emailVerificationPath,
    completeProfilePath, completeEducationPath, completeExperiencePath, completeLanguagesPath
} from "../../assets/constants"

const CompleteProfile = () => {
    const { user } = useSelector((state) => state.auth)
    const profileCompletionScore = useSelector((state) => state.profileCompletion.score)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(profileCompletionScoreAsync(user.accountType))
    }, [dispatch])

    // Get setup link based on user type & their progress
    let completeProfileLink
    if (user.accountType === CLIENT) {
        completeProfileLink = user.verified ? completeProfilePath : emailVerificationPath
    }
    else {
        completeProfileLink = !user.primaryService || !user.leadsLocation || !user.about || (!user.primaryService.project || (user.otherServices && !user.otherServices.length > 0 && !user.otherServices[0].project))
            ? setupPath
            : user.verified
                ? !user.about.phone
                    ? completeProfilePath
                    : !user.education
                        ? completeEducationPath
                        : !user.experience
                            ? completeExperiencePath
                            : completeLanguagesPath
                : emailVerificationPath
    }
    return (
        profileCompletionScore !== 100 && completeProfileLink &&
        <Link to={completeProfileLink} className="bg-[#F0F2FF] flex flex-col sm401:flex-row-reverse smaller:flex-row smaller:items-center gap-5 gap-y-2.5 py-4 px-4 smaller:px-6 rounded-[3px]">
            <CircularProgressBar progress={profileCompletionScore} />
            <div className="flex-1 flex flex-col smaller:flex-row smaller:items-center gap-5 gap-y-3">
                <div className="text-[#212121] text-left flex-1 space-y-0.5">
                    <p className="text-sm font-bold">Complete your profile</p>
                    <p className="text-xs">Your profile is not completed yet, please click the proceed button to complete it now.</p>
                </div>
                <div to={completeProfileLink} className="text-[#4F4F4F] text-sm inline-flex items-center gap-[5px]">
                    Proceed <img className="w-5 h-5" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}
export default CompleteProfile