import SocialIconsData2 from "../../assets/SocialIconsData2"

const SocialIcons = () => {
    return (
        <div className="flex gap-5 py-3 max-sm:flex-wrap justify-center lg:justify-end h-full items-center flex-1">
            {SocialIconsData2.map((data) => (
                <a
                    className="hover:bg-white p-3 border border-versich-darktext-color/10 hover:border-versich-darktext-color/20 rounded-full cursor-pointer transition-all"
                    href={data.url}
                    target="_blank"
                    rel="noreferrer"
                    key={data.id}
                >
                    <img src={data.icon} alt="icons" className="min-w-6 h-6 transition-transform transform-gpu scale-90 cursor-pointer" />
                </a>
            ))}
        </div>
    )
}
export default SocialIcons
