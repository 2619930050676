import { createContext, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"

export const ServiceRequestContext = createContext()

export const ServiceRequestProvider = ({ cookieKey, children }) => {
    const { user } = useSelector(state => state.auth)
    const [formPosition, setFormPosition] = useState(0)
    const [clientDetails, setClientDetails] = useState(null)
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [titleError, setTitleError] = useState(false)
    const [descError, setDescError] = useState(false)

    // Prefill common data from cookie.
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.clientEmail && setClientDetails({
            email: parsedData.clientEmail,
            name: parsedData.clientName,
        })
        parsedData.title && setTitle(parsedData.title)
        parsedData.description && setDescription(parsedData.description)
    }, [cookieKey])

    // Prefill client details
    useEffect(() => {
        if (!user) {
            return
        }
        setClientDetails({
            email: user.email,
            ...(user.name && { name: user.name }),
        })
    }, [user])

    return (
        <ServiceRequestContext.Provider
            value={{
                formPosition, setFormPosition,
                clientDetails, setClientDetails,
                title, setTitle,
                description, setDescription,
                titleError, setTitleError,
                descError, setDescError,
            }}
        >
            {children}
        </ServiceRequestContext.Provider>
    )
}