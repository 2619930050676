import { useSelector } from "react-redux"
import { useState, useRef, useEffect, useCallback } from "react"
import { Link, useNavigate } from "react-router-dom"
import { CustomScroll } from "react-custom-scroll"

import briefcaseIcon from "../../../../assets/icons/dashboard/briefcase.svg"
import Avatar from "./Avatar"
import Message from "./Message"
import MessageInput from "./MessageInput"
import { BUSINESS, CLIENT, FREELANCER, TOASTTIMER } from "../../../../assets/constants"
import { toRequestDetails, toProposalDetails } from "../../../../assets/path"

const UnlockedDetails = ({ conversation, messages, setMessages }) => {
    const { user } = useSelector(state => state.auth)
    const [typingUsers, setTypingUsers] = useState([])

    const ws = useRef(null)
    const messagesEndRef = useRef(null)
    const typingTimeoutRef = useRef(null)

    useEffect(() => {
        initializeWebSocket()
        return () => {
            if (ws.current) {
                ws.current.close()
            }
        }
    }, [conversation])
    useEffect(() => {
        return () => {
            clearTimeout(typingTimeoutRef.current)
        }
    }, [])

    const initializeWebSocket = () => {
        const wsScheme = window.location.protocol === "https:" ? "wss" : "ws"
        const endpoint = `${wsScheme}://${process.env.REACT_APP_WEBSOCKET_HOST}/api/ws/chat/${conversation.id}/`
        ws.current = new WebSocket(endpoint)
        ws.current.onopen = () => {
            console.log("WebSocket connected")
        }
        ws.current.onmessage = (e) => {
            const data = JSON.parse(e.data)
            if (data.type === "message") {
                setMessages(prevMessages => [...prevMessages, data.message])
                scrollToBottom()
                setTypingUsers([])
            }
            else if (data.type === "typing" && data.participantName !== user.name) {
                setTypingUsers([data.participantName])
                clearTimeout(typingTimeoutRef.current)
                typingTimeoutRef.current = setTimeout(() => {
                    setTypingUsers([])
                }, TOASTTIMER)
            }
        }
        ws.current.onclose = () => {
            console.log("WebSocket disconnected")
        }
    }
    const sendMessage = (content) => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({
                type: "message",
                content: content,
            }))
        }
    }
    const sendTypingIndicator = () => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({
                type: "typing",
            }))
        }
    }

    // Scroll to bottom
    useEffect(() => {
        scrollToBottom()
    }, [messages])
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    // View request
    const navigate = useNavigate()
    const navigateToRequest = (e) => {
        if (e.target.id === "view-proposal-btn") {
            return
        }
        toRequestDetails(conversation.request.refId, navigate)
    }
    const otherUser = conversation.client.accountType === user.accountType ? conversation.participant : conversation.client
    const userProfilePath = otherUser.accountType === BUSINESS
        ? `/business/${otherUser.details.companyId}/`
        : otherUser.accountType === FREELANCER
            ? `/freelancer/${otherUser.details.profileId}/`
            : null

    // Memoized callback to view proposal details
    const viewProposal = useCallback((uid) => {
        toProposalDetails(uid, navigate)
    }, [navigate])
    return (<>
        <div className="flex max-sm:flex-wrap items-center gap-2 sm401:gap-2.5 mb-4 sm401:mb-6">
            {userProfilePath
                ? <Link to={userProfilePath}>
                    <Avatar user={otherUser} />
                </Link>
                : <Avatar user={otherUser} />
            }
            <div className="grid sm:grid-cols-[auto_1fr] items-center gap-x-4 gap-y-0.5">
                <h2 className="text-lg md:text-xl font-semibold">
                    {userProfilePath
                        ? <Link className="text-versich-dark-blue hover:text-versich-lighter-blue inline-block transition-colors" to={userProfilePath}>
                            {otherUser.details.name}
                        </Link>
                        : otherUser.details.name
                    }
                </h2>
                {conversation.request &&
                    <p className="text-versich-darktext-color text-xs inline-flex items-center gap-1.5 cursor-pointer overflow-hidden" onClick={navigateToRequest}>
                        <img className="w-5 h-5" src={briefcaseIcon} alt="" />
                        <span className="w-[85%] truncate">{conversation.request.title}</span>
                    </p>
                }
            </div>
        </div>
        <div className="bg-versich-light-blue/[0.075] w-full h-[1px] mx-auto" />
        <CustomScroll className="flex-grow custom_scroll" keepAtBottom={true}>
            <div className="text-xs sm401:text-sm h-full max-h-[50vh] p-1.5 sm401:p-3">
                {conversation.request &&
                    <div
                        className={"flex my-2 "
                            + (user.accountType === CLIENT ? "justify-end" : "justify-start")
                        }
                        onClick={navigateToRequest}
                    >
                        <div className="bg-white text-versich-dark-blue max-w-xs flex flex-col items-center justify-center gap-1.5 p-2.5 py-3.5 border border-versich-darktext-color/[0.075] hover:border-brandeis-blue/25 shadow-formLight transition-colors rounded-lg cursor-pointer group">
                            <img className="w-10 h-10" src={briefcaseIcon} alt="" />
                            <span className="text-center group-hover:text-versich-lighter-blue transition-colors">{conversation.request.title}</span>
                            {conversation.request.proposalId &&
                                <button
                                    type="button"
                                    id="view-proposal-btn"
                                    className="hover:text-versich-light-blue mt-2 pt-2 hover:underline underline-offset-2 decoration-majorelle-blue/20 transition-colors border-t border-versich-darktext-color/5"
                                    onClick={() => viewProposal(conversation.request.proposalId)}
                                    aria-label="View Proposal"
                                >
                                    View Proposal
                                </button>
                            }
                        </div>
                    </div>
                }
                {messages.map(message => (
                    <Message
                        message={message}
                        userAccountType={user.accountType}
                        key={message.id}
                    />
                ))}
                <div ref={messagesEndRef}></div>
            </div>
        </CustomScroll>
        {typingUsers.length > 0 && (
            <div className="text-versich-lighter-blue text-xs sm401:text-sm pt-2.5">
                {typingUsers.join(", ")} {typingUsers.length > 1 ? "are" : "is"} typing...
            </div>
        )}
        <MessageInput
            onSendMessage={sendMessage}
            onTyping={sendTypingIndicator}
        />
    </>)
}
export default UnlockedDetails