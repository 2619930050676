import { Link } from "react-router-dom"

import { businessListingPath, businessSignupPath } from "../../assets/constants"

const ForBusinesses = () => {
    return (
        <div className="text-versich-darktext-color">
            <p className="max-smaller:text-sm xl:text-[18px] font-bold pb-4">For Businesses</p>
            <ul className="flex flex-col gap-y-3 max-smaller:text-sm">
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={businessListingPath}>List your Business</Link>
                </li>
                {/* <li className="hover:text-versich-blue transition-colors">
                    <Link to="#find-B2B-service">Find B2B Service</Link>
                </li> */}
                <li className="hover:text-versich-blue transition-colors">
                    <Link to={businessSignupPath}>Sign up</Link>
                </li>
            </ul>
        </div>
    )
}
export default ForBusinesses
