import { useNavigate } from "react-router-dom"

import pattern3 from "../../../assets/images/pattern-3.png"
import pattern4 from "../../../assets/images/pattern-4.png"
import { signupPath } from "../../../assets/constants"

const GetStartedBanner = () => {
    const navigate = useNavigate()
    return (
        <div className="py-12 md:py-16 px-8 md:px-16 xl:px-28">
            <div className="bg-versich-light-blue max_screen_w max-md:pt-10 py-16 max-sm:pb-24 max-lg:pb-32 px-4 sm404:px-6 sm:px-10 md:px-20 lg:px-32 xl:px-40 rounded-2xl relative overflow-hidden">
                <img className="w-[280px] absolute left-[-90px] bottom-[-90px] max-md:hidden" src={pattern3} alt="" />
                <img className="w-[180px] sm:w-[240px] absolute smaller:max-sm:right-[-2px] smaller:max-sm:bottom-[-30px] right-[-45px] bottom-[-45px]" src={pattern4} alt="" />
                <div className="text-white text-center relative">
                    <p className="max-smaller:text-sm xl:text-[18px] mb-[35px]">We’re not Just a freelancing platform, VersiMarket combines Freelancing and Business Listings to create a Marketplace for  Professionals, Consultants, Developers, Entrepreneurs, Startups and Businesses to Connect</p>
                    <button className="text-white hover:bg-gray-100 hover:text-versich-dark-blue max-smaller:text-sm font-medium px-5 lg:px-6 py-2 border border-white/80 rounded-xl transition-colors" onClick={() => navigate(signupPath)}>Get Started</button>
                </div>
            </div>
        </div>
    )
}
export default GetStartedBanner
