import {
    requestConfirmationPath, requestDetailsPath,
    privateServicesProjectsPath, addServiceProjectPath,
    privateServicesProjectDetailsPath,
    messageConversationDetailsPath,
    serviceRelatedfreelancerPath,
    freelancerProfilePath, freelancerPublicServicesPath, freelancerPublicServicesProjectsPath,
    findServiceBasedBusinessesPath,
    findServiceBasedJobsPath,
    viewFullJobPath,
    submitProposalPath,
    proposalDetailsPath,
} from "./constants"

/**
 * buildPath
 *
 * Builds a dynamic path by replacing placeholder tokens (e.g., ":id") with actual values.
 *
 * @param {string} template - The URL template string.
 * @param {Object} params - An object mapping token names to their values.
 * @param {Object} [options] - Optional settings.
 * @param {boolean} [options.encode=false] - If true, encode all values using encodeURIComponent.
 * @returns {string} - The final URL.
 */
export const buildPath = (template, params = {}, options = {}) => {
    let path = template
    Object.keys(params).forEach((key) => {
        const value = options.encode ? encodeURIComponent(params[key]) : params[key]
        path = path.replace(`:${key}`, value)
    })
    return path
}

/**
 * navigateToPath
 *
 * Builds a dynamic path (using buildPath), appends any query parameters, and navigates to the new path.
 *
 * @param {function} navigate - The navigation function (e.g., from useNavigate).
 * @param {string} template - The URL template string.
 * @param {Object} [pathParams={}] - An object mapping placeholder tokens to values.
 * @param {Object} [queryParams={}] - An object representing query parameters.
 */
export const navigateToPath = (navigate, template, pathParams = {}, queryParams = {}) => {
    let path = buildPath(template, pathParams)
    if (Object.keys(queryParams).length > 0) {
        const queryString = new URLSearchParams(queryParams).toString()
        path += `?${queryString}`
    }
    navigate(path)
}


// Dynamic path builders

export const getRequestConfirmationPath = (refId, pid, sid) =>
    buildPath(requestConfirmationPath, { id: refId, pid, sid })

export const getRequestDetailsPath = (id) =>
    buildPath(requestDetailsPath, { id })

export const getServiceProjectsPath = (id) =>
    buildPath(privateServicesProjectsPath, { sid: id })

export const getAddServiceProjectPath = (id) =>
    buildPath(addServiceProjectPath, { sid: id })

export const getServiceProjectDetailsPath = (id, pid) =>
    buildPath(privateServicesProjectDetailsPath, { sid: id, pid })

export const getMessageConversationDetailPath = (id) =>
    buildPath(messageConversationDetailsPath, { id })

export const getRelaventExpertsPath = (id) =>
    buildPath(serviceRelatedfreelancerPath, { id })

export const getFreelancerProfilePath = (id) =>
    buildPath(freelancerProfilePath, { uid: id })

export const getFreelancerServicesPath = (id) =>
    buildPath(freelancerPublicServicesPath, { uid: id })

export const getFreelancerServiceProjectsPath = (id, sid) =>
    buildPath(freelancerPublicServicesProjectsPath, { uid: id, sid })

export const getServiceBusinessesPath = (id) =>
    buildPath(findServiceBasedBusinessesPath, { service: id }, { encode: true })

export const getServiceJobsPath = (id) =>
    buildPath(findServiceBasedJobsPath, { service: id }, { encode: true })

export const getFullJobPath = (id) =>
    buildPath(viewFullJobPath, { refId: id })

export const getSubmitProposalPath = (id) =>
    buildPath(submitProposalPath, { refId: id })

export const getProposalDetailsPath = (id) =>
    buildPath(proposalDetailsPath, { id })


// Navigation functions

export const toRequestDetails = (refId, navigate) =>
    navigateToPath(navigate, requestDetailsPath, { id: refId });

export const toConversationDetails = (id, navigate, params) =>
    navigateToPath(navigate, messageConversationDetailsPath, { id }, params);

export const toProposalDetails = (uid, navigate, params) =>
    navigateToPath(navigate, proposalDetailsPath, { id: uid }, params);