import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../api"
import apiErrorParser from "../../utils/apiErrorParser"

export const sendContactMessageAsync = createAsyncThunk("website/sendContactMessage",
    async ({ firstname, lastname, email, phonecode, phonenumber, message, token }) => {
        try {
            const { data } = await api.sendContactMessage({ firstname, lastname, email, phonecode, phonenumber, message, csrfmiddlewaretoken: token })
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })