import { Link } from "react-router-dom"

import hero from "../../../assets/images/hero.jpg"
import { businessSignupPath, findBusinessesPath } from "../../../assets/constants"

const Hero = () => {
    return (
        <div style={{ backgroundImage: `url(${hero})` }} className="background-image bg-cover bg-center bg-no-repeat mt-[-100px]">
            <div className="bg-[#044A8CEB] text-white w-full h-full px-8 md:px-20 lg:px-40 xl:px-60 largeLaptop:px-80 pt-[160px] pb-10">
                <div className="text-4xl lg:text-5xl font-bold max-w-[570px] lg:max-w-[760px] mx-auto">
                    <h1 className="leading-tight">
                        <div className="text-white">List your business on VersiMarket to get more leads</div>
                    </h1>
                </div>
                <div className="text-gray-300 mt-4">Full details on why you should list your business on VersiMarket</div>
                <div className="flex justify-center flex-wrap gap-4 mt-10">
                    <Link className="bg-white text-versich-blue hover:text-versich-light-blue text-xs smallest:text-sm font-bold inline-flex items-center py-2.5 lg:py-3 px-3.5 lg:px-[22px] border-2 border-white rounded-xl transition-colors" to={businessSignupPath}>List Your Business Now</Link>
                    <Link className="bg-versich-lighter-blue text-white text-xs smallest:text-sm font-bold inline-flex items-center py-2.5 lg:py-3 px-3.5 lg:px-[22px] border-2 border-columbia-blue/45 hover:border-columbia-blue rounded-xl transition-colors" to={findBusinessesPath}>View Listed Businesses</Link>
                </div>
            </div>
        </div>
    )
}
export default Hero
