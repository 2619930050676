// useBackNavigation.js
import { useNavigate, useSearchParams } from "react-router-dom"
import { useCallback } from "react"

/**
 * useBackNavigation
 *
 * Returns a function that navigates to a "back" path. If the URL contains the
 * query parameter specified by `queryKey`, its decoded value is used. Otherwise, the defaultPath is used.
 *
 * @param {string} defaultPath - The fallback navigation path.
 * @param {string} [queryKey="source_path"] - The query parameter key to look for.
 * @returns {function} A function to call for back navigation.
 */
const useBackNavigation = (defaultPath, queryKey = "source_path") => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    return useCallback(() => {
        const sourcePath = searchParams.get(queryKey)
        const backPath = sourcePath ? decodeURIComponent(sourcePath) : defaultPath
        navigate(backPath)
    }, [searchParams, queryKey, defaultPath, navigate])
}

export default useBackNavigation
