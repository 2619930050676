import { useState, useEffect } from "react"

import AddPlus from "../../../../svgs/AddPlus"
import LanguageFields from "./LanguageFields"

const AddLanguage = ({ maximum = 1, minimum = 1, submitted, languages, setLanguages }) => {
    const [languageCount, setLanguageCount] = useState(minimum)
    const [fieldStatus, setFieldStatus] = useState({ added: true, count: minimum })

    useEffect(() => {
        if (languages && languages.length > 0) {
            setFieldStatus({ added: true, count: languages.length })
            setLanguageCount(languages.length)
        }
    }, [languages])

    const addMoreLanguage = (e) => {
        const button = e.currentTarget
        button.disabled = true

        setTimeout(() => {
            const updatedCount = languageCount + 1
            setFieldStatus({ added: true, count: updatedCount })
            setLanguageCount(updatedCount)
            button.disabled = false
        }, 300)
    }
    const removeLanguage = () => {
        const updatedCount = languageCount - 1
        setFieldStatus({ added: false, count: updatedCount })
        setLanguageCount(languageCount - 1)
    }
    return (
        <div id="add-languages" className="space-y-7 pt-8">
            {[...Array(languageCount)].map((_, index) => (
                <LanguageFields
                    data={languages}
                    submitted={submitted}
                    position={index + 1}
                    minimum={minimum}
                    fieldStatus={fieldStatus}
                    removeLanguage={removeLanguage}
                    setLanguages={setLanguages}
                    key={index}
                />
            ))}
            {maximum >= languageCount &&
                <button
                    className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group"
                    type="button"
                    onClick={addMoreLanguage}
                >
                    <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                    <span className="text-sm">Add Language</span>
                </button>
            }
        </div>
    )
}
export default AddLanguage