import { useSelector } from "react-redux"
import { useParams, useLocation, Navigate, Outlet } from "react-router-dom"

import ErrorPage from "../ErrorPage/ErrorPage"
import {
    businessPricingPath, buyVersiGemsPath, checkoutPaymentsSuccessPath,
    emailTokenVerificationPath, emailVerificationPath, profilePictureSetupPath, setupPath,
    loginPath, freelancerPaths, businessPaths, businessSetupPaths,
    FREELANCER, BUSINESS,
    COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION,
} from "../../assets/constants"

const AuthRoutes = () => {
    const { isAuthenticated, user } = useSelector(state => state.auth)
    const { token } = useParams()
    const location = useLocation()
    const path = window.encodeURIComponent(location.pathname + location.search)

    // Remove subscription redirect path
    if ([businessPricingPath, buyVersiGemsPath, checkoutPaymentsSuccessPath].includes(location.pathname) === false) {
        window.sessionStorage.removeItem(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION)
    }

    if (isAuthenticated && user !== null) {
        var initialPath = null
        const isBusiness = user.accountType === BUSINESS
        const isFreelancer = user.accountType === FREELANCER

        // Check email token verification path
        const isEmailTokenVerifyPath = location.pathname.replace(token, ":token") === emailTokenVerificationPath
        if (isBusiness && !isEmailTokenVerifyPath && !user.verified) {
            initialPath = emailVerificationPath
        }
        else if (isBusiness && !isEmailTokenVerifyPath
            && (
                !user.business || !user.business.details || !user.business.details.completed ||
                !user.business.personalContact || !user.business.personalContact.completed ||
                !user.business.about ||
                !user.business.location || !user.business.location.completed ||
                !user.business.socialProfiles || !user.business.socialProfiles.completed
            )
            && businessSetupPaths.includes(location.pathname) === false) {
            initialPath = businessPaths.root
        }
        else if (isFreelancer && location.pathname !== profilePictureSetupPath && !(user.profile?.image || user.social?.image)) {
            initialPath = profilePictureSetupPath
        }
        else if (location.pathname === setupPath) {
            if (isFreelancer) {
                initialPath = freelancerPaths.root
            }
            else return <ErrorPage status={404} />
        }
        if (initialPath && location.pathname !== initialPath) {
            return <Navigate to={initialPath} replace />
        }
        return <Outlet />
    }
    else {
        return <Navigate to={`${loginPath}?next=${path}`} />
    }
}
export default AuthRoutes