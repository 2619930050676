import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../../api"
import apiErrorParser from "../../../../utils/apiErrorParser"

export const allChoiceOfToolAsync = createAsyncThunk("service/FT/enterprise/allChoiceOfTool", async (params) => {
    try {
        const { data } = await api.EnterpriseChoiceOfTools_FT(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("service/FT/enterprise/createRequest",
    async ({
        businessSize, tools, projectDuration, otherProjectDuration, paymentType, currency, budget, otherBudget,
        clientEmail, clientName, title, description, token
    }) => {
        try {
            const reqData = {
                requestType: "finance-transformation--enterprise",
                businessSizeId: businessSize,
                toolsIds: tools,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                projectDurationId: projectDuration,
                otherProjectDuration,
                clientEmail,
                clientName,
                title,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = await api.createRequest(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })