import { memo } from "react"
import { IoWarningOutline } from "react-icons/io5"

import PremiumButton from "../../Buttons/PremiumButton"

const BusinessProposalStatus = memo(({ upgradeToPremium }) => {
    return (
        <div>
            <div className="bg-versich-lighter-blue/[0.03] space-y-2 p-4 sm:p-6 lg:p-8 rounded-xl">
                <p className="text-versich-darktext-color opacity-70 text-xs inline-flex items-center gap-1">
                    <IoWarningOutline className="min-w-5 w-6 h-6" />
                    <span>Premium plan required to submit proposal</span>
                </p>
                <div>
                    <PremiumButton label="Upgrade" onClick={upgradeToPremium} />
                </div>
            </div>
        </div>
    )
})
export default BusinessProposalStatus