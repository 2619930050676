import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../ServiceRequestLayout"
import WebsiteRequirement from "./components/WebsiteRequirement"
import WebsiteNeed from "./components/WebsiteNeed"
import BusinessType from "../components/BusinessType"
import IndustryType from "../components/IndustryType"
import ProjectDuration from "../components/ProjectDuration"
import Budget from "../components/Budget"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import TitleAndDescription from "../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_WDAD } from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/webDesignAndDevelopment"

const metadata = {
    title: "New Request - Web Design and Development Service",
    description: "Web design and development new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/web-design-and-development/`
}

const WebDesignAndDevelopment = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [requirement, setRequirement] = useState("")
    const [need, setNeed] = useState("")
    const [otherNeed, setOtherNeed] = useState(null)
    const [businessType, setBusinessType] = useState("")
    const [otherBusinessType, setOtherBusinessType] = useState(null)
    const [industryType, setIndustryType] = useState("")
    const [otherIndustryType, setOtherIndustryType] = useState(null)
    const [projectDuration, setProjectDuration] = useState("")
    const [otherProjectDuration, setOtherProjectDuration] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.requirement && setRequirement(parsedData.requirement)
        parsedData.need && setNeed(parsedData.need)
        parsedData.otherNeed && setOtherNeed(parsedData.otherNeed)
        parsedData.businessType && setBusinessType(parsedData.businessType)
        parsedData.otherBusinessType && setOtherBusinessType(parsedData.otherBusinessType)
        parsedData.industryType && setIndustryType(parsedData.industryType)
        parsedData.otherIndustryType && setOtherIndustryType(parsedData.otherIndustryType)
        parsedData.projectDuration && setProjectDuration(parsedData.projectDuration)
        parsedData.otherProjectDuration && setOtherProjectDuration(parsedData.otherProjectDuration)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
    }, [])

    // Generate request data
    const formData = {
        requirement,
        need,
        ...(otherNeed && { otherNeed }),
        businessType,
        ...(otherBusinessType && { otherBusinessType }),
        industryType,
        ...(otherIndustryType && { otherIndustryType }),
        projectDuration,
        ...(otherProjectDuration && { otherProjectDuration }),
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        hiringDecision,
        ...(otherHiringDecision && { otherHiringDecision }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 9
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!requirement ? (
                <WebsiteRequirement
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    defaultValue={searchParams.get("requirement")}
                    setRequirement={setRequirement}
                    goBack={() => navigate(newRequestPath)}
                />
            ) : !need ? (
                <WebsiteNeed
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    defaultValue={searchParams.get("need")}
                    setNeed={setNeed}
                    setOtherNeed={setOtherNeed}
                    goBack={() => setRequirement("")}
                />
            ) : !businessType ? (
                <BusinessType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    setBusinessType={setBusinessType}
                    setOtherBusinessType={setOtherBusinessType}
                    goBack={() => setNeed("")}
                />
            ) : !industryType ? (
                <IndustryType
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    setIndustryType={setIndustryType}
                    setOtherIndustryType={setOtherIndustryType}
                    goBack={() => setBusinessType("")}
                />
            ) : !projectDuration ? (
                <ProjectDuration
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    setProjectDuration={setProjectDuration}
                    setOtherProjectDuration={setOtherProjectDuration}
                    goBack={() => setIndustryType("")}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(6)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setProjectDuration("")}
                />
            ) : !hiringDecision ? (
                <HiringDecision
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(7)}
                    setHiringDecision={setHiringDecision}
                    setOtherHiringDecision={setOtherHiringDecision}
                    goBack={() => setBudget("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(8)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setHiringDecision("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(9)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setHiringDecision("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const WebDesignAndDevelopmentLayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_WDAD
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <WebDesignAndDevelopment cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default WebDesignAndDevelopmentLayout


/*
: <div className="max_screen_w w-full flex justify-between">
    <button className="bg-slate-100 hover:bg-slate-200 text-gray-900 border border-versich-border/20 rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="button" onClick={() => setDescription("")}>Back</button>
    <button className="bg-versich-blue hover:bg-versich-blue-hover text-white rounded-md py-2 lg:py-2.5 px-4 lg:px-6" type="submit">Submit</button>
</div>
*/