import { useSelector, useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useEffect, useState } from "react"

import Meta from "../../components/Meta"
import requestCreatedIcon from "../../assets/images/request-created.png"
import ConfirmationDialog, { confirmationDialog } from "../../components/ConfirmationDialog"
import FormLoader from "../../components/loader/FormLoader"
import CSRFTokenField from "../../components/Inputs/CSRFTokenField"
import InputText from "../../components/Inputs/InputTextField"
import DropdownField from "../../components/Inputs/DropdownField"
import TextAreaField from "../../components/Inputs/TextAreaField"
import FileUploadField from "../../components/FileUploadField"
import SectionLoader from "../../components/loader/SectionLoader"
import toastify from "../../utils/toastify"
import { ACCOUNT_TYPES, TOASTTYPE_ERROR } from "../../assets/constants"
import { titleCase } from "../../utils"
import { closeDialog, openDialog } from "../../components/DialogBox"
import { getHumanCalculationsAsync } from "../../slices/website/humanCalculations"
import { globalSubmitRequestAsync } from "../../slices/website/request"

const metadata = {
    title: "Help Center | Support & Solutions",
    description: "Access our Help Center for FAQs, troubleshooting guides, and support options to resolve your issues quickly.",
    url: `${process.env.REACT_APP_CLIENT_URL}/help-center/`
}

const accountTypes = ACCOUNT_TYPES.map(type => {
    return {
        title: titleCase(type),
        uid: type
    }
})

const defaultVerifyText = {
    x: 0,
    y: 0,
    total: 0,
    input: 0
}

const HelpCenter = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()

    const [csrfToken, setCsrfToken] = useState("")
    const [email, setEmail] = useState("")
    const [accountType, setAccountType] = useState("")
    const [description, setDescription] = useState("")
    const [attachment, setAttachment] = useState(null)
    const [verifyText, setVerifyText] = useState(defaultVerifyText)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setEmail("")
        setAccountType("")
        setDescription("")
        setAttachment(null)
        setVerifyText(defaultVerifyText)
    }

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    // Set default 'accounttype'
    useEffect(() => {
        user?.accountType && setAccountType(user.accountType)
    }, [user])

    // Set human verification
    const getHumanVerification = () => {
        dispatch(getHumanCalculationsAsync())
            .unwrap()
            .then(data => {
                setVerifyText({
                    ...defaultVerifyText,
                    ...data.payload
                })
            })
            .catch(() => setVerifyText(defaultVerifyText))
    }
    useEffect(() => {
        (!verifyText || verifyText.total === 0) && getHumanVerification()
    }, [verifyText])

    // Set verification 'input' as number
    const handleVerifyTextChange = (e) => {
        setVerifyText(prev => {
            let val = window.parseInt(e.target.value)
            if (isNaN(val)) {
                val = 0
            }
            return {
                ...prev, input: val
            }
        })
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                token: csrfToken,
                email: data.email,
                accounttype: data.accountType,
                description: data.description,
                attachment: attachment,
                verifytext: JSON.stringify(verifyText),
            }
            setLoading(true)
            await dispatch(globalSubmitRequestAsync(formData))
                .unwrap()
                .then(() => openDialog(event))
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    setVerifyText(defaultVerifyText)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div>
            <Meta data={metadata} />
            <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 sm:py-12 rounded-md shadow-formLight">
                    <div className="space-y-5 pb-6 sm:pb-10 border-b border-[#EFEEEE99]">
                        <h1 className="text-versich-darktext-color text-2xl sm:text-3xl font-bold flex justify-center">Submit a Request</h1>
                        <p className="text-xs sm:text-sm font-medium">Submit a request. We will respond as soon as possible.</p>
                    </div>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)} className="mt-8 sm:mt-12 space-y-5" data-parent-id={confirmationDialog}>
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            <div className="grid grid-cols-1 gap-x-5 gap-6 md:grid-cols-2">
                                <InputText
                                    name="email"
                                    inputType="email"
                                    label="Email Address"
                                    placeholder="Enter your email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    defaultValue={email}
                                    rules={{ required: "Email is required" }}
                                />
                                <DropdownField
                                    name="accountType"
                                    label="User Type"
                                    options={accountTypes}
                                    value={accountType}
                                    placeholder="Select"
                                    rules={{ required: "User type is required" }}
                                    onChange={(e) => setAccountType(e.target.value)}
                                />
                            </div>
                            <TextAreaField
                                name="description"
                                label="Description"
                                onChange={(e) => setDescription(e.target.value)}
                                defaultValue={description}
                                rules={{ required: "Description is required" }}
                                height="min-h-60 max-h-[528px]"
                            />
                            <FileUploadField name="attachments" label="Attachments" maxSizeInMb={10} file={attachment} setFile={setAttachment} />
                            {verifyText.total
                                ? <InputText
                                    name="verification"
                                    inputType="text"
                                    label={`Verify you are human: What is ${verifyText.x}+${verifyText.y}?`}
                                    onChange={handleVerifyTextChange}
                                    defaultValue={verifyText.input || ""}
                                    rules={{ required: "Fill the answer to verify you're human" }}
                                />
                                : <SectionLoader className="h-20" />
                            }
                            {errorMsg && <div className="text-sm text-red-500 text-left !mt-6">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full py-6">
                                <button className="primary_btn md:py-3 !px-6 lg:!px-10" type="submit" disabled={!verifyText.total}>Submit</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
            </div>
            <ConfirmationDialog
                title="Request Submitted"
                description="Thank you for reaching out. We will respond as soon as possible."
                icon={requestCreatedIcon}
                customCloseEvent={customCloseDialog}
            />
            {loading && <FormLoader />}
        </div>
    )
}
export default HelpCenter
