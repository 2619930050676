import { useFormContext } from "react-hook-form"
import { useEffect } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

export const validateNumber = (phoneNumber) => {
    if (!phoneNumber.trim()) {
        return false
    }
    const phoneNumberPattern = /^\+?\d{1,}$/
    return phoneNumberPattern.test(phoneNumber)
}

const PhoneNumberField = ({ name, rules, setPhoneCode, setPhoneNumber, setPhoneNumberValid, value = "", readOnly }) => {
    const { register, formState, setValue, clearErrors } = useFormContext()
    const { errors } = formState

    useEffect(() => {
        setValue(name, value)
    }, [value, name])

    // Remove errors on value change
    useEffect(() => {
        clearErrors(name)
    }, [value])

    const handleChange = (phone, country) => {
        setValue(name, phone)

        // Visible values
        setPhoneCode(country.dialCode)
        setPhoneNumber(phone.replace(country.dialCode, ""))
        setPhoneNumberValid(validateNumber(phone))
    }
    return (
        <div className="text-start">
            <div className="flex flex-col gap-y-2 text-start">
                <label htmlFor={name} className="text-charcoal text-sm">Phone Number{rules?.required && <span className="text-red-500 ml-1">*</span>}</label>
                <div className="w-full">
                    <PhoneInput
                        name={name}
                        {...register(name, rules)}
                        country={"gb"}
                        onChange={handleChange}
                        value={value}
                        inputProps={{
                            id: name,
                            required: true,
                            className: "bg-white text-sm font-normal w-full h-10 pl-14 py-2.5 px-3 border-[1px] shadow-input outline-none rounded-lg "
                                + (errors[name] ? "border-red-500 " : "border-gray-300 ")
                                + (readOnly ? "text-versich-light-gray cursor-not-allowed" : "focus-visible:border-versich-blue focus:shadow-inputFocus"),
                        }}
                        disabled={readOnly}
                        containerClass="relative flex items-center justify-start"
                        buttonClass={"!bg-[#FBFBFF] w-12 text-left m-[1px] px-3 py-2.5 !border-l-0 !border-t-0 !border-b-0 !rounded-l-lg [&>*]:!rounded-l-lg "
                            + (readOnly ? "!cursor-not-allowed [&>[role=button]]:cursor-not-allowed" : "")
                        }
                        dropdownClass="border border-versich-blue/20 !shadow-card2 !rounded-lg"
                        preferredCountries={["gb", "us"]}
                    />
                </div>
                {errors[name] &&
                    <div className="text-sm text-red-500 text-left">
                        {errors[name].message}
                    </div>
                }
            </div>
        </div>
    )
}
export default PhoneNumberField
