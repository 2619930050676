import { useSelector } from "react-redux"

import Meta from "../Meta"
import SideNavbar from "../Navbar/DashSideNavbar"
import Header from "./Header/Header"
import {
    BUSINESS, CLIENT,
    SS_VERIFICATION_STATUS
} from "../../assets/constants"
import {
    BusinessMenu, FreelancerMenu, ClientMenu,
    FindBusinesses, FindJobs
} from "./constants"

const metaData = {
    title: "Dashboard",
    description: "VersiMarket user dashboard",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/`
}

const DashboardLayout = ({ children, metadata = metaData, activePage }) => {
    const { user } = useSelector(state => state.auth)

    let navMenu, usefullLinks = null
    if (user.accountType === CLIENT) {
        navMenu = ClientMenu
        usefullLinks = { ...FindBusinesses }
    }
    else if (user.accountType === BUSINESS) {
        navMenu = BusinessMenu
        usefullLinks = { ...FindJobs, ...FindBusinesses }
    }
    else {
        navMenu = FreelancerMenu
        usefullLinks = { ...FindJobs }
    }

    // Clean session cookie
    window.sessionStorage.removeItem(SS_VERIFICATION_STATUS.key)
    return (
        <div className="bg-[#FBFBFF] flex">
            <Meta data={metadata} />
            <SideNavbar navlinks={navMenu} activePage={activePage} usefullLinks={usefullLinks} />
            <div className="w-full h-svh flex flex-col">
                <Header usefullLinks={usefullLinks} />
                <div className="flex-grow w-full h-full pt-2.5 px-[14px] pb-5 space-y-2.5 overflow-y-auto">
                    {children}
                </div>
            </div>
        </div>
    )
}
export default DashboardLayout