import { useState, useEffect } from "react"

import AddPlus from "../../../../svgs/AddPlus"
import EducationFields from "./EducationFields"

const AddEducation = ({ maximum = 1, minimum = 1, submitted, education, setEducation }) => {
    const [educationCount, setEducationCount] = useState(minimum)
    const [fieldStatus, setFieldStatus] = useState({ added: true, count: minimum })

    useEffect(() => {
        if (education && education.length > 0) {
            setFieldStatus({ added: true, count: education.length })
            setEducationCount(education.length)
        }
    }, [education])

    const addMoreEducation = (e) => {
        const button = e.currentTarget
        button.disabled = true

        setTimeout(() => {
            const updatedCount = educationCount + 1
            setFieldStatus({ added: true, count: updatedCount })
            setEducationCount(updatedCount)
            button.disabled = false
        }, 300)
    }
    const removeEducation = () => {
        const updatedCount = educationCount - 1
        setFieldStatus({ added: false, count: updatedCount })
        setEducationCount(educationCount - 1)
    }
    return (
        <div id="add-education" className="space-y-7 pt-8">
            {[...Array(educationCount)].map((_, index) => (
                <EducationFields
                    data={education}
                    submitted={submitted}
                    position={index + 1}
                    minimum={minimum}
                    fieldStatus={fieldStatus}
                    removeEducation={removeEducation}
                    setEducation={setEducation}
                    key={index}
                />
            ))}
            {maximum >= educationCount &&
                <button
                    className="text-versich-blue hover:text-versich-blue-hover flex items-center gap-1 disabled:cursor-not-allowed disabled:opacity-80 group"
                    type="button"
                    onClick={addMoreEducation}
                >
                    <AddPlus className="[&>*]:stroke-versich-blue group-hover:[&>*]:stroke-versich-blue-hover smaller:max-lg:w-[18px] smaller:max-lg:h-[18px] w-6 h-6" />
                    <span className="text-sm">Add Education</span>
                </button>
            }
        </div>
    )
}
export default AddEducation