import { useCallback } from "react"
import { useLocation } from "react-router-dom"

import { toConversationDetails } from "../assets/path"
import { openDialog } from "../components/DialogBox"

/**
 * useClientMessageHandler
 *
 * Returns a memoized callback function that handles messaging for a client.
 * It accepts a conversation ID and an event.
 *
 * - If a conversation ID is provided, navigates to its conversation details,
 *   appending the current URL (encoded) as the "source_path" parameter.
 * - Otherwise, it calls openDialog with the event.
 *
 * @param {function} navigate - React Router's navigation function.
 * @returns {function} A callback: (conversation: string|null, event: any) => void
 */
const useClientMessageHandler = (navigate) => {
    const { pathname, search } = useLocation()

    return useCallback((conversation, e) => {
        if (conversation) {
            const fullPath = `${pathname}${search}`
            const encodedPath = window.encodeURIComponent(fullPath)
            toConversationDetails(conversation, navigate, { source_path: encodedPath })
        } else {
            openDialog(e)
        }
    }, [navigate, pathname, search])
}

export default useClientMessageHandler
