import { useNavigate } from "react-router-dom"

import checkIcon from "../../../assets/svgs/badge-check.png"
import pattern2 from "../../../assets/images/pattern-2.png"
import { businessListingPath, findBusinessesPath } from "../../../assets/constants"

const BusinessListings = () => {
    const navigate = useNavigate()
    return (
        <div className="py-12 md:py-16 px-8 md:px-16 xl:px-28">
            <div style={{ backgroundImage: `url("${pattern2}")` }} className="background-image bg-cover bg-center bg-no-repeat max_screen_w max-sm404:px-4 max-md:px-10 p-12 rounded-2xl relative overflow-hidden">
                <div className="bg-[#6473B6]/20 w-full h-full absolute inset-0"></div>
                <div className="text-center relative">
                    <h2 className="text-versich-darktext-color text-2xl smaller:text-3xl lg:text-4xl font-bold pb-3">Business Listings</h2>
                    <p className="max-smaller:text-sm xl:text-[18px] md:inline-flex flex-col">VersiMarket Business Hub for B2B to get matched and connected</p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-x-8 gap-y-12 text-left mt-6 md:mt-8 relative">
                    <div>
                        <h3 className="text-versich-light-blue text-xl smaller:text-2xl lg:text-3xl font-bold pb-6">List your business</h3>
                        <div className="max-smaller:text-sm xl:text-[18px] space-y-2 mb-[18px]">
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Increase Brand Visibility and Online Presence</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Get Both B2B and B2C Clients</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Showcase Your Work and Services to a Larger Audience</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Hire Top Freelance Talents to Get Your Projects Done</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Edge Over Competitors with Our Third-Party Marketing Platforms</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Build Business Reputation by Receiving Ratings and Reviews</span>
                            </div>
                        </div>
                        <div>
                            <button className="primary_btn" onClick={() => navigate(businessListingPath)}>Get Listed</button>
                        </div>
                    </div>
                    <div>
                        <h3 className="text-versich-light-blue text-xl smaller:text-2xl lg:text-3xl font-bold pb-6">B2B Services</h3>
                        <div className="max-smaller:text-sm xl:text-[18px] space-y-2 mb-[18px]">
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Does your business have a challenge?</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Do you need business services?</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Are you looking for specialized businesses to get your job done?</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Speed up your search for business services share your project.</span>
                            </div>
                            <div className="flex items-center gap-2.5">
                                <img className="min-w-5" src={checkIcon} alt="" />
                                <span>Get connected with best-fit providers</span>
                            </div>
                        </div>
                        <div>
                            <button className="primary_btn" onClick={() => navigate(findBusinessesPath)}>View All Businesses</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BusinessListings
