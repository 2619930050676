import { useSelector, useDispatch } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { useState } from "react"

import Meta from "../../../components/Meta"
import BrandBar from "../../../components/Navbar/BrandBar"
import FormLoader from "../../../components/loader/FormLoader"
import AddExperience from "../../../components/Dashboard/accountSettings/freelancer/experience/AddExperience"
import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import toastify from "../../../utils/toastify"
import {
    TOASTTIMER, TOASTTYPE_ERROR,
    completeLanguagesPath, dashboardPath
} from "../../../assets/constants"
import { freelancerExperienceSetAsync } from "../../../slices/freelancer/experience"

const metadata = {
    title: "Complete Profile Details",
    description: "Complete user profile details to become a verified user",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/complete/experience/`
}

const FreelancerExperienceForm = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [experience, setExperience] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setSubmitted(true)
        const experienceKeys = Object.keys(experience)
        const emptyDetails = experienceKeys.filter(k =>
            experience[k].title === "" ||
            experience[k].company === "" ||
            experience[k].startMonth === 0 ||
            experience[k].startYear === 0 ||
            (!experience[k].present
                && (
                    experience[k].endMonth === 0 ||
                    experience[k].endYear === 0 ||
                    experience[k].endYear < experience[k].startYear ||
                    (experience[k].endYear === experience[k].startYear && experience[k].endMonth < experience[k].startMonth)
                )
            )
        )
        if (emptyDetails.length > 0) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }
        setLoading(true)
        const formData = {
            experience: experienceKeys.map(k => {
                return {
                    title: experience[k].title,
                    company: experience[k].company,
                    start_month: experience[k].startMonth,
                    start_year: experience[k].startYear,
                    end_month: experience[k].endMonth,
                    end_year: experience[k].endYear,
                    present: experience[k].present,
                }
            }),
            token: csrfToken
        }
        dispatch(freelancerExperienceSetAsync(formData))
            .unwrap()
            .then(() => navigate(completeLanguagesPath))
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }
    return (
        !user.experience
            ? <div>
                <Meta data={metadata} />
                <BrandBar />
                <div className="bg-white mb-12 py-10 md:py-14 px-3 sm:px-8 md:px-16 lg:px-32 xl:px-52">
                    <div className="w-full max-w-[1080px] mx-auto px-4 smaller:px-12 xl:px-20 py-8 rounded-md shadow-formLight">
                        <div className="space-y-5 pb-6 border-b border-[#EFEEEE99]">
                            <h1 className="text-versich-dark-blue text-xl sm:text-2xl font-medium flex justify-center">Employment History</h1>
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-5">
                            <AddExperience maximum={4} minimum={1} submitted={submitted} experience={user.experience} setExperience={setExperience} />
                            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                            {errorMsg && <div className="text-sm text-red-500 text-left !mt-10">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full flex justify-end gap-5 py-6">
                                <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="button" onClick={() => navigate(dashboardPath)}>Cancel</button>
                                <button className="bg-versich-blue hover:bg-versich-blue-hover text-white py-2 lg:py-3 px-6 lg:px-10 rounded-lg" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </div>
                {loading && <FormLoader />}
            </div>
            : <Navigate to={completeLanguagesPath} replace />
    )
}
export default FreelancerExperienceForm
