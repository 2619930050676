import { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Navbar from "../../components/Navbar/Navbar"
import Meta from "../../components/Meta"
import internetIcon from "../../assets/svgs/internet.svg"
import About from "../../components/Dashboard/business/About"
import OtherDetails from "../../components/Dashboard/business/OtherDetails"
import Location from "../../components/Dashboard/business/Location"
import SocialMedia from "../../components/Dashboard/business/SocialMedia"
import Reviews from "../../components/Dashboard/business/Reviews/Reviews"
import { getPublicBusinessProfileAsync } from "../../slices/business/public"
import { getInitials } from "../../utils"

const BusinessProfile = () => {
    const { id } = useParams()
    const [user, setUser] = useState(undefined)
    const [companyName, setCompanyName] = useState("")
    const [metadata, setMetadata] = useState()

    const dispatch = useDispatch()
    const fetchBusinessProfile = async (id) => {
        await dispatch(getPublicBusinessProfileAsync(id))
            .unwrap()
            .then((data) => {
                setUser(data.payload)
                const companyName = data.payload.details.data.companyName
                setCompanyName(companyName)
                setMetadata({
                    title: companyName + " - Business Profile",
                    description: `Public business profile of ${companyName}`,
                    url: `${process.env.REACT_APP_CLIENT_URL}/business/${id}/`
                })
            })
            .catch(() => setUser(null))
    }
    useEffect(() => {
        setMetadata({
            title: "Business Profile",
            description: "Public business profile",
            url: `${process.env.REACT_APP_CLIENT_URL}/business/${id}/`
        })
        fetchBusinessProfile(id)
    }, [id])
    return (
        user !== null
            ? <>
                <Navbar />
                {user === undefined
                    ? <div className="w-full h-80 flex justify-center items-center">
                        <BeatLoader loading={true} color="#1D88ED" size={10} />
                    </div>
                    : <>
                        {metadata && <Meta data={metadata} />}
                        <div className="bg-[#F5F7FF] py-8 px-8 md:px-16 lg:px-28">
                            <div className="max_screen_w">
                                <div className="flex max-sm:flex-col flex-wrap items-center gap-2.5">
                                    <div>
                                        {/* Avatar */}
                                        <div className="bg-white w-[135px] h-[135px] smallest:w-[150px] smallest:h-[150px] sm:w-[160px] sm:h-[160px] rounded-xl">
                                            {user.profile?.image
                                                ? <img className="w-full h-full object-cover rounded-xl" src={user.profile.image} alt="" />
                                                : <div className="text-versich-darktext-color text-3xl md:text-4xl font-semibold flex justify-center items-center w-full h-full shadow-card4 rounded-xl">
                                                    {getInitials(companyName)}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="text-versich-dark-blue sm:text-start flex-grow inline-flex flex-col sm:ml-4 mb-2 sm:mb-6">
                                        <span className="text-2xl font-semibold">{companyName}</span>
                                        <span className="text-versich-blue text-base">Business</span>
                                    </div>
                                    {user.details.data.website &&
                                        <div className="text-sm font-semibold">
                                            <Link target="_blank" to={user.details.data.website} className="bg-white hover:text-versich-blue-hover text-versich-blue inline-flex items-center justify-center gap-2 border border-versich-blue py-1 lg:py-2 px-2 lg:px-4 rounded-lg">
                                                <img src={internetIcon} alt="" />
                                                <span>Visit Website</span>
                                            </Link>
                                        </div>
                                    }
                                </div>
                                <div className="mt-6 space-y-2.5">
                                    <div className="grid md:grid-cols-2 gap-x-4 gap-y-3">
                                        {user.about && <>
                                            <About data={user.about} />
                                            <OtherDetails data={user.about} />
                                        </>}
                                        {user.location && <Location data={user.location} hideEmpty={true} />}
                                        {user.socialProfiles && <SocialMedia data={user.socialProfiles} hideEmpty={true} />}
                                    </div>
                                    {user.reviews && <Reviews data={user.reviews} />}
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
            : <ErrorPage status={404} />
    )
}
export default BusinessProfile