import greenCheck from "../assets/svgs/check.svg"
import DialogBox from "./DialogBox"

const ConfirmationDialog = ({ title, description, icon, customCloseEvent }) => {
    return (
        <DialogBox
            id={confirmationDialog}
            className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-sm"
            toastStacked={false}
            hideClose={true}
            customCloseEvent={customCloseEvent}
        >
            <div className="bg-lighter bg-opacity-20 mx-auto p-4 lg:p-4">
                <div className="flex justify-center p-4 py-6">
                    <img className="max-w-16 sm:max-w-24 lg:max-w-32 w-full" src={icon || greenCheck} alt="request created" />
                </div>
                <div className="text-center mb-8 sm:mb-10">
                    <h2 className="text-versich-darktext-color font-semibold text-lg sm:text-xl lg:text-2xl mb-5">{title}</h2>
                    <div className="text-xs sm:text-sm lg:text-base">{description}</div>
                </div>
                <div className="max-w-fit mx-auto">
                    <button className="primary_btn min-w-20 md:py-3 !px-6 lg:!px-10" type="button" onClick={customCloseEvent} data-parent-id={confirmationDialog}>Ok</button>
                </div>
            </div>
        </DialogBox >
    )
}
export const confirmationDialog = "confirmation-dialog"
export default ConfirmationDialog