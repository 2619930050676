import { useSelector } from "react-redux"
import { useLocation, Navigate } from "react-router-dom"

import { dashboardPath, loginPath } from "../assets/constants"

const AuthOrDashboard = () => {
    const { isAuthenticated, user } = useSelector(state => state.auth)
    const location = useLocation()
    const path = window.encodeURIComponent(location.pathname + location.search)
    if (isAuthenticated && user !== null) {
        return <Navigate to={dashboardPath} />
    }
    return <Navigate to={`${loginPath}?next=${path}`} />
}
export default AuthOrDashboard