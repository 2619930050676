import { useSelector } from "react-redux"

import sendIcon from "../../assets/svgs/send.svg"
import linkIcon from "../../assets/social-icons/share/link.png"
import whatsappIcon from "../../assets/social-icons/share/whatsapp.png"
import envelopeIcon from "../../assets/social-icons/share/envelope.png"
import redditIcon from "../../assets/social-icons/share/reddit.png"
import telegramIcon from "../../assets/social-icons/share/telegram.png"
import chatIcon from "../../assets/social-icons/share/chat.png"
import xIcon from "../../assets/social-icons/x_color.svg"
import linkedinIcon from "../../assets/social-icons/linkedin_color.svg"
import pinterestIcon from "../../assets/social-icons/pinterest_color.svg"
import facebookIcon from "../../assets/social-icons/facebook_color.svg"
import DialogBox, { closeDialog, openDialog } from "../DialogBox"
import Close from "../svgs/Close"
import { copyTextToClipboard, getInitials, titleCase } from "../../utils"
import { BUSINESS, TOASTTYPE_INFO } from "../../assets/constants"
import toastify from "../../utils/toastify"

const ShareProfileButton = ({ profilePath }) => {
    const { user } = useSelector(state => state.auth)

    let userName, fullName
    if (user.accountType === BUSINESS) {
        fullName = userName = user.business.details.data.companyName
    }
    else {
        userName = user.about.firstName
        fullName = userName + " " + user.about.lastName
    }
    const initials = getInitials(fullName)
    const fullProfilePath = process.env.REACT_APP_CLIENT_URL + profilePath

    // Copy profile link & show notification
    const copyProfileToClipBoard = (e) => {
        const button = e.currentTarget
        copyTextToClipboard(fullProfilePath)
        toastify(
            <div className="font-normal text-start">Profile successfully copied to clipboard!</div>,
            TOASTTYPE_INFO, button, shareProfileDialog
        )
    }

    // Share target
    const clickHandler = (e) => {
        const targetUrl = e.currentTarget.dataset.targetUrl
        const target = e.currentTarget.dataset.samewindow ? "_parent" : "_blank"
        window.open(targetUrl, target)
    }
    return (<>
        <button
            className="bg-versich-blue hover:bg-versich-blue-hover text-white text-sm min-h-12 inline-flex items-center justify-center gap-2 py-2 lg:py-3 px-3.5 lg:px-5 border-2 border-versich-blue hover:border-versich-blue-hover rounded-[10px]"
            type="button"
            onClick={openDialog} data-parent-id={shareProfileDialog}
        >
            <img src={sendIcon} alt="" data-parent-id={shareProfileDialog} />
            <span data-parent-id={shareProfileDialog}>Share Profile</span>
        </button>
        <DialogBox
            id={shareProfileDialog}
            className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
            toastStacked={false}
            hideClose={true}
        >
            <div className="space-y-[34px] p-2.5 md:p-4">
                <div className="flex gap-1.5">
                    <div className="text-versich-dark-blue flex-1">
                        <div className="text-xl md:text-2xl font-semibold text-left">Share {userName}'s Profile</div>
                    </div>
                    <div id="dialog_closebtn" className="text-right">
                        <button type="button" className="m-1 p-1" onClick={closeDialog} data-parent-id={shareProfileDialog}>
                            <Close className="w-4 h-4" />
                        </button>
                    </div>
                </div>
                <div className="flex gap-x-2.5 items-center relative">

                    {/* Avatar */}
                    <div className="bg-[#F6F9FF] w-14 h-14 lg:w-20 lg:h-20 rounded-full">
                        {user.profile?.image
                            ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                            : user.social?.image
                                ? <img className="w-full h-full object-cover rounded-full" src={user.social.image} alt="" />
                                : <div className="text-versich-darktext-color text-base lg:text-lg font-semibold flex justify-center items-center w-full h-full rounded-full">
                                    {initials}
                                </div>
                        }
                    </div>
                    <div className="text-start text-versich-dark-blue">
                        <div className="text-lg lg:text-xl font-semibold">{fullName}</div>
                        <div className="text-sm font-normal">{titleCase(user.accountType)}</div>
                    </div>
                </div>
                <div className="text-versich-label text-xs font-medium grid grid-cols-1 smallest:grid-cols-2 sm401:grid-cols-3 smaller:grid-cols-4 lg:max-xl:grid-cols-4 sm:grid-cols-5 gap-2.5 mt-5 p-1">
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={copyProfileToClipBoard}>
                        <img className="w-7 h-7" src={linkIcon} alt="" />
                        <span>Copy link</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://api.whatsapp.com/send?text=${fullProfilePath}&mp_source=share`}
                    >
                        <img className="w-7 h-7" src={whatsappIcon} alt="" />
                        <span>Whatsapp</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`mailto:?body=${fullProfilePath}`}
                        data-samewindow="true"
                    >
                        <img className="w-7 h-7" src={envelopeIcon} alt="" />
                        <span>Email</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://x.com/intent/post?text=?${fullProfilePath}`}
                    >
                        <img className="w-7 h-7" src={xIcon} alt="" />
                        <span>X</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://www.linkedin.com/shareArticle?mini=true&url=${fullProfilePath}&source=VersiMarket`}
                    >
                        <img className="w-7 h-7" src={linkedinIcon} alt="" />
                        <span>Linkedin</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://pinterest.com/pin/create/button/?url=${fullProfilePath}`}
                    >
                        <img className="w-7 h-7" src={pinterestIcon} alt="" />
                        <span>Pinterest</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://www.facebook.com/sharer.php?u=${fullProfilePath}`}
                    >
                        <img className="w-7 h-7" src={facebookIcon} alt="" />
                        <span>Facebook</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://www.reddit.com/submit?url=${fullProfilePath}`}
                    >
                        <img className="w-7 h-7" src={redditIcon} alt="" />
                        <span>Reddit</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`https://t.me/share/url?url=${fullProfilePath}`}
                    >
                        <img className="w-7 h-7" src={telegramIcon} alt="" />
                        <span>Telegram</span>
                    </div>
                    <div
                        className="hover:bg-brandeis-blue/[0.04] inline-flex flex-col items-center justify-center gap-1.5 p-4 border border-brandeis-blue/[0.15] rounded-[10px] cursor-pointer"
                        onClick={clickHandler}
                        data-target-url={`sms:?body=${fullProfilePath}`}
                        data-samewindow="true"
                    >
                        <img className="w-7 h-7" src={chatIcon} alt="" />
                        <span>SMS</span>
                    </div>
                </div>
            </div>
        </DialogBox >
    </>)
}
const shareProfileDialog = "shareprofile-dialog"
export default ShareProfileButton   