import { Helmet } from "react-helmet"

const toISODate = (ms) => new Date(ms).toISOString()

const JobPostingStructuredData = ({ job }) => {
    if (!job) return null

    // Extract fields from your job object
    const { createdAt, details } = job
    const { title, description } = details

    // Build the JSON-LD data
    const jsonLd = {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        "url": window.location.href,
        "title": title,
        "description": description,
        "datePosted": toISODate(createdAt),
        "employmentType": [
            "CONTRACTOR",
            "TEMPORARY"
        ],
        "directApply": false,
        "hiringOrganization": {
            "@type": "Organization",
            "name": "VersiMarket",
            "sameAs": "https://versimarket.com/"
        },
        "jobLocation": {
            "@type": "Place",
            "address": {
                "@type": "PostalAddress",
                "addressCountry": "UK"
            }
        },
        "jobLocationType": "TELECOMMUTE",
        "applicantLocationRequirements": {
            "@type": "Country",
            "name": [
                "GB",
                "US",
                "India",
                "Canada"
            ]
        }
    }
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        </Helmet>
    )
}
export default JobPostingStructuredData
