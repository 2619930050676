import { useState, useEffect } from "react"
import { useParams, useSearchParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { FaRegEye } from "react-icons/fa"
import { BsCheck2Circle } from "react-icons/bs"

import noData from "../../../assets/images/no-data.png"
import Meta from "../../../components/Meta"
import SearchBar from "../../../components/FindBusinesses/SearchBar"
import Filters from "../../../components/FindBusinesses/Filters"
import BusinessProfileCard from "../../../components/FindBusinesses/BusinessProfileCard"
import DirectContactDialog from "../../../components/FindBusinesses/DirectContactDialog/DirectContactDialog"
import SectionLoader from "../../../components/loader/SectionLoader"
import Paginator from "../../../components/Paginator"
import { getPublicBusinessProfilesAsync } from "../../../slices/business/public"
import { FREELANCER_TYPES, findBusinessesPath } from "../../../assets/constants"
import { toConversationDetails } from "../../../assets/path"

const metadata = {
    title: "Find Businesses on VersiMarket - Leading Brands & Services",
    description: "Discover top businesses on VersiMarket. Explore leading brands, read reviews, and find the best services for your needs.",
    url: `${process.env.REACT_APP_CLIENT_URL}/`
}

const FindBusinesses = () => {
    const { user } = useSelector(state => state.auth)
    const [businesses, setBusinesses] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const initialPage = parseInt(searchParams.get("page") || "1", 10)
    const [currentPage, setCurrentPage] = useState(initialPage)
    const [totalPages, setTotalPages] = useState(1)
    const [businessId, setBusinessId] = useState(null)
    const [contacted, setContacted] = useState({
        status: false,
        message: "",
        conversationId: 0
    })

    const { service } = useParams()
    const hideContact = FREELANCER_TYPES.includes(user?.accountType) === false

    // Query parameters
    const querySearch = searchParams.get("search") || ""
    const filterSortBy = searchParams.get("sortby") || ""
    const filterVerified = searchParams.get("verified") || ""
    const filterBudget = searchParams.get("budget") || ""

    /** 
    * Determine the effective search term.
    *  - If the URL contains a query parameter "search"
    *  - If there’s a route parameter (e.g. /find/web-app-development/businesses)
    */
    const effectiveQuery = querySearch || service || ""

    const combinedFilters = {
        ...(effectiveQuery && { search: effectiveQuery }),
        ...(currentPage && { page: currentPage }),
        ...(filterSortBy && { sortby: filterSortBy }),
        ...(filterVerified && { verified: filterVerified }),
        ...(filterBudget && { budget: filterBudget }),
    }

    // Fetch businesses based on search term, filters and page
    const dispatch = useDispatch()
    useEffect(() => {
        const fetchBusinesses = async () => {
            setBusinesses([])
            const stringParams = new URLSearchParams(combinedFilters).toString()
            await dispatch(getPublicBusinessProfilesAsync(`?${stringParams}`))
                .unwrap()
                .then(async (data) => {
                    await new Promise(resolve => setTimeout(resolve, 50))
                    setBusinesses(data.results)
                    setTotalPages(data.totalPages)
                })
                .catch(() => setBusinesses(null))
        }
        fetchBusinesses()
    }, [effectiveQuery, currentPage, filterBudget, filterSortBy, filterVerified])


    // Update search resets all filter keys
    const updateSearch = (newQuery) => {
        const params = {}
        if (newQuery) {
            params.search = newQuery
        }
        params.page = "1"
        setSearchParams(params, { replace: true })
        setCurrentPage(1)
    }
    /**
     * Update filters
     * Merge with existing search value  
     * When a manual filter change occurs, reset page to 1
     */
    const updateFilters = (newFilters, resetPage = false) => {
        const params = { ...(querySearch && { search: querySearch }) }
        Object.keys(newFilters).forEach((key) => {
            if (newFilters[key]) {
                params[key] = newFilters[key]
            }
        })
        if (resetPage) {
            params.page = "1"
            setCurrentPage(1)
        }
        setSearchParams(params, { replace: true })
    }
    // Change the page number in URL
    const changePage = (number) => {
        let params = Object.fromEntries([...searchParams].filter(([key, value]) => value))
        if (number > 1) {
            params.page = number
        }
        else delete params.page
        setSearchParams(params, { replace: true })
    }
    useEffect(() => {
        changePage(currentPage)
    }, [currentPage])

    // Set query 'title'
    const queryTitle = service
        ? `List of Top ${service
            .split("-")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")} Agencies`
        : "List of the Top Agencies"

    const navigate = useNavigate()
    return (
        <div>
            <Meta data={metadata} />
            <div className="text-versich-darktext-color w-full h-full px-8 md:px-16 xl:px-28 pt-[100px] pb-[80px]">
                <div className="max_screen_w">
                    <div className="text-3xl sm:text-4xl lg:text-5xl font-semibold text-left">
                        <h1 className="leading-tight">Find Top Businesses on VersiMarket</h1>
                        <p className="text-base sm:text-lg lg:text-xl tracking-wide py-3 font-normal">Explore verified business profiles to find the perfect partner for your needs.</p>
                    </div>
                    <div className="mt-6 sm:mt-8 lg:mt-10 space-y-4 sm:space-y-5 lg:space-y-6">
                        <SearchBar
                            id="business-search"
                            placeholder="Search Business"
                            setQuery={updateSearch}
                            initialSearch={querySearch}
                            parentPath={findBusinessesPath}
                        />
                        <Filters
                            setFilters={updateFilters}
                            initialSortby={filterSortBy}
                            initialVerified={filterVerified}
                            initialBudget={filterBudget}
                        />
                    </div>
                </div>
            </div>
            <div className="pb-12 md:pb-16 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <h2 className="text-versich-darktext-color text-xl smaller:text-2xl lg:text-3xl font-bold text-left pb-2">{queryTitle}</h2>
                    {contacted.status && (
                        <div className="bg-white/25 backdrop-blur-xl mt-4 md:mt-5 -mb-5 md:-mb-8 sticky inset-x-1.5 top-2 z-50">
                            <div className="bg-brandeis-blue/5 border border-brandeis-blue/20 text-versich-dark-blue font-semibold text-start px-4 py-4 sm:px-6 sm:py-6 shadow-sticker rounded-xl">
                                <div className="flex flex-wrap items-center justify-between gap-y-2 gap-x-4">
                                    <p>{contacted.message}</p>
                                    {contacted.conversationId ? (
                                        <button
                                            className="text-versich-blue hover:text-versich-blue-hover text-sm inline-flex items-center gap-1.5"
                                            onClick={() => toConversationDetails(contacted.conversationId, navigate)}
                                            title="View Message"
                                        >
                                            <FaRegEye className="w-3.5 md:w-4 h-3.5 md:h-4" />
                                            <span>View message</span>
                                        </button>
                                    ) : (
                                        <BsCheck2Circle className="text-versich-light-blue text-xl" />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="grid gap-y-8 md:gap-y-10 text-left my-10 md:my-16">
                        {businesses
                            ? businesses.length > 0
                                ? <>
                                    {businesses.map((business, index) => (
                                        <BusinessProfileCard user={business} contactBusiness={!hideContact ? null : () => setBusinessId(business.id)} key={index} />
                                    ))}
                                    {hideContact && <DirectContactDialog userId={businessId} setContacted={setContacted} />}
                                </>
                                : <SectionLoader className="h-[500px]" />
                            : <div className="flex flex-col items-center justify-center">
                                <div>
                                    <img className="w-full h-full max-w-[500px] max-h-[500px]" src={noData} alt="no business found" />
                                </div>
                                <p className="text-versich-darktext-color text-sm -mt-5">No business found</p>
                            </div>
                        }
                    </div>
                    {totalPages &&
                        <Paginator pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} maxPages={3} />
                    }
                </div>
            </div>
        </div>
    )
}
export default FindBusinesses
