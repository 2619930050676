import { useSelector, useDispatch } from "react-redux"
import { useState } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import AddPlus from "../../../svgs/AddPlus"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import FormLoader from "../../../loader/FormLoader"
import Close from "../../../svgs/Close"
import AddExperience from "./experience/AddExperience"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import toastify from "../../../../utils/toastify"
import { TOASTTIMER, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { freelancerExperienceSetAsync } from "../../../../slices/freelancer/experience"

const Experience = () => {
    const { user } = useSelector((state) => state.auth)

    const [csrfToken, setCsrfToken] = useState("")
    const [experience, setExperiences] = useState(null)
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        setSubmitted(true)
        const experienceKeys = Object.keys(experience)
        const emptyDetails = experienceKeys.filter(k =>
            experience[k].title === "" ||
            experience[k].company === "" ||
            experience[k].startMonth === 0 ||
            experience[k].startYear === 0 ||
            (!experience[k].present
                && (
                    experience[k].endMonth === 0 ||
                    experience[k].endYear === 0 ||
                    experience[k].endYear < experience[k].startYear ||
                    (experience[k].endYear === experience[k].startYear && experience[k].endMonth < experience[k].startMonth)
                )
            )
        )
        if (emptyDetails.length > 0) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }
        setLoading(true)
        const formData = {
            experience: experienceKeys.map(k => {
                return {
                    title: experience[k].title,
                    company: experience[k].company,
                    start_month: experience[k].startMonth,
                    start_year: experience[k].startYear,
                    end_month: experience[k].endMonth,
                    end_year: experience[k].endYear,
                    present: experience[k].present,
                }
            }),
            token: csrfToken
        }
        dispatch(freelancerExperienceSetAsync(formData))
            .unwrap()
            .then(() => {
                const dialog = getDialog(e.target)
                if (dialog) {
                    closeDialog(null, dialog)
                }
                return setLoading(false)
            })
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }
    const hasExperience = user.experience && user.experience.length > 0
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card5 rounded-lg">
            <div className="font-medium flex max-sm401:flex-col sm401:justify-between gap-x-3 gap-y-1.5 py-3 border-b border-[#EFEEEE99]">
                <span className="text-versich-dark-blue text-xl">Experience</span>
                <button className="inline-flex items-center gap-1 hover:opacity-80" onClick={openDialog} data-parent-id={experienceEditDialog}>
                    {hasExperience
                        ? <>
                            <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={experienceEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={experienceEditDialog}>Edit</span>
                        </>
                        : <>
                            <AddPlus className="[&>*]:stroke-versich-light-blue w-[18px] h-[18px]" dataParentId={experienceEditDialog} />
                            <span className="text-versich-light-blue text-xs" data-parent-id={experienceEditDialog}>Add</span>
                        </>
                    }
                </button>
            </div>
            <div>
                {hasExperience
                    ? user.experience.map((exp, index) => (
                        <div className="text-versich-dark-blue text-sm my-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EFEEEE99]/50 space-y-1.5" key={index}>
                            <p className="font-semibold pb-0.5">{exp.title}</p>
                            <p>{exp.company}</p>
                            <p>
                                <span>{exp.startMonthName}, {exp.startYear}</span>
                                {exp.present
                                    ? <span> - Present</span>
                                    : <span> - {exp.endMonthName}, {exp.endYear}</span>
                                }
                            </p>
                        </div>
                    ))
                    : <div className="text-versich-dark-blue h-1 my-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EFEEEE99]/50 space-y-8" />
                }
            </div>
            <DialogBox
                id={experienceEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-md:max-w-lg lg:w-3/4 2xl:!w-2/3 !max-w-4xl"
                toastStacked={false}
                hideClose={true}
            >
                <div className="p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Experience</div>
                            <div className="text-sm mt-0.5">Manage your experience</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={closeDialog} data-parent-id={experienceEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <form className="space-y-[34px]" method="dialog" onSubmit={handleSubmit} data-parent-id={experienceEditDialog}>
                        <AddExperience maximum={4} minimum={1} submitted={submitted} experience={user.experience} setExperience={setExperiences} />
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={closeDialog} data-parent-id={experienceEditDialog}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">
                                {hasExperience ? "Update" : "Add"}
                            </button>
                        </div>
                    </form>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div >
    )
}
const experienceEditDialog = "experience-edit-dialog"
export default Experience