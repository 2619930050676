import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../../../api"
import apiErrorParser from "../../../utils/apiErrorParser"

export const allSoftwareTypeAsync = createAsyncThunk("service/SD/allSoftwareType", async (params) => {
    try {
        const { data } = await api.softwareTypesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allSoftwareNatureAsync = createAsyncThunk("service/SD/allSoftwareNature", async (params) => {
    try {
        const { data } = await api.softwareNaturesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allOperatingSystemAsync = createAsyncThunk("service/SD/allOperatingSystem", async (params) => {
    try {
        const { data } = await api.operatingSystemsSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allIndustryTypeAsync = createAsyncThunk("service/SD/allIndustryType", async (params) => {
    try {
        const { data } = await api.industryTypesSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const allServiceEngagementAsync = createAsyncThunk("service/SD/allServiceEngagement", async (params) => {
    try {
        const { data } = await api.serviceEngagementsSD(params)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createRequestAsync = createAsyncThunk("service/SD/createRequest",
    async ({
        softwareType, otherSoftwareType, softwareNature, otherSoftwareNature, operatingSystem, otherOperatingSystem,
        industryType, otherIndustryType, serviceEngagement, otherServiceEngagement, paymentType, currency, budget, otherBudget,
        hiringDecision, otherHiringDecision, clientEmail, clientName, title, description, token
    }) => {
        try {
            const reqData = {
                requestType: "software-development",
                softwareTypeId: softwareType,
                otherSoftwareType,
                softwareNatureId: softwareNature,
                otherSoftwareNature,
                osId: operatingSystem,
                otherOS: otherOperatingSystem,
                industryTypeId: industryType,
                otherIndustryType,
                paymentTypeId: paymentType,
                currencyId: currency,
                estimatedBudgetId: budget,
                otherEstimatedBudget: otherBudget,
                serviceEngagementId: serviceEngagement,
                otherServiceEngagement,
                hiringDecisionId: hiringDecision,
                otherHiringDecision,
                clientEmail,
                clientName,
                title,
                description,
                csrfmiddlewaretoken: token
            }
            const { data } = await api.createRequest(reqData)
            if (data.status !== "success") {
                throw Error(JSON.stringify(data))
            }
            return data
        }
        catch (error) {
            throw apiErrorParser(error)
        }
    })