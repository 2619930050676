import { Link, useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"

import { getServiceJobsPath, getFullJobPath, getSubmitProposalPath } from "../../assets/path"
import { fetchPrimaryAndSubService } from "./utils"
import { tagClass } from "../../assets/class"

const JobCard = ({ job, updateSearch }) => {
    const {
        refId,
        createdAt,
        details: { title, serviceId, requestType, paymentType, price, duration, otherDuration, tags, shortDesc },
    } = job

    // Fetch service 'title & uid'
    const service = fetchPrimaryAndSubService(serviceId, requestType)

    // Define the path for viewing a job
    const navigate = useNavigate()
    const viewJobPath = getFullJobPath(refId)
    const submitProposalPath = getSubmitProposalPath(refId)
    return (
        <div className="bg-white hover:bg-majorelle-blue/[0.02] max-smaller:py-7 max-smaller:px-4 max-md:px-6 p-[38px] border border-versich-light-blue/20 rounded-2xl group">
            <div className="flex flex-col lg:flex-row gap-y-2.5 sm:gap-10">
                <div className="flex-grow">
                    <h3 className="text-versich-darktext-color text-lg lg:text-xl font-bold">
                        <Link to={viewJobPath} className="group-hover:text-versich-light-blue inline-block hover:underline underline-offset-2 decoration-majorelle-blue/20 transition-colors">
                            {title}
                        </Link>
                    </h3>
                    <div className="text-xs md:text-sm mt-0.5 md:mt-1">
                        Posted {formatDistanceToNow(createdAt, { addSuffix: true })}
                    </div>
                    <p className="mt-1.5 md:mt-2.5">
                        {shortDesc || "No description provided."}
                    </p>
                </div>

                <div className="sm:max-lg:grid grid-cols-2 lg:min-w-[200px] max-sm:mt-3 sm:max-lg:space-y-0 space-y-3 rounded-xl">
                    <div className="space-y-0.5 md:space-y-1">
                        {paymentType && (
                            <div className="text-xs md:text-sm">
                                {paymentType.title} Price
                            </div>
                        )}
                        {price && (
                            <div className="text-versich-darktext-color text-sm smaller:text-base lg:text-lg font-medium">
                                {price}
                            </div>
                        )}
                    </div>
                    {duration && (
                        <div className="space-y-0.5 md:space-y-1">
                            <div className="text-xs md:text-sm">Duration</div>
                            <div className="text-versich-darktext-color text-xs smaller:text-sm lg:text-base font-medium">
                                {duration.uid === "other" ? otherDuration : duration.title}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="text-xs md:text-sm flex gap-1.5 flex-wrap mt-5 sm:pt-1.5">
                {service.primary && (
                    <Link to={getServiceJobsPath(service.primary.uid)} className={tagClass} key={service.primary.uid}>
                        {service.primary.title}
                    </Link>
                )}
                {service.sub && (
                    <Link to={getServiceJobsPath(service.sub.uid)} className={tagClass} key={service.sub.uid}>
                        {service.sub.title}
                    </Link>
                )}
                {tags &&
                    tags.map((tag) => (
                        tag.uid !== "other" ? (
                            <button onClick={() => updateSearch(tag.title)} className={tagClass} key={tag.uid}>
                                {tag.title}
                            </button>
                        ) : null
                    ))
                }
            </div>
            <div className="flex flex-wrap lg:justify-end gap-3 mt-3 sm:mt-5 pt-5 lg:pt-7">
                <button onClick={() => navigate(submitProposalPath)} className="primary_btn">
                    Submit Proposal
                </button>
                <button onClick={() => navigate(viewJobPath)} className="accent_btn">
                    View Job
                </button>
            </div>
        </div>
    )
}
export default JobCard