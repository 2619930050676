import { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { newRequestPath } from "../../../assets/constants"

const SearchSuggestionsInput = ({ onChange, value, setValue, suggestions }) => {
    const [isOpen, setIsOpen] = useState(false)

    const navigate = useNavigate()
    const handleOptionSelection = (option) => {
        setValue(option.service)
        setIsOpen(false)
        navigate(`${newRequestPath + option.uid}/`)
    }

    // Handle Outside click
    const comboInputRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            comboInputRef.current && !comboInputRef.current.contains(event.target)
                && setIsOpen(false)
        }
        document.addEventListener("click", handleClickOutside)
        return () => {
            document.removeEventListener("click", handleClickOutside)
        }
    }, [])
    return (
        <div className="flex-1 flex flex-col gap-y-4" ref={comboInputRef}>
            <div className="h-full relative z-50">
                <input
                    id="primary-search"
                    className="text-black w-full min-w-0 h-full p-1.5 lg:p-2 outline-none rounded-xl required"
                    name="selectedServices"
                    type="text"
                    onChange={onChange}
                    value={value}
                    placeholder="What service are you looking for?"
                    onClick={() => setIsOpen(true)}
                />
                {isOpen && suggestions.length > 0 &&
                    <div className="bg-white max-h-48 w-full border-[1px] border-versich-darktext-color/20 rounded-bl-md rounded-br-md absolute top-[calc(100%+6px)] lg:top-[calc(100%+8px)] overflow-y-auto">
                        {suggestions.map((option) => (
                            <ul key={option.uid}>
                                <li className="text-versich-darktext-color text-left p-2 border-b border-gray-200/70" onClick={() => handleOptionSelection(option)}>{option.service}</li>
                            </ul>
                        ))}
                    </div>
                }
            </div>
        </div>
    )
}
export default SearchSuggestionsInput
