import { createAsyncThunk } from "@reduxjs/toolkit"

import * as api from "../api"
import apiErrorParser from "../utils/apiErrorParser"

export const submitProposalAsync = createAsyncThunk("service/request/submitProposal", async ({ refId, coverLetter, attachment, token }) => {
    try {
        const formData = new FormData()
        formData.append("refId", refId)
        formData.append("coverLetter", coverLetter)
        formData.append("attachment", attachment)
        formData.append("csrfmiddlewaretoken", token)
        const { data } = await api.submitProposal(formData)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const userProposalsAsync = createAsyncThunk("service/request/userProposals", async () => {
    try {
        const { data } = await api.userProposals()
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getProposalAsync = createAsyncThunk("service/request/getProposal", async (id) => {
    try {
        const { data } = await api.getProposal(id)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const getClientRequestProposalsAsync = createAsyncThunk("service/request/getClientRequestProposals", async (requestRefId) => {
    try {
        const { data } = await api.getClientRequestProposals(requestRefId)
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})

export const createConversationFromProposalAsync = createAsyncThunk("service/request/createConversationFromProposal", async ({ uid, content, token }) => {
    try {
        const { data } = await api.createConversationFromProposal({ uid, content, csrfmiddlewaretoken: token })
        if (data.status !== "success") {
            throw Error(JSON.stringify(data))
        }
        return data
    }
    catch (error) {
        throw apiErrorParser(error)
    }
})