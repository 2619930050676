import { useState, useEffect } from "react"

import MiddleTextSeparator from "../../../../../pages/auth/socialAccounts/MiddleTextSeparator"
import TextFieldInput from "../../../../Services/TextFieldInput"
import MonthDropdownFieldInput from "../../../../Services/MonthDropdownFieldInput"
import YearDropdownFieldInput from "../../../../Services/YearDropdownFieldInput"
import BooleanFieldInput from "../../../../Services/BooleanFieldInput"
import AddPlus from "../../../../svgs/AddPlus"
import { TOASTTIMER } from "../../../../../assets/constants"

const ExperienceFields = ({ data, submitted, position, minimum, fieldStatus, removeExperience, setExperience }) => {
    const [title, setTitle] = useState("")
    const [titleError, setTitleError] = useState(false)
    const [company, setCompany] = useState("")
    const [companyError, setCompanyError] = useState(false)
    const [startMonth, setStartMonth] = useState(0)
    const [startMonthError, setStartMonthError] = useState(false)
    const [startYear, setStartYear] = useState(0)
    const [startYearError, setStartYearError] = useState(false)
    const [endMonth, setEndMonth] = useState(0)
    const [endMonthError, setEndMonthError] = useState(false)
    const [endYear, setEndYear] = useState(0)
    const [endYearError, setEndYearError] = useState(false)
    const [irrelaventMonthsError, setIrrelaventMonthsError] = useState(false)
    const [irrelaventYearsError, setIrrelaventYearsError] = useState(false)
    const [present, setPresent] = useState(false)

    // Pre-fill fields
    useEffect(() => {
        if (data && data.length >= position) {
            const d = data[position - 1]
            setTitle(d.title)
            setCompany(d.company)
            setStartMonth(d.startMonth)
            setStartYear(d.startYear)
            if (!d.present) {
                setEndMonth(d.endMonth)
                setEndYear(d.endYear)
            }
            setPresent(d.present)
        }
    }, [data])

    // Track changes in experience
    const updateExperience = (add) => {
        setExperience(preExperience => {
            let details
            if (add) {
                details = {
                    ...preExperience, [position]: {
                        title,
                        company,
                        startMonth,
                        startYear,
                        endMonth,
                        endYear,
                        present
                    }
                }
            }
            else {
                let d = Object.entries({ ...preExperience }).filter(([key, value]) => parseInt(key) <= fieldStatus.count)
                if (d.length > 0) {
                    details = Object.fromEntries(d)
                }
            }
            return details
        })
    }
    useEffect(() => {
        updateExperience(true)
    }, [title, company, startMonth, startYear, endMonth, endYear, present])

    // Add/Remove experience
    useEffect(() => {
        updateExperience(fieldStatus.added)
    }, [fieldStatus])

    useEffect(() => {
        setTitleError(submitted && title === "")
        setCompanyError(submitted && company === "")
        setStartMonthError(submitted && startMonth === 0)
        setStartYearError(submitted && startYear === 0)
        setEndMonthError(submitted && !present && endMonth === 0)
        setEndYearError(submitted && !present && endYear === 0)

        // Validate dates
        if (submitted && !present
            && endYear && endMonth
            && startYear && startMonth
        ) {
            if (endYear < startYear) {
                setIrrelaventMonthsError(true)
                setIrrelaventYearsError(true)
                setTimeout(() => {
                    setIrrelaventMonthsError(false)
                    setIrrelaventYearsError(false)
                }, TOASTTIMER)
            }
            else if (endYear === startYear && endMonth < startMonth) {
                setIrrelaventMonthsError(true)
                setTimeout(() => {
                    setIrrelaventMonthsError(false)
                }, TOASTTIMER)
            }
        }
    }, [submitted])
    return (
        <div className="space-y-5 experience">
            {position > 1 &&
                <MiddleTextSeparator word={
                    <p className="w-[128px] mb-2.5">Experience #{position}</p>
                } />
            }
            <TextFieldInput
                name={`title_${position}`}
                label="Title"
                placeholder="e.g. Business Analyst"
                onChange={(e) => setTitle(e.target.value)}
                defaultValue={title}
                required="Title is required"
                error={titleError}
            />
            <TextFieldInput
                name={`company_${position}`}
                label="Company"
                placeholder="e.g. Business Solution"
                onChange={(e) => setCompany(e.target.value)}
                defaultValue={company}
                required="Company is required"
                error={companyError}
            />
            <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                <MonthDropdownFieldInput
                    name={`startMonth_${position}`}
                    label="Start Month"
                    value={startMonth}
                    placeholder="Select start month"
                    setMonthValue={setStartMonth}
                    required="Start month is required"
                    error={startMonthError}
                />
                <YearDropdownFieldInput
                    name={`startYear_${position}`}
                    label="Start Year"
                    value={startYear}
                    placeholder="Select start year"
                    setYearValue={setStartYear}
                    required="Start year is required"
                    error={startYearError}
                />
            </div>
            {!present && (<>
                <div className="grid grid-cols-1 gap-x-3 gap-5 lg:grid-cols-2">
                    <MonthDropdownFieldInput
                        name={`endMonth_${position}`}
                        label="End Month"
                        value={endMonth}
                        placeholder="Select end month"
                        setMonthValue={setEndMonth}
                        required={irrelaventMonthsError ? "" : "End month is required"}
                        error={irrelaventMonthsError || endMonthError}
                    />
                    <YearDropdownFieldInput
                        name={`endYear_${position}`}
                        label="End Year"
                        value={endYear}
                        placeholder="Select end year"
                        setYearValue={setEndYear}
                        required={irrelaventYearsError ? "" : "End year is required"}
                        error={irrelaventYearsError || endYearError}
                    />
                </div>
                {(irrelaventMonthsError || irrelaventYearsError) &&
                    <div className="text-sm text-red-500 text-left mt-2">End date can't be earlier than start date.</div>
                }
            </>)}
            <BooleanFieldInput
                name={`present_${position}`}
                label="I currently work here"
                onChange={(e) => setPresent(e.target.checked)}
                defaultValue={present}
            />
            <div className={"hover:opacity-80 items-center justify-end gap-x-1.5 group cursor-pointer remove_button" + (
                position > minimum ? " flex" : " hidden"
            )} onClick={removeExperience}>
                <AddPlus className="[&>*]:stroke-red-500 rotate-45 group-hover:[&>*]:opacity-80 smaller:max-lg:w-5 smaller:max-lg:h-5 w-6 h-6 transition-transform" />
                <span className="text-red-500 text-sm font-medium">Remove Experience</span>
            </div>
        </div>
    )
}
export default ExperienceFields