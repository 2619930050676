import Meta from "../../components/Meta"

const metadata = {
    title: "Terms and Conditions | User Agreement",
    description: "Review our Terms and Conditions to understand your rights and responsibilities while using our services.",
    url: `${process.env.REACT_APP_CLIENT_URL}/legal/terms/`
}

const TermsAndConditions = () => {
    return (
        <div>
            <Meta data={metadata} />
            <div className="bg-white py-10 md:py-14 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <div className="pb-3 sm:pb-6 border-b border-versich-light-blue/10">
                        <h1 className="text-versich-darktext-color text-2xl sm:text-3xl font-bold">VersiMarket Terms and Conditions</h1>
                        <h2 className="text-versich-dark-blue text-sm smaller:text-base font-semibold text-left pt-8 sm:pt-10">Effective Date: February 3rd 2025</h2>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 space-y-12 sm:space-y-16">
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">1. Introduction</h2>
                            <div className="text-sm lg:text-base space-y-3 sm:space-y-5">
                                <div>Welcome to VersiMarket ("Platform"), the online marketplace connecting freelancers, consultants, and businesses to find work, discover talent, and list services for enhanced visibility. VersiMarket is a division of Versich, a company registered in England (Company No. 14629622) with its registered office at Broad House, Imperial Drive, HA2 7BL, London, United Kingdom.</div>
                                <div>By accessing or using our Platform, you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree to these Terms, please do not access or use the Platform.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">2. Acceptance of Terms</h2>
                            <div className="text-sm lg:text-base">
                                By using the Platform, you confirm that you have read, understood, and accept these Terms, as well as any additional policies or guidelines referenced herein. Your continued use of the Platform constitutes your acceptance of any changes or updates to these Terms.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">3. Amendments</h2>
                            <div className="text-sm lg:text-base">
                                VersiMarket reserves the right to modify or update these Terms at any time without prior notice. Significant changes will be posted on the Platform, and your continued use of the Platform after such modifications signifies your acceptance of the updated Terms.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">4. Eligibility</h2>
                            <div className="text-sm lg:text-base">
                                You must be at least 18 years of age (or the age of majority in your jurisdiction) to use the Platform. By using the Platform, you represent and warrant that you have the legal capacity to enter into these Terms.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">5. Account Registration and Security</h2>
                            <div className="text-sm lg:text-base">
                                <ul className="pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Registration</span>: Certain features of the Platform may require you to create an account. You agree to provide accurate, current, and complete information during the registration process and to update this information as necessary.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Account Security</span>: Ysou are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you suspect any unauthorized use of your account.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">6. Platform Services</h2>
                            <div className="text-sm lg:text-base">
                                <div>VersiMarket is designed to facilitate:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Freelance Matchmaking</span>: Helping freelancers and consultants find projects and work opportunities.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Business Listings</span>: Allowing businesses to list their services, gain visibility, and attract talent and clients.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Talent Acquisition</span>: Enabling businesses to search for and hire skilled professionals.</li>
                                </ul>
                                <div>We provide tools for communication, payment processing, and service management. However, we are not a party to any transactions or agreements made directly between users.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">7. User Conduct and Responsibilities</h2>
                            <div className="text-sm lg:text-base">
                                <div>You agree to use the Platform only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit the use and enjoyment of the Platform by any third party. Prohibited conduct includes, but is not limited to:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li>Posting or transmitting any content that is defamatory, offensive, obscene, or otherwise objectionable.</li>
                                    <li>Engaging in fraudulent, deceptive, or manipulative practices.</li>
                                    <li>Disrupting or interfering with the operation or security of the Platform.</li>
                                </ul>
                                <div>You are solely responsible for all content you upload, post, or otherwise transmit through the Platform.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">8. Payment and Fees</h2>
                            <div className="text-sm lg:text-base">
                                <ul className="pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Fees</span>: Some services on the Platform may require payment. Any fees associated with such services will be clearly indicated.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Payment Information</span>: You agree to provide valid payment information when required and to update it as necessary. All fees are non-refundable unless expressly stated otherwise.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Third-Party Payment Processors</span>: Payments processed through third-party services are subject to the terms and conditions of those service providers.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">9. Intellectual Property</h2>
                            <div className="text-sm lg:text-base">
                                <ul className="pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Ownership</span>: All content, trademarks, logos, and other intellectual property on the Platform are owned by VersiMarket or its licensors and are protected by intellectual property laws.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Limited License</span>: You are granted a limited, non-exclusive, non-transferable license to access and use the Platform for your personal or business purposes in accordance with these Terms.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Restrictions</span>: You may not reproduce, distribute, modify, or create derivative works from any content on the Platform without the prior written consent of VersiMarket.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">10. Third-Party Links and Content</h2>
                            <div className="text-sm lg:text-base">
                                The Platform may contain links to third-party websites, services, or resources. VersiMarket does not control and is not responsible for the content or practices of these third parties. Your use of any third-party websites or services is governed by their respective terms and conditions.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">11. Disclaimers</h2>
                            <div className="text-sm lg:text-base">
                                <ul className="pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">"As Is" Basis</span>: The Platform and all content, materials, and services provided are offered on an "as is" and "as available" basis without any warranties, express or implied.</li>
                                    <li><span className="text-versich-light-blue font-semibold">No Guarantee</span>: VersiMarket does not guarantee the accuracy, reliability, or completeness of any content on the Platform. Use of the Platform is at your own risk.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Service Interruptions</span>: We do not guarantee uninterrupted or error-free access to the Platform and shall not be liable for any interruptions or errors.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">12. Limitation of Liability</h2>
                            <div className="text-sm lg:text-base">
                                To the fullest extent permitted by law, VersiMarket and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, consequential, or punitive damages arising out of your use of, or inability to use, the Platform. In no event shall our total liability exceed the amount you have paid (if any) to use the Platform during the period in which the claim arises.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">13. Indemnification</h2>
                            <div className="text-sm lg:text-base">
                                You agree to indemnify, defend, and hold harmless VersiMarket, its parent company Versich, and their respective affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses (including reasonable legal fees) arising out of or in any way connected with your access to or use of the Platform, your violation of these Terms, or your infringement of any rights of a third party.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">14. Termination</h2>
                            <div className="text-sm lg:text-base">
                                <ul className="pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">By VersiMarket</span>: We reserve the right to suspend or terminate your account and access to the Platform at any time, with or without notice, for any conduct that violates these Terms or is harmful to other users or the Platform.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Effect of Termination</span>: Upon termination, all licenses and rights granted to you under these Terms will immediately cease, and you must promptly discontinue all use of the Platform.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">15. Governing Law and Jurisdiction</h2>
                            <div className="text-sm lg:text-base">
                                These Terms shall be governed by and construed in accordance with the laws of England and Wales, without regard to its conflict of law principles. Any disputes arising from or related to these Terms or the use of the Platform shall be subject to the exclusive jurisdiction of the courts of England and Wales.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">16. Severability</h2>
                            <div className="text-sm lg:text-base">
                                If any provision of these Terms is found to be invalid or unenforceable, that provision shall be enforced to the maximum extent possible and the remaining provisions shall remain in full force and effect.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">17. Waiver</h2>
                            <div className="text-sm lg:text-base">
                                The failure of VersiMarket to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">18. Changes to the Platform and Terms</h2>
                            <div className="text-sm lg:text-base">
                                VersiMarket reserves the right to modify, suspend, or discontinue, temporarily or permanently, the Platform (or any part thereof) at any time without liability. We may also update these Terms periodically; it is your responsibility to review them for any changes.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">19. Contact Information</h2>
                            <div className="text-sm lg:text-base">
                                <div>If you have any questions or concerns about these Terms, please contact us at:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Email</span>: Hello@versimarket.com</li>
                                    <li><span className="text-versich-light-blue font-semibold">Postal Address</span>:<br />
                                        VersiMarket<br />
                                        Broad House, Imperial Drive,<br />
                                        HA2 7BL London,<br />
                                        United Kingdom
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 pt-2 sm:pt-4 border-t border-versich-light-blue/20">
                        <p className="text-versich-dark-blue text-sm lg:text-base font-semibold">By using the VersiMarket Platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TermsAndConditions
