import { useParams } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Meta from "../../components/Meta"
import SectionLoader from "../../components/loader/SectionLoader"
import JobDetailsWithProposalForm from "../../components/Jobs/JobDetailsWithProposalForm/JobDetailsWithProposalForm"
import { getPublicRequestAsync } from "../../slices/request"

const metadata = {
    title: "Submit your proposal",
    description: "Submit your proposal to get hired by clients",
}

const JobProposalSubmit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [request, setRequest] = useState(undefined)

    // Load request details on mount (or when id changes)
    useEffect(() => {
        const loadRequest = () => {
            dispatch(getPublicRequestAsync({ id, params: "?purpose=proposal" }))
                .unwrap()
                .then(data => {
                    setRequest(data.payload)
                })
                .catch(() => setRequest(null))
        }
        loadRequest()
    }, [dispatch, id])

    // Display error page if 'request' is null
    if (request === null) {
        return <ErrorPage status={404} />
    }
    return (<>
        <Meta data={metadata} />
        {request ? (
            <div className="text-versich-darktext-color text-left px-8 md:px-16 xl:px-28 pt-[80px] pb-[60px]">
                <div className="max_screen_w">
                    <h1 className="text-xl sm:text-2xl lg:text-3xl font-semibold">Submit Your Proposal</h1>
                    <JobDetailsWithProposalForm request={request} />
                </div>
            </div>
        ) : (
            <SectionLoader className="h-[450px]" />
        )}
    </>)
}
export default JobProposalSubmit