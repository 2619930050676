import { useCallback, memo } from "react"
import { FaRegEye } from "react-icons/fa6"

import useFormattedDate from "../../../hooks/useFormattedDate"

const ProposalCard = ({ data, viewProposal, viewMessage }) => {
    const { request: { details }, read, conversation, createdAt, uid } = data

    // Memoize date formatting to avoid recomputation on each render
    const { localDate: createdAtLocalDate, distance: createdAtDistance } = useFormattedDate(createdAt)

    // Callback to view the proposal
    const handleViewProposal = useCallback(() => {
        viewProposal(uid)
    }, [uid, viewProposal])

    // Callback for message button
    const handleMessageClick = useCallback(() => {
        viewMessage(conversation)
    }, [conversation, viewMessage])
    return (
        <div className="bg-white hover:bg-majorelle-blue/[0.01] max-smaller:py-6 max-md:px-5 p-8 border border-versich-light-blue/20 rounded-2xl group">
            <div className="flex flex-col lg:flex-row gap-y-2.5 lg:gap-10">
                <div className="flex-grow">
                    <h3 className="text-versich-darktext-color text-lg lg:text-xl font-bold">
                        <button
                            type="button"
                            className="group-hover:text-versich-light-blue inline-block hover:underline underline-offset-2 decoration-majorelle-blue/20 transition-colors cursor-pointer"
                            onClick={handleViewProposal}
                        >
                            {details.title}
                        </button>
                    </h3>
                    {read &&
                        <div className="text-versich-lighter-blue text-xs md:text-sm inline-flex items-center gap-1 md:gap-1.5">
                            <FaRegEye aria-hidden="true" />
                            <span>Viewed by client</span>
                        </div>
                    }
                    <div className="text-xs md:text-sm flex flex-col mt-0.5 md:mt-1">
                        <span>Initiated {createdAtLocalDate}</span>
                        <span>{createdAtDistance}</span>
                    </div>
                </div>
                <div className="lg:min-w-[160px] max-lg:mt-3 rounded-xl">
                    <div className="text-sm flex flex-wrap lg:flex-col lg:justify-end gap-2.5">
                        {conversation
                            ? <>
                                <button type="button" className="accent_btn" onClick={handleMessageClick} aria-label="View Messages">
                                    Message
                                </button>
                                <button type="button" className="secondary_btn" onClick={handleViewProposal} aria-label="View Proposal">
                                    View Proposal
                                </button>
                            </>
                            : <button type="button" className="accent_btn" onClick={handleViewProposal} aria-label="View Proposal">
                                View Proposal
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(ProposalCard)