import { FaSquareCheck } from "react-icons/fa6"
import { BiSquareRounded } from "react-icons/bi"

const BooleanFieldInput = ({ name, label, onChange, defaultValue }) => {
    return (
        <div className="flex justify-start">
            <label className="inline-flex items-center px-1 py-2 md:p-2 cursor-pointer group">
                <input
                    id={name}
                    className="appearance-none"
                    name={name}
                    type="checkbox"
                    onChange={onChange}
                />
                {defaultValue
                    ? <FaSquareCheck className="fill-versich-blue w-5 h-5" />
                    : <BiSquareRounded className="text-gray-300 group-hover:fill-versich-blue w-5 h-5 transition-colors" />
                }
                <span className="text-versich-dark-blue text-base font-medium ps-2">{label}</span>
            </label>
        </div>
    )
}
export default BooleanFieldInput
