import { useParams, useNavigate, useLocation } from "react-router-dom"
import { useState, useEffect, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { BeatLoader } from "react-spinners"

import useClientMessageHandler from "../../../../hooks/useClientMessageHandler"
import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import Meta from "../../../../components/Meta"
import chevronRightIcon from "../../../../assets/svgs/chevron-right.svg"
import noMessages from "../../../../assets/images/no-messages.svg"
import ContactProfessionalViaProposalDialog from "../../../../components/Dashboard/proposals/ContactProfessionalViaProposalDialog"
import RequestCard from "./RequestCard"
import MessageCard from "./MessageCard"
import ProposalCard from "./ProposalCard"
import { CLIENT, requestsPath } from "../../../../assets/constants"
import { toProposalDetails } from "../../../../assets/path"
import { getRequestAsync } from "../../../../slices/client/service"
import { getClientRequestInvitesAsync } from "../../../../slices/message"
import { getClientRequestProposalsAsync } from "../../../../slices/proposal"

const RequestDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname, search } = useLocation()

    const { user } = useSelector(state => state.auth)
    const isClient = user.accountType === CLIENT

    // States
    const [request, setRequest] = useState(undefined)
    const [messages, setMessages] = useState([])
    const [proposals, setProposals] = useState([])
    const [proposalId, setProposalId] = useState("")

    // Navigation callback for the "Back" button
    const handleBackNavigation = useCallback(() => {
        navigate(requestsPath)
    }, [navigate])

    // Memoized callback to view proposal details
    const viewProposal = useCallback((uid) => {
        const path = window.encodeURIComponent(pathname + search)
        toProposalDetails(uid, navigate, { "source_path": path })
    }, [navigate])

    // Get the client messaging handler
    const clientMessageHandler = useClientMessageHandler(navigate)

    // Memoized callback to view/send message
    const handleMessageClick = useCallback((conversationID, proposalUID, event) => {
        setProposalId(proposalUID)
        clientMessageHandler(conversationID, event)
    }, [clientMessageHandler])

    // Load request details on mount (or when id changes)
    useEffect(() => {
        const loadRequest = () => {
            dispatch(getRequestAsync(id))
                .unwrap()
                .then(data => {
                    setRequest(data.payload)
                    if (!isClient) {
                        return
                    }
                    // Invites
                    dispatch(getClientRequestInvitesAsync(id))
                        .unwrap()
                        .then(data => {
                            setMessages(data.payload)
                        })
                        .catch(() => { })

                    // Proposals
                    dispatch(getClientRequestProposalsAsync(id))
                        .unwrap()
                        .then(data => {
                            setProposals(data.payload)
                        })
                        .catch(() => { })
                })
                .catch(() => setRequest(null))
        }
        loadRequest()
    }, [dispatch, id])

    return (
        request === null
            ? <ErrorPage status={404} />
            : <DashboardLayout activePage="My Requests">
                <div className="flex flex-col gap-y-5 h-full">
                    <Meta data={{
                        title: request ? request.details.title : "VersiMarket | Request Details Page",
                        description: "Detailed view of client request",
                    }} />

                    {/* Back Button */}
                    <div className="space-y-1.5">
                        <div className="flex justify-start pt-1">
                            <button className="inline-flex items-center gap-0.5" onClick={handleBackNavigation}>
                                <img src={chevronRightIcon} alt="" />
                                <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                            </button>
                        </div>
                    </div>

                    {/* Request Card */}
                    <div className="bg-white shadow-formLight rounded-xl">
                        {request
                            ? <RequestCard data={request} />
                            : <div className="w-full h-80 flex justify-center items-center">
                                <BeatLoader loading={true} color="#1D88ED" size={10} />
                            </div>
                        }
                    </div>

                    {/* Client-specific views */}
                    {isClient && <>
                        <div className="bg-white shadow-formLight rounded-xl">
                            <div className="p-5 md:p-10 space-y-4">
                                <div className="text-start space-y-5">
                                    <div className="flex pb-3">
                                        <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Contacted Experts</p>
                                    </div>
                                    {messages && messages.length > 0 ? (
                                        <div className="text-start space-y-5">
                                            {messages.map((message, index) => (
                                                <MessageCard data={message} key={index} />
                                            ))}
                                        </div>
                                    ) : (<>
                                        <div className="flex justify-center !mt-0">
                                            <img className="w-[300px]" src={noMessages} alt="no messages" />
                                        </div>
                                        <p className="text-center text-sm lg:text-base font-semibold text-versich-dark-blue !-mt-5">There are no experts contacted yet.</p>
                                    </>)}
                                </div>
                            </div>
                        </div>

                        {/* Proposals */}
                        {proposals && proposals.length > 0 &&
                            <div className="bg-white shadow-formLight rounded-xl">
                                <div className="p-5 md:p-10 space-y-4">
                                    <div className="text-start space-y-5">
                                        <div className="flex pb-3">
                                            <p className="text-lg lg:text-xl text-versich-dark-blue font-semibold">Proposals</p>
                                        </div>
                                        <div className="grid md:grid-cols-2 mt-5 gap-5">
                                            {proposals.map((data, index) => (
                                                <ProposalCard
                                                    data={data}
                                                    viewProposal={viewProposal}
                                                    handleMessageClick={handleMessageClick}
                                                    key={index}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <ContactProfessionalViaProposalDialog proposalId={proposalId} />
                            </div>
                        }
                    </>}
                    <div className="w-full pb-[1px]" />
                </div>
            </DashboardLayout>
    )
}
export default RequestDetails