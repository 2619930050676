import { useSelector } from "react-redux"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

import DashboardLayout from "../../../components/Dashboard/DashboardLayout"
import versiGemIcon from "../../../assets/images/versigem2.0.png"
import PurchasedVersiGems from "../../../components/Dashboard/freelancer/PurchasedVersiGems"
import ShareProfileButton from "../../../components/Dashboard/ShareProfileButton"
import CompleteProfile from "../../../components/Dashboard/CompleteProfile"
import Metrics from "../../../components/Dashboard/business/Metrics"
import MyServices from "../../../components/Dashboard/freelancer/MyServices"
import About from "../../../components/Dashboard/freelancer/About"
import Experience from "../../../components/Dashboard/freelancer/Experience"
import Education from "../../../components/Dashboard/freelancer/Education"
import Languages from "../../../components/Dashboard/freelancer/Languages"
import Reviews from "../../../components/Dashboard/business/Reviews/Reviews"
import { buyVersiGemsPath } from "../../../assets/constants"
import { getFreelancerProfilePath } from "../../../assets/path"

const FreelancerDashboard = () => {
    const { user } = useSelector(state => state.auth)
    const [notifications, setNotifications] = useState([])

    const navigate = useNavigate()
    const profilePath = getFreelancerProfilePath(user.about.profileId)
    return (
        <DashboardLayout activePage="Dashboard">
            {/* Render puchased VersiGems */}
            <PurchasedVersiGems totalVersiGems={user.versigems} setNotifications={setNotifications} />

            {/* All notification */}
            {notifications.length > 0 && notifications.map((notify, index) => (
                <div key={index}>
                    {notify}
                </div>
            ))}
            <div className="flex max-sm:flex-col sm:items-center gap-2.5">
                <div className="text-versich-dark-blue text-start flex-1 space-y-[2px]">
                    <div className="text-lg lg:text-xl font-semibold">Hello {user.about.firstName}!</div>
                    <div className="text-sm">Dashboard Overview</div>
                </div>
                <div className="font-semibold flex flex-wrap gap-2.5 py-1">
                    <button className="bg-white hover:bg-sky-50/50 text-versich-light-blue min-h-12 inline-flex items-center gap-2 border-2 border-sky-300 py-0.5 lg:py-1 pl-4 px-6 rounded-[10px]" type="button" onClick={() => navigate(buyVersiGemsPath)}>
                        <img className="w-[23px] h-[23px]" src={versiGemIcon} alt="" />
                        <div>
                            <span className="text-xs">VersiGems</span>
                            <p className="text-versich-dark-blue text-xs font-semibold text-left">{user.versigems ? user.versigems : <span className="text-red-500">0</span>}</p>
                        </div>
                    </button>
                    <ShareProfileButton profilePath={profilePath} />
                </div>
            </div>
            <CompleteProfile />
            <Metrics data={user.metrics} />
            <div className="grid md:grid-cols-2 gap-x-4 gap-y-3">
                <MyServices data={user} />
                <About data={user.about} />
                {user.experience && <Experience data={user.experience} />}
                <div className="space-y-3">
                    {user.education && <Education data={user.education} />}
                    {user.languages && <Languages data={user.languages} />}
                </div>
            </div>
            <Reviews />
        </DashboardLayout>
    )
}
export default FreelancerDashboard