import { useSelector } from "react-redux"

import ClientDirectContactDialog from "./ClientDirectContactDialog"
import GuestDirectContactDialog from "./GuestDirectContactDialog"

export const directContactDialog = "directcontact-dialog"

const DirectContactDialog = ({ userId, setContacted }) => {
    const { user } = useSelector((state) => state.auth)
    return (user
        ? <ClientDirectContactDialog
            userId={userId}
            setContacted={setContacted}
        />
        : <GuestDirectContactDialog
            userId={userId}
            setContacted={setContacted}
        />
    )
}
export default DirectContactDialog