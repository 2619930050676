import { useNavigate } from "react-router-dom"

import Avatar from "../../Avatars/Avatar"
import { toConversationDetails } from "../../../assets/path"

const Conversation = ({ data, user, selectedConversation, handleClick }) => {
    const otherUser = data.client.accountType === user.accountType ? data.participant : data.client

    return (
        <div
            className={"cursor-pointer rounded-xl group "
                + (selectedConversation ? "bg-[#F0F2FF]/60 " : "hover:bg-[#F0F2FF]/60 ")
            }
            onClick={handleClick}
            key={data.id}
        >
            <div className="flex items-center gap-2.5 sm401:gap-3.5 p-4">
                <Avatar
                    user={otherUser}
                    fullName={otherUser.details.name}
                    ringClass={"w-10 h-10 sm401:h-12 sm401:w-12 border "
                        + (selectedConversation ? "border-versich-darktext-color/10 " : "border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10 ")
                    }
                    initialClass="text-sm lg:text-base"
                />
                <div className="flex-1 space-y-0.5 overflow-hidden">
                    <h3 className="font-semibold">{otherUser.details.name}</h3>
                    {data.request
                        ? <p className="text-gray-600 text-xs truncate">{data.request.title}</p>
                        : data.conversationType && <p className="text-gray-600 text-xs truncate">{data.conversationType}</p>
                    }
                    {data.lastMessage && (
                        <p className="text-sm truncate">
                            {data.lastMessage.accountType === user.accountType ? "You: " : ""}
                            {data.lastMessage.shortContent}
                        </p>
                    )}
                </div>
            </div>
            <div className="bg-versich-light-blue/10 w-[95%] lg900:w-[85%] h-[1px] mx-auto" />
        </div>
    )
}

const ConversationList = ({ className, user, conversations, selectedConversationId }) => {
    const navigate = useNavigate()
    return (
        <div className={"w-full lg900:w-2/5 lg900:max-w-[350px] h-full p-2 sm401:p-3.5 shadow-ring2 rounded-lg "
            + (className ? className : "")
        }>
            <div className="flex-grow max-h-[65vh] overflow-y-auto">
                {conversations.map(conversation => (
                    <Conversation
                        data={conversation}
                        user={user}
                        selectedConversation={selectedConversationId == conversation.id}
                        handleClick={() => toConversationDetails(conversation.id, navigate)}
                        key={conversation.id}
                    />
                ))}
            </div>
        </div>
    )
}
export default ConversationList
