import Meta from "../../components/Meta"

const metadata = {
    title: "Cookies Policy | Your Data Protection",
    description: "Understand how we collect, use, and protect your personal information. Review our Cookies Policy for detailed information.",
    url: `${process.env.REACT_APP_CLIENT_URL}/legal/cookies-policy/`
}

const CookiesPolicy = () => {
    return (
        <div>
            <Meta data={metadata} />
            <div className="bg-white py-10 md:py-14 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <div className="pb-3 sm:pb-6 border-b border-versich-light-blue/10">
                        <h1 className="text-versich-darktext-color text-2xl sm:text-3xl font-bold">VersiMarket Cookies Policy</h1>
                        <h2 className="text-versich-dark-blue text-sm smaller:text-base font-semibold text-left pt-8 sm:pt-10">Effective Date: February 3rd 2025</h2>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 space-y-12 sm:space-y-16">
                        <div className="text-sm lg:text-base">
                            This Cookies Policy explains how VersiMarket ("we", "us", "our") uses cookies and similar technologies to recognize you when you visit our website, use our mobile applications, or interact with our online services (collectively, the "Platform"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">1. What Are Cookies?</h2>
                            <div className="text-sm lg:text-base space-y-3 sm:space-y-5">
                                <div>Cookies are small text files that are placed on your computer, smartphone, or other devices when you visit a website. Cookies are widely used by website owners to make their websites work, or to work more efficiently, as well as to provide reporting information.</div>
                                <div>Cookies set by the website owner (in this case, VersiMarket) are called "first party cookies." Cookies set by parties other than the website owner are called "third party cookies." Third party cookies enable third party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.</div>
                            </div>
                        </div>
                        <div className="space-y-6 sm:space-y-10">
                            <div>
                                <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">2. How VersiMarket Uses Cookies</h2>
                                <div className="text-sm lg:text-base">We use cookies for several reasons. Some cookies are required for technical reasons in order for our Platform to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Platform. Third parties serve cookies through our Platform for advertising, analytics, and other purposes. This is described in more detail below.</div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">Purposes for Using Cookies:</h2>
                                <div className="text-sm lg:text-base">
                                    <ul className="pl-6 sm:pl-8 list-disc">
                                        <li><span className="text-versich-light-blue font-semibold">Essential Functionality</span>: To ensure the proper functioning of the Platform, including security, network management, and accessibility.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Performance and Analytics</span>: To understand how our Platform is being used, to help us improve its performance and user experience, and to monitor aggregate website usage.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Functional Cookies</span>: To remember choices you make (such as your username, language, or region) and to provide enhanced, more personal features.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Advertising and Targeting</span>: To deliver relevant advertising to you both on our Platform and on third-party sites, and to limit the number of times you see an advertisement.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-6 sm:space-y-10">
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">3. Types of Cookies We Use</h2>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.1. Essential Cookies</h2>
                                <div className="text-sm lg:text-base">
                                    These cookies are necessary for the operation of our Platform. They include, for example, cookies that enable you to log into secure areas of our website or make use of e commerce features.
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.2. Performance and Analytics Cookies</h2>
                                <div className="text-sm lg:text-base">
                                    These cookies collect information about how you use our Platform, such as which pages you visit most often and if you receive error messages from certain pages. These cookies do not collect information that identifies you personally; all the information these cookies collect is aggregated and therefore anonymous. It is only used to improve how our Platform works.
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.3. Functional Cookies</h2>
                                <div className="text-sm lg:text-base">
                                    These cookies allow our Platform to remember choices you make (such as your username, language, or region) and provide enhanced, more personal features.
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.4. Advertising and Targeting Cookies</h2>
                                <div className="text-sm lg:text-base">
                                    These cookies are used to deliver advertisements more relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of advertising campaigns.
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.5. Third Party Cookies</h2>
                                <div className="text-sm lg:text-base">
                                    In addition to our own cookies, we may also use various third party cookies to report usage statistics, deliver advertisements, and offer other functionalities. These cookies are subject to the privacy policies of the respective third parties.
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">4. How to Manage Cookies</h2>
                            <div className="text-sm lg:text-base space-y-3 sm:space-y-5">
                                <div>Most web browsers allow you to control cookies through your browser settings. You can set your browser to refuse cookies or to alert you when cookies are being sent. However, if you disable cookies, some parts of our Platform may not work properly or may be less secure.</div>
                                <div>For more detailed information on how to manage cookies for your specific browser, please refer to the help menu of your browser. You can also visit <a className="text-brandeis-blue underline underline-offset-2" href="https://www.aboutcookies.org" target="_blank">www.aboutcookies.org</a> for general guidance on managing cookies.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">5. Changes to This Cookies Policy</h2>
                            <div className="text-sm lg:text-base">
                                We may update this Cookies Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to periodically review this page for the latest information on our use of cookies.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">6. Contact Us</h2>
                            <div className="text-sm lg:text-base">
                                <div>If you have any questions or concerns about our use of cookies or this Cookies Policy, please contact us at:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Email</span>: Hello@versimarket.com</li>
                                    <li><span className="text-versich-light-blue font-semibold">Postal Address</span>:<br />
                                        VersiMarket<br />
                                        Broad House, Imperial Drive,<br />
                                        HA2 7BL London,<br />
                                        United Kingdom
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 pt-2 sm:pt-4 border-t border-versich-light-blue/20">
                        <p className="text-versich-dark-blue text-sm lg:text-base font-semibold">By continuing to use our Platform, you consent to our use of cookies as described in this Cookies Policy.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CookiesPolicy
