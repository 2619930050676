import { useState, useEffect, useRef } from "react"

import TextFieldInput from "../../../Services/TextFieldInput"
import { TOASTTIMER } from "../../../../assets/constants"

const MessageInput = ({ onSendMessage, onTyping }) => {
    const [content, setContent] = useState("")
    const [isTyping, setIsTyping] = useState(false)
    const typingTimeoutRef = useRef(null)

    // Track typing
    const handleChange = (e) => {
        setContent(e.target.value)
        onTyping()
        if (!isTyping) {
            setIsTyping(true)

        } else {
            clearTimeout(typingTimeoutRef.current)
        }
        typingTimeoutRef.current = setTimeout(() => {
            setIsTyping(false)
        }, TOASTTIMER)
    }

    // Send message
    const handleSubmit = (e) => {
        e.preventDefault()
        if (content.trim()) {
            onSendMessage(content)
            setContent("")
            setIsTyping(false)
            clearTimeout(typingTimeoutRef.current)
        }
    }

    useEffect(() => {
        return () => {
            clearTimeout(typingTimeoutRef.current)
        }
    }, [])
    return (
        <form onSubmit={handleSubmit} className="flex gap-2 pt-2 sm401:pt-4">
            <div className="flex-grow">
                <TextFieldInput
                    name="message"
                    placeholder="Type a message"
                    onChange={handleChange}
                    defaultValue={content}
                />
            </div>
            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2 px-4 rounded-lg disabled:cursor-not-allowed" type="submit">Send</button>
        </form>
    )
}
export default MessageInput
