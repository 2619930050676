import Meta from "../../components/Meta"
import Hero from "./components/Hero"
import UserActionsLayout from "./components/UserActionsLayout"
import FindTalentByCategory from "./components/FindTalentByCategory"
import BrowseTalentByCategory from "./components/BrowseTalentByCategory"
import BusinessListings from "./components/BusinessListings"
import GetStartedBanner from "./components/GetStartedBanner"

const metadata = {
    title: "VersiMarket",
    description: "Web-based Services Marketplace for Web Design, Development, Mobile Apps and Data Analytics",
    url: `${process.env.REACT_APP_CLIENT_URL}/`
}

const Home = () => {
    return (
        <div>
            <Meta data={metadata} />
            <Hero />
            <UserActionsLayout />
            <FindTalentByCategory />
            <BrowseTalentByCategory />
            <BusinessListings />
            <GetStartedBanner />
        </div>
    )
}
export default Home
