import { useSelector } from "react-redux"

import AccountSettingsLayout from "../../../components/Dashboard/accountSettings/AccountSettingsLayout"
import Profile from "../../../components/Dashboard/accountSettings/Profile"
import BusinessDetails from "../../../components/Dashboard/accountSettings/business/BusinessDetails"
import PersonalContact from "../../../components/Dashboard/accountSettings/business/PersonalContact"
import AboutBusiness from "../../../components/Dashboard/accountSettings/business/AboutBusiness"
import BusinessLocation from "../../../components/Dashboard/accountSettings/business/BusinessLocation"
import SocialProfiles from "../../../components/Dashboard/accountSettings/business/SocialProfiles"
import Competitors from "../../../components/Dashboard/accountSettings/business/Competitors"
import AccountAndSecurity from "../../../components/Dashboard/accountSettings/accountAndSecurity/AccountAndSecurity"

const BusinessAccountSettings = ({ metadata }) => {
    const { user } = useSelector((state) => state.auth)
    return (
        <AccountSettingsLayout metadata={metadata}>
            <Profile />
            <div className="md:col-span-3 xl:col-span-2 space-y-5">
                <BusinessDetails />
                <PersonalContact />
                <AboutBusiness />
                <BusinessLocation />
                <SocialProfiles />
                <Competitors />
                <AccountAndSecurity isSocial={user.social} />
            </div>
        </AccountSettingsLayout>
    )
}
export default BusinessAccountSettings