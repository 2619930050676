import { getInitials } from "../../utils"

const Avatar = ({ user, fullName, ringClass, initialClass }) => {
    const initials = getInitials(fullName)
    return (
        <div className={"bg-[#F6F9FF] rounded-full "
            + (ringClass ? ringClass : "")
        }>
            {user.profile?.image
                ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                : user.social?.image
                    ? <img className="w-full h-full object-cover rounded-full" src={user.social.image} alt="" />
                    : <div className={"text-versich-darktext-color font-semibold flex justify-center items-center w-full h-full rounded-full "
                        + (initialClass ? initialClass : "")
                    }>
                        {initials}
                    </div>
            }
        </div>
    )
}
export default Avatar