import briefcaseIcon from "../../../assets/icons/dashboard/briefcase.svg"

const Experience = ({ data }) => {
    return (
        <div className="bg-white text-start p-4 sm:p-5 shadow-card4 rounded-lg">
            <div className="text-versich-dark-blue font-bold pb-4 border-b border-[#EDF0F5]">Experience</div>
            <div className="">
                {data.length > 0 && data.map((experience, index) => (
                    <div className="text-versich-dark-blue text-sm my-5 pt-5 first-of-type:pt-0 border-t-2 first-of-type:border-t-0 border-[#EDF0F5]/50" key={index}>
                        <div className="flex gap-[26px]">
                            <div>
                                <div className="bg-brandeis-blue/[0.08] inline-flex items-center justify-center min-w-[43px] min-h-[43px] rounded-[10px]">
                                    <img className="w-6 h-6" src={briefcaseIcon} alt="" />
                                </div>
                            </div>
                            <div className="space-y-1.5">
                                <p className="font-semibold pb-0.5">{experience.title}</p>
                                <p>{experience.company}</p>
                                <p>
                                    <span>{experience.startMonthName}, {experience.startYear}</span>
                                    {experience.present
                                        ? <span> - Present</span>
                                        : <span> - {experience.endMonthName}, {experience.endYear}</span>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default Experience