import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useState } from "react"
import { BiSolidLock } from "react-icons/bi"

import Avatar from "./Avatar"
import PremiumButton from "../../../Buttons/PremiumButton"
import UnlockButton from "../../../Buttons/UnlockButton"
import { openDialog } from "../../../DialogBox"
import UnlockWithVersiGemsDialog, { unlockWithVersiGemsDialog } from "../../freelancer/UnlockWithVersiGemsDialog"
import { unlockFreelancerRequestInviteRestrictionAsync } from "../../../../slices/message"
import {
    BUSINESS, FREELANCER,
    businessPricingPath,
    COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, TOASTTIMER, freelancerUnlockOptions,
} from "../../../../assets/constants"

const BusinessLock = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const upgradeToPremium = () => {
        const path = window.encodeURIComponent(location.pathname + location.search)
        window.sessionStorage.setItem(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, path)
        navigate(businessPricingPath)
    }
    return (
        <PremiumButton
            className="px-2 lg:px-3 py-1.5 md:py-2.5"
            label="Upgrade to Reveal"
            onClick={upgradeToPremium}
        />
    )
}

const FreelancerLock = ({ conversation }) => {
    const [requiredVerisGems, setRequiredVersiGems] = useState()

    // Handle 'unlock' button click
    const handleUnlockClick = (e) => {
        openDialog(e)
        setRequiredVersiGems({
            ...freelancerUnlockOptions.message,
            value: conversation.restriction.gems,
            title: "New Request",
            id: conversation.restriction.restriction_id
        })
    }

    // Unlock action
    const unlockRequestInviteAsync = (id) => {
        return unlockFreelancerRequestInviteRestrictionAsync(id)
    }
    return (<>
        <UnlockWithVersiGemsDialog
            requiredVerisGems={requiredVerisGems}
            unlockAsync={unlockRequestInviteAsync}
            triggerAfterSuccess={() => { }}
        />
        <UnlockButton
            className="px-2 lg:px-3 py-1.5 md:py-2.5"
            label="Unlock Message"
            onClick={handleUnlockClick}
            dataParentId={unlockWithVersiGemsDialog}
        />
    </>)
}

const LockedDetails = ({ conversation }) => {
    const { user } = useSelector(state => state.auth)
    const isBusiness = user.accountType === BUSINESS
    const isFreelancer = user.accountType === FREELANCER

    const otherUser = conversation.client.accountType === user.accountType ? conversation.participant : conversation.client
    return (<>
        <div className="grid sm:grid-cols-[auto_1fr] items-center gap-4 mb-4 sm401:mb-6">
            <div className="flex max-sm:flex-wrap items-center gap-2 sm401:gap-2.5">
                <Avatar user={otherUser} />
                <h2 className="text-lg md:text-xl font-semibold">
                    <span className="text-versich-dark-blue">{otherUser.details.name}</span>
                </h2>
            </div>
            <div className="ml-auto">
                {isBusiness && <BusinessLock />}
                {isFreelancer && <FreelancerLock conversation={conversation} />}
            </div>
        </div>
        <div className="bg-versich-light-blue/[0.075] w-full h-[1px] mx-auto" />
        <div className="w-full h-full min-h-64 px-2 py-4 sm401:px-4 sm401:py-6 rounded-md relative">
            <div className="bg-[#F0F2FF]/60 w-full h-full blur-[8px] rounded-xl" />
            <div className="flex items-center justify-center w-full h-full absolute inset-0">
                <BiSolidLock className="text-versich-light-blue w-8 h-8 sm:w-10 sm:h-10 xl:w-12 xl:h-12" />
            </div>
        </div>
    </>)
}
export default LockedDetails