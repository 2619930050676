import { useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import chevronRightIcon from "../../../../assets/svgs/chevron-right.svg"
import curvyArrowIcon from "../../../../assets/svgs/full-curvyarrow-right.svg"
import emptyBoxIcon from "../../../../assets/images/empty-box.png"
import ServicesList from "../../../../assets/ServicesList"
import { dashboardPath } from "../../../../assets/constants"
import { getServiceProjectsPath } from "../../../../assets/path"

const metadata = {
    title: "My Services",
    url: `${process.env.REACT_APP_CLIENT_URL}/dashboard/services/`
}

const ServiceTile = ({ data }) => {

    // Set Icon & Color
    const [service, setService] = useState(null)
    useEffect(() => {
        const services = ServicesList.filter(s => s.uid === data.uid)
        if (services.length > 0) {
            setService(services[0])
        }
    }, [data])

    const serviceProjectsPath = getServiceProjectsPath(data.uid)
    return (
        service &&
        <Link to={serviceProjectsPath} className="bg-white text-center flex flex-col items-center justify-center gap-3 smaller:gap-5 sm401:aspect-[4/3] py-4 sm:py-6 px-6 sm:px-8 border border-[#F5F5F5] hover:border-blue-100 hover:shadow-ring rounded-lg">
            <div>
                <img className="min-w-10 h-10 smaller:min-w-[50px] smaller:h-[50px] drop-shadow contrast-150" src={service.icon} alt={data.title} />
            </div>
            <div className="text-xs">
                <h3 className="text-versich-dark-blue font-medium">{data.title}</h3>
                <div className="text-versich-label text-[10px] inline-flex items-center gap-[5px] mt-[5px]">
                    View details <img className="w-3 h-3" src={curvyArrowIcon} alt="" />
                </div>
            </div>
        </Link>
    )
}

const MyServices = () => {
    const { user } = useSelector(state => state.auth)
    const navigate = useNavigate()
    return (
        <DashboardLayout metadata={metadata} activePage="My Services">
            <div className="flex flex-col gap-y-2.5 h-full">
                <div className="space-y-1.5">
                    <div className="flex justify-start pt-1">
                        <button className="inline-flex items-center gap-0.5" onClick={() => navigate(dashboardPath)}>
                            <img src={chevronRightIcon} alt="" />
                            <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                        </button>
                    </div>
                    <div className="text-versich-dark-blue text-start space-y-[2px]">
                        <div className="text-lg lg:text-xl font-semibold">My Services</div>
                        <div className="text-sm">Manage all your services</div>
                    </div>
                </div>
                <div className="bg-white text-start flex-grow py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    {user.primaryService
                        ? <div className="grid grid-cols-1 sm401:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-5">
                            <ServiceTile data={user.primaryService} />
                            {user.otherServices && user.otherServices.length > 0 && user.otherServices.map(service => (
                                <ServiceTile data={service} key={service.uid} />
                            ))}
                        </div>
                        : <div className="h-full flex flex-col items-center justify-center p-4">
                            <img className="w-[180px] sm:w-[220px] opacity-60" src={emptyBoxIcon} alt="no services" />
                            <span className="text-versich-label text-sm">No Services</span>
                        </div>
                    }
                </div>
            </div>
        </DashboardLayout>
    )
}
export default MyServices