import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useState, useEffect } from "react"

import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import FormLoader from "../../../../components/loader/FormLoader"
import CSRFTokenField from "../../../../components/Inputs/CSRFTokenField"
import TextFieldInput from "../../../../components/Services/TextFieldInput"
import RichTextFieldInput from "../../../../components/Services/RichTextFieldInput"
import DropdownFieldInput from "../../../../components/Services/DropdownFieldInput"
import DatePickerFieldInput from "../../../../components/Services/DatePickerFieldInput"
import ImagePreviewInput from "../../../../components/Services/ImagePreviewInput"
import { TOASTTIMER, TOASTTYPE_ERROR } from "../../../../assets/constants"
import { getServiceProjectsPath } from "../../../../assets/path"
import { clearBlobImages } from "../../../../components/Services/utils"
import { freelancerAddServiceProjectAsync, freelancerServiceProjectAllProjectSizeAsync } from "../../../../slices/freelancer/serviceProject"
import toastify from "../../../../utils/toastify"

const metadata = {
    title: "My Services - Add New Project",
}

const AddServiceProject = () => {
    const { id } = useParams()
    const [csrfToken, setCsrfToken] = useState("")
    const [projectName, setProjectName] = useState("")
    const [projectNameError, setProjectNameError] = useState(false)
    const [desc, setDesc] = useState("")
    const [descError, setDescError] = useState(false)
    const [clientName, setClientName] = useState("")
    const [clientNameError, setClientNameError] = useState(false)
    const [projectSizeOptions, setProjectSizeOptions] = useState([])
    const [projectSize, setProjectSize] = useState("")
    const [projectSizeError, setProjectSizeError] = useState(false)
    const [projectStartDate, setProjectStartDate] = useState(null)
    const [projectStartDateError, setProjectStartDateError] = useState(false)
    const [projectEndDate, setProjectEndDate] = useState(null)
    const [projectEndDateError, setProjectEndDateError] = useState(false)
    const [irrelaventDatesError, setIrrelaventDatesError] = useState(false)
    const [images, setImages] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Load dropdown values
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(freelancerServiceProjectAllProjectSizeAsync(`?orderby=id`))
            .unwrap()
            .then((data) => {
                setProjectSizeOptions(data.payload)
            })
    }, [])

    useEffect(() => {
        setProjectNameError(submitted && projectName === "")
        setDescError(submitted && desc === "")
        setClientNameError(submitted && clientName === "")
        setProjectSizeError(submitted && projectSize === "")
        setProjectStartDateError(submitted && projectStartDate === null)
        setProjectEndDateError(submitted && projectEndDate === null)
    }, [submitted])

    const navigate = useNavigate()
    const serviceProjectsPath = getServiceProjectsPath(id)
    const handleSubmit = async (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        // Check for empty values
        setSubmitted(true)
        if (projectName === ""
            || desc === ""
            || clientName === ""
            || projectSize === ""
            || projectStartDate === null
            || projectEndDate === null) {
            setTimeout(() => {
                submitBtn.disabled = false
                setSubmitted(false)
            }, TOASTTIMER)
            return toastify("Fill required fields to continue.", TOASTTYPE_ERROR, submitBtn)
        }

        // Validate project dates
        if (projectEndDate.getTime() < projectStartDate.getTime()) {
            setIrrelaventDatesError(true)
            return setTimeout(() => {
                submitBtn.disabled = false
                setIrrelaventDatesError(false)
                setSubmitted(false)
            }, TOASTTIMER)
        }

        // Post request
        setLoading(true)
        const formData = {
            serviceId: id,
            projectName,
            description: desc,
            clientName,
            projectSizeId: projectSize,
            projectStart: projectStartDate,
            projectEnd: projectEndDate,
            images,
            token: csrfToken
        }
        await dispatch(freelancerAddServiceProjectAsync(formData))
            .unwrap()
            .then(() => navigate(serviceProjectsPath))
            .catch(error => {
                const data = JSON.parse(error?.message)
                return setErrorMsg(data.message)
            })
            .finally(() => {
                setLoading(false)
                submitBtn.disabled = false
                setSubmitted(false)
            })
    }

    // Clear image urls
    useEffect(() => {
        return (
            clearBlobImages()
        )
    }, [])
    return (
        <DashboardLayout metadata={metadata} activePage="My Services">
            <div className="flex flex-col gap-y-2.5 h-full">
                <div className="bg-white text-start flex-grow py-6 px-4 sm401:px-6 shadow-card4 rounded-lg">
                    <form className="max-w-[1920px] mx-auto sm:px-4 md:px-12 xl:px-20 pt-4 pb-8" onSubmit={handleSubmit}>
                        <div className="text-versich-dark-blue text-center py-4">
                            <div className="text-lg lg:text-xl font-semibold">Add New Project</div>
                            <div className="text-sm mt-1">Complete the details below to add a new project</div>
                        </div>
                        <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                        <div className="mt-5">
                            <TextFieldInput
                                name="projectName"
                                inputType="text"
                                placeholder="Enter project title"
                                label="Project Name"
                                onChange={(e) => setProjectName(e.target.value)}
                                defaultValue={projectName}
                                required="Project name is required"
                                error={projectNameError}
                            />
                        </div>
                        <div className="mt-5">
                            <RichTextFieldInput
                                name="description"
                                label="Description"
                                placeholder="What you did in the project"
                                onChange={(value) => setDesc(value)}
                                defaultValue={desc}
                                required="Please enter a description"
                                error={descError}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-x-3 gap-5 md:grid-cols-2 mt-5">
                            <TextFieldInput
                                name="clientName"
                                inputType="text"
                                placeholder="Enter client name"
                                label="Client Name"
                                onChange={(e) => setClientName(e.target.value)}
                                defaultValue={clientName}
                                required="Client name is required"
                                error={clientNameError}
                            />
                            <DropdownFieldInput
                                name="projectSize"
                                label="Project Size"
                                options={projectSizeOptions}
                                value={projectSize}
                                placeholder="Select"
                                onChange={(e) => setProjectSize(e.target.value)}
                                required="Project Size is required"
                                error={projectSizeError}
                            />
                        </div>
                        <div className="grid grid-cols-1 gap-x-3 gap-5 md:grid-cols-2 mt-5">
                            <DatePickerFieldInput
                                name="projectStart"
                                label="Project Start"
                                value={projectStartDate}
                                placeholder="Select Start Date"
                                setPickedDate={setProjectStartDate}
                                required="Project start date is required"
                                error={projectStartDateError}
                            />
                            <DatePickerFieldInput
                                name="projectEnd"
                                label="Project End"
                                value={projectEndDate}
                                placeholder="Select End Date"
                                setPickedDate={setProjectEndDate}
                                required={irrelaventDatesError ? "" : "Project end date is required"}
                                error={irrelaventDatesError || projectEndDateError}
                            />
                        </div>
                        {irrelaventDatesError &&
                            <div className="text-sm text-red-500 mt-2">Project end date must be after the start date. Update the dates accordingly.</div>
                        }
                        <div className="space-y-1">
                            <div className="text-versich-dark-blue block font-medium text-start">
                                <span className="text-base inline-block mt-5 mb-2">Project Photos</span>
                                <div className="grid grid-cols-2 lg800:grid-cols-4 gap-3">
                                    {[...Array(4)].map((_, index) => (
                                        <ImagePreviewInput name="projectphoto" maxSizeInMb={10} setFiles={setImages} key={index} />
                                    ))}
                                </div>
                            </div>
                        </div>
                        {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                        <div className="text-sm font-semibold w-full flex max-sm401:flex-col justify-end gap-3 md:gap-5 py-6">
                            <button className="bg-white hover:opacity-80 text-versich-light-blue border border-versich-light-blue py-2.5 md:py-3 px-8 md:px-10 rounded-lg" type="button" onClick={() => navigate(serviceProjectsPath)}>Cancel</button>
                            <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Add Project</button>
                        </div>
                    </form>
                    {loading && <FormLoader />}
                </div>
            </div>
        </DashboardLayout >
    )
}
export default AddServiceProject