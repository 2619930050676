import { useSelector, useDispatch } from "react-redux"
import { FormProvider, useForm } from "react-hook-form"
import { useState, useEffect } from "react"

import curvedEditIcon from "../../../../assets/svgs/curved-edit.svg"
import Close from "../../../svgs/Close"
import DialogBox, { closeDialog, getDialog, openDialog } from "../../../DialogBox"
import CSRFTokenField from "../../../Inputs/CSRFTokenField"
import InputTextField from "../../../Inputs/InputTextField"
import FormLoader from "../../../loader/FormLoader"
import toastify from "../../../../utils/toastify"
import { TOASTTYPE_ERROR } from "../../../../assets/constants"
import { taglineUpdateAsync } from "../../../../slices/freelancer/settings"

const Tagline = () => {
    const { user } = useSelector((state) => state.auth)
    const methods = useForm()
    const [csrfToken, setCsrfToken] = useState("")
    const [tagline, setTagline] = useState("")
    const [errorMsg, setErrorMsg] = useState("")
    const [loading, setLoading] = useState(false)

    // Pre-fill Form
    const resetForm = () => {
        setTagline(user.about.tagline || "")
    }
    useEffect(() => {
        if (!user.about) {
            return
        }
        resetForm()
    }, [user])

    // Close dialog
    const customCloseDialog = (e) => {
        resetForm()
        closeDialog(e)
    }

    const dispatch = useDispatch()
    const onSubmit = async (data, event) => {
        try {
            const isValid = await methods.trigger()
            if (!isValid) {
                toastify("Form validation failed", TOASTTYPE_ERROR)
                return methods.reset()
            }
            const formData = {
                tagline: data.tagline,
                token: csrfToken,
            }
            setLoading(true)
            await dispatch(taglineUpdateAsync(formData))
                .unwrap()
                .then(() => {
                    const dialog = getDialog(event.target)
                    if (dialog) {
                        closeDialog(null, dialog)
                    }
                    methods.reset()
                    return setLoading(false)
                })
                .catch(error => {
                    const data = JSON.parse(error?.message)
                    return setErrorMsg(data.message)
                })
                .finally(() => setLoading(false))
        } catch (error) {
            console.error("Form submission error:", error)
        }
    }
    return (
        <div className="flex flex-col items-center gap-2">
            <div className="text-center">{user?.about?.tagline ? user.about.tagline : "Tagline"}</div>
            <button
                className="flex items-center justify-center min-w-7 h-7 sm:min-w-8 sm:h-8 border border-versich-light-blue/20 hover:border-versich-light-blue/40 rounded-full"
                type="button"
                onClick={openDialog}
                data-parent-id={taglineEditDialog}
                title="Edit tagline">
                <img className="w-[18px] h-[18px]" src={curvedEditIcon} alt="" data-parent-id={taglineEditDialog} />
            </button>
            <DialogBox
                id={taglineEditDialog}
                className="backdrop:bg-[#1717253D] backdrop:backdrop-blur-sm max-sm:max-w-lg"
                toastStacked={false}
                hideClose={true}
                customCloseEvent={customCloseDialog}
            >
                <div className="space-y-[34px] p-2.5 md:p-4">
                    <div className="flex gap-1.5">
                        <div className="text-versich-dark-blue flex-1">
                            <div className="text-xl font-semibold">Tagline</div>
                            <div className="text-sm mt-0.5">Manage your tagline</div>
                        </div>
                        <div id="dialog_closebtn" className="text-right">
                            <button type="button" className="m-1 p-1" onClick={customCloseDialog} data-parent-id={taglineEditDialog}>
                                <Close className="w-4 h-4" />
                            </button>
                        </div>
                    </div>
                    <FormProvider {...methods}>
                        <form className="space-y-[34px]" method="dialog" onSubmit={methods.handleSubmit(onSubmit)} data-parent-id={taglineEditDialog}>
                            <div className="space-y-3">
                                <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
                                <InputTextField
                                    name="tagline"
                                    inputType="text"
                                    placeholder="Stand out with a short tagline that describes what you do."
                                    onChange={(e) => setTagline(e.target.value)}
                                    defaultValue={tagline}
                                    rules={{ required: "Tagline is required" }}
                                    maxLength={80}
                                />
                            </div>
                            {errorMsg && <div className="text-sm text-red-500 text-left mt-8 mb-4">{errorMsg}</div>}
                            <div className="text-sm font-semibold w-full text-center">
                                <button className="bg-versich-blue hover:bg-versich-blue-hover disabled:bg-versich-blue-hover text-white disabled:opacity-70 py-2.5 md:py-3 px-8 md:px-10 rounded-lg disabled:cursor-not-allowed" type="submit">Save</button>
                            </div>
                        </form>
                    </FormProvider>
                </div>
                {loading && <FormLoader />}
            </DialogBox>
        </div>
    )
}
const taglineEditDialog = "tagline-edit-dialog"
export default Tagline