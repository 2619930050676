import { useParams, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"

import ErrorPage from "../../components/ErrorPage/ErrorPage"
import Meta from "../../components/Meta"
import JobPostingStructuredData from "../../components/StructuredData/JobPostingStructuredData"
import RequestCard from "../dashboard/request/details/RequestCard"
import SectionLoader from "../../components/loader/SectionLoader"
import { getPublicRequestAsync } from "../../slices/request"
import { getSubmitProposalPath } from "../../assets/path"

const JobDetails = () => {
    const { id } = useParams()
    const [request, setRequest] = useState(undefined)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getPublicRequestAsync({ id }))
            .unwrap()
            .then(data => {
                setRequest(data.payload)
            })
            .catch(() => setRequest(null))
    }, [])

    const navigate = useNavigate()
    const dynamicPath = getSubmitProposalPath(id)
    return (
        request === null
            ? <ErrorPage status={404} />
            : <div className="pt-12 md:pt-16 pb-20 md:pb-24 px-2.5 md:px-6 xl:px-28">
                <Meta data={{
                    title: request ? request.details.title : "VersiMarket | Job Details Page",
                    description: "Detailed view of client posted job",
                }} />
                <div className="max_screen_w">
                    <div className="flex flex-col gap-y-5 h-full">
                        {request
                            ? <>
                                <JobPostingStructuredData job={request} />
                                <RequestCard
                                    data={request}
                                    ctaButton={
                                        <button onClick={() => navigate(dynamicPath)} className="primary_btn">
                                            Submit Proposal
                                        </button>
                                    }
                                />
                            </>
                            : <SectionLoader className="h-[450px]" />
                        }
                    </div>
                </div>
            </div>
    )
}
export default JobDetails