import { useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import CSRFTokenField from "../../../components/Inputs/CSRFTokenField"
import SearchButton from "../../../components/Buttons/SearchButton"
import { validateForm } from "../../../utils/form"
import { searchServiceAsync } from "../../../slices/search"
import { newRequestPath, servicesPath } from "../../../assets/constants"
import SearchSuggestionsInput from "./SearchSuggestionsInput"
import ServicesList from "../../../assets/ServicesList"

const ServicesNames = ServicesList.map(service => service.name)

const SearchBar = () => {
    const [csrfToken, setCsrfToken] = useState("")
    const [query, setQuery] = useState("")
    const [suggestions, setSuggestions] = useState([])

    const dispatch = useDispatch()
    const handleChange = (e) => {
        e.preventDefault()
        let query = e.target.value
        setQuery(query)
        if (!query) {
            return setSuggestions([])
        }
        dispatch(searchServiceAsync({ query, token: csrfToken }))
            .unwrap()
            .then((data) => setSuggestions(data))
            .catch(() => setSuggestions([]))
    }

    const navigate = useNavigate()
    const handleSubmit = (e) => {
        e.preventDefault()
        const submitBtn = e.target.querySelector("button[type=submit]")
        submitBtn.disabled = true

        if (validateForm(e.target, submitBtn, "Enter the keywords / service you're looking!")) {
            let serviceIndex = ServicesNames.indexOf(query)
            if (serviceIndex >= 0) {
                navigate(`${newRequestPath + ServicesList[serviceIndex].uid}/`)
            }
            else if (suggestions.length === 1) {
                navigate(`${newRequestPath + suggestions[0].uid}/`)
            }
            else navigate(servicesPath)
        }
    }
    return (
        <form className="bg-white flex sm:mt-2 sm:w-[85%] sm:max-w-[920px] mx-auto p-1.5 lg:p-2 border border-versich-darktext-color/20 rounded-xl relative" onSubmit={handleSubmit}>
            <CSRFTokenField token={csrfToken} setToken={setCsrfToken} />
            <label htmlFor="primary-search" className="w-full h-full absolute inset-0"></label>
            <SearchSuggestionsInput onChange={handleChange} value={query} setValue={setQuery} suggestions={suggestions} />
            <SearchButton />
        </form>
    )
}
export default SearchBar
