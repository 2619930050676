import { useSelector } from "react-redux"
import { useLocation, useNavigate, Link } from "react-router-dom"

import DashboardConfirmBtn from "../Buttons/DashboardConfirmBtn"
import { getInitials } from "../../utils"
import { openDialog } from "../DialogBox"
import { BUSINESS, contactFreelancerDialog, emailVerificationPath } from "../../assets/constants"

const ExpertCard = ({ className, user, contactExpertClick }) => {
    const { user: clientUser } = useSelector(state => state.auth)

    const location = useLocation()
    const navigate = useNavigate()
    const verifyEmailAndContinue = () => {
        const path = window.encodeURIComponent(location.pathname + location.search)
        navigate(`${emailVerificationPath}?next=${path}`)
    }
    const isBusiness = user.accountType === BUSINESS
    const fullName = isBusiness ? user.companyName : user.name
    const initials = getInitials(fullName)
    return (
        <div className={"w-full flex flex-col items-center gap-y-5 gap-x-10 justify-between sm:max-w-[280px] md:max-w-[300px] lg:max-w-[360px] mx-auto border rounded-xl " + (className ? className : "")}>
            <div className="text-center p-6 sm:p-8 pt-6 !pb-0">
                <div className="inline-flex border border-versich-lighter-blue/20 p-1.5 rounded-full">
                    <div className="bg-versich-darktext-color w-16 h-16 lg:h-24 lg:w-24 rounded-full">
                        {user.profile?.image
                            ? <img className="w-full h-full object-cover rounded-full" src={user.profile.image} alt="" />
                            : <div className="text-white text-xl lg:text-2xl font-semibold flex justify-center items-center w-full h-full rounded-full">
                                {initials}
                            </div>
                        }
                    </div>
                </div>
                {!isBusiness
                    ? <>
                        <Link to={`/freelancer/${user.profileId}/`} target="_blank" className="text-versich-dark-blue hover:text-versich-blue text-lg lg:text-xl font-semibold flex justify-center mt-4">{user.name}</Link>
                        {user.tagline &&
                            <span className="text-versich-darktext-color mt-1">{user.tagline}</span>
                        }
                    </>
                    : <Link to={`/business/${user.companyId}/`} target="_blank" className="text-versich-dark-blue hover:text-versich-blue text-lg lg:text-xl font-semibold flex justify-center mt-4">{user.companyName ? user.companyName : user.name}</Link>
                }
                {/* <p className="text-sm text-versich-label mb-5">{user.profileOverview}</p> */}
                <div className="mt-4 py-4">
                    {clientUser.verified
                        ? <DashboardConfirmBtn onClick={(e) => { contactExpertClick(); openDialog(e) }} dataParentId={contactFreelancerDialog}>Contact this expert</DashboardConfirmBtn>
                        : <DashboardConfirmBtn onClick={verifyEmailAndContinue} dataParentId={contactFreelancerDialog}>Contact this expert</DashboardConfirmBtn>
                    }
                </div>
            </div>
            <div className="bg-[#F5F5F5] flex-grow w-full p-4 sm:p-6 border-4 border-white rounded-t-lg rounded-b-xl">
                {!isBusiness && user.companyName && (<>
                    <p className="text-sm text-versich-light-blue font-semibold mb-2.5">Company Name</p>
                    <p className="text-versich-label mb-5">{user.companyName}</p>

                </>)}
                {user.services && (<>
                    <p className="text-sm text-versich-light-blue font-semibold mb-2.5">Skills</p>
                    <div className="flex flex-wrap flex-col smallest:flex-row gap-3">
                        {user.services.map(service => (
                            <div className="bg-white text-versich-darktext-color text-xs md:text-sm py-1.5 px-2.5 border border-versich-border/60 rounded-full" key={service.uid}>{service.title}</div>
                        ))}
                    </div>
                </>)}
            </div>
        </div>
    )
}
export default ExpertCard