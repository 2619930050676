import { useLocation, useNavigate } from "react-router-dom"

import RequestCardLayout from "./RequestCardLayout"
import PremiumButton from "../../Buttons/PremiumButton"
import {
    businessPricingPath,
    COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION
} from "../../../assets/constants"

const BusinessRequestCard = ({ data, hasPlan, viewRequestDetails, upgradeToPremium }) => {
    return (
        <RequestCardLayout data={data}>
            {hasPlan
                ? <button className="px-4 lg:px-5 py-3 bg-versich-blue hover:bg-versich-blue-hover font-medium text-white text-sm rounded-xl"
                    onClick={viewRequestDetails}
                >
                    View Request
                </button>
                : <PremiumButton label="View Request" onClick={upgradeToPremium} />
            }
        </RequestCardLayout>
    )
}

const BusinessRequestCards = ({ plan, requests, viewRequest }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const upgradeToPremium = () => {
        const path = window.encodeURIComponent(location.pathname + location.search)
        window.sessionStorage.setItem(COOKIE_MESSAGE_DETAILS_PATH_AFTER_NEW_SUBSCRIPTION, path)
        navigate(businessPricingPath)
    }

    // Premium Plan check
    const hasPremiumPlan = plan && plan.name === "premium" && plan.status === "active"
    return (<>
        <div />
        {requests.map((data, index) => (
            <BusinessRequestCard data={data} hasPlan={hasPremiumPlan}
                viewRequestDetails={() => viewRequest(data.refId)}
                upgradeToPremium={upgradeToPremium}
                key={index}
            />
        ))}
    </>)
}
export default BusinessRequestCards