import { memo } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { formatDistanceToNow } from "date-fns"

import FreelancerProposalStatus from "./FreelancerProposalStatus"
import BusinessProposalStatus from "./BusinessProposalStatus"
import { fetchPrimaryAndSubService } from "../../FindJobs/utils"
import { findJobsPath } from "../../../assets/constants"
import { getServiceJobsPath } from "../../../assets/path"
import { tagClass } from "../../../assets/class"

const JobDetailsCard = ({ request, viewFullJobPath, restrictionReason, isFreelancer, businessUserHasPremiumPlan, userVersiGems, upgradeToPremium, navigateToBuyVersiGemsPage }) => {
    const {
        createdAt,
        details: { title, serviceId, requestType, paymentType, price, duration, otherDuration, tags, shortDesc },
        restriction,
    } = request
    const location = useLocation()
    const navigate = useNavigate()

    // Fetch service 'title & uid'
    const service = fetchPrimaryAndSubService(serviceId, requestType)

    // Search job by 'tag' name
    const searchByTag = (name) => {
        const term = `search=${encodeURIComponent(name)}`
        const string = location.search ? `${location.search}&${term}` : `?${term}`
        navigate(findJobsPath + string)
    }
    return (
        <div className="bg-white grid lg900:grid-cols-[1fr,minmax(auto,300px)] xl1080:grid-cols-[1fr,minmax(auto,350px)] gap-x-10 gap-y-5 my-4 md:my-8 p-3 border border-versich-light-blue/20 rounded-2xl">
            <div className="text-sm md:text-base max-md:px-2 p-3">
                <p className="text-base sm:text-lg lg:text-xl pb-2">Job details</p>
                <h2 className="text-lg sm:text-xl lg:text-2xl font-semibold pt-2">{title}</h2>
                <div className="text-sm pb-5">Posted {formatDistanceToNow(createdAt, { addSuffix: true })}</div>
                <p>{shortDesc}</p>
                <Link to={viewFullJobPath} className="text-brandeis-blue inline-flex mt-10 mb-5 underline hover:no-underline underline-offset-2 transition-all" target="_blank">View full job</Link>
            </div>
            <div className="text-sm font-medium grid md:grid-cols-2 lg900:grid-cols-1 gap-y-4 gap-x-2">
                {!restrictionReason && <>
                    {isFreelancer ?
                        <FreelancerProposalStatus
                            availableVersiGems={userVersiGems}
                            requiredVersiGems={restriction?.gems}
                            navigateToBuyVersiGemsPage={navigateToBuyVersiGemsPage}
                        />
                        : (
                            !businessUserHasPremiumPlan && <BusinessProposalStatus
                                upgradeToPremium={upgradeToPremium}
                            />
                        )
                    }
                </>
                }
                <div className="md:max-lg900:col-start-1 md:max-lg900:col-end-2 md:max-lg900:row-start-1 md:max-lg900:row-end-2">
                    <div className="bg-versich-lighter-blue/[0.03] space-y-8 p-4 sm:p-6 lg:p-8 rounded-xl">
                        <div className="smaller:max-md:grid grid-cols-2 smaller:max-md:space-y-0 space-y-2">
                            <div className="space-y-0.5 md:space-y-1">
                                {paymentType && (
                                    <div className="text-sm">
                                        {paymentType.title} Price
                                    </div>
                                )}
                                {price && (
                                    <div className="text-versich-darktext-color text-sm smaller:text-base font-semibold">
                                        {price}
                                    </div>
                                )}
                            </div>
                            {duration && (
                                <div className="space-y-0.5 md:space-y-1">
                                    <div className="text-sm">Duration</div>
                                    <div className="text-versich-darktext-color text-sm smaller:text-base">
                                        {duration.uid === "other" ? otherDuration : duration.title}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="space-y-2">
                            <div className="text-versich-darktext-color text-sm smaller:text-base lg:text-lg">Skills & Expertise: </div>
                            <div className="text-xs md:text-sm font-normal flex gap-1.5 flex-wrap mt-5 sm:pt-1.5">
                                {service.primary && (
                                    <Link to={getServiceJobsPath(service.primary.uid)} className={tagClass} key={service.primary.uid}>
                                        {service.primary.title}
                                    </Link>
                                )}
                                {service.sub && (
                                    <Link to={getServiceJobsPath(service.sub.uid)} className={tagClass} key={service.sub.uid}>
                                        {service.sub.title}
                                    </Link>
                                )}
                                {tags &&
                                    tags.map((tag) => (
                                        tag.uid !== "other" ? (
                                            <button onClick={() => searchByTag(tag.title)} className={tagClass + " text-left"} key={tag.uid}>
                                                {tag.title}
                                            </button>
                                        ) : null
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default memo(JobDetailsCard)