import { useState } from "react"

import fileuploadIcon from "../assets/svgs/file-upload.svg"

const FileUploadField = ({ name, label, maxSizeInMb, file, setFile }) => {
    const [uploadError, setUploadError] = useState("")

    const handleFileSelect = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0]
            const fileSizeMb = (file.size / (1024 ** 2)).toFixed(2)
            if ((allowedFileTypes.includes(file.type) === false)
                || (file.type === "" && file.name.endsWith(".heic") === false)) {
                setUploadError("Choose a valid file specified above.")
            }
            else if (fileSizeMb < maxSizeInMb) {
                setUploadError("")
                return setFile(file)
            } else {
                setUploadError(`Selected file size is greater than ${maxSizeInMb}MB`)
            }
        }
        setFile(null)
    }
    return (
        <div className="space-y-2">
            <label htmlFor={name} className="text-charcoal block text-sm text-start">{label}</label>
            <label className="bg-[#F0F2FF] flex flex-col items-center justify-center gap-y-3 py-10 px-4 rounded-[10px] cursor-pointer">
                <input id={name} name={name} type="file" accept="image/*,.pdf" hidden onChange={handleFileSelect} />
                <img src={fileuploadIcon} alt="" />
                <span className="text-xs text-center">
                    {file ? file.name : "Click to add file"}
                </span>
                <span className="text-xs text-center">JPG, PNG, WEBP, HEIC or PDF, file size no more than {maxSizeInMb}MB</span>
            </label>
            {uploadError !== "" && <p className="text-red-500 text-sm text-left">{uploadError}</p>}
        </div>
    )
}
const allowedFileTypes = ["image/webp", "image/png", "image/jpeg", "application/pdf"]
export default FileUploadField