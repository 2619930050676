import { useSelector } from "react-redux"
import { useSearchParams, useNavigate } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import Cookies from "js-cookie"

import { ServiceRequestContext, ServiceRequestProvider } from "../components/ServiceRequestLayout/ServiceRequestContext"
import ServiceRequestLayout from "../ServiceRequestLayout"
import Expert from "./components/Expert"
import Budget from "../components/Budget"
import HiringDecision from "../components/HiringDecision"
import ClientDetails from "../components/ClientDetails"
import TitleAndDescription from "../components/TitleAndDescription"
import { newRequestPath, COOKIE_NEW_REQUEST_MLAI } from "../../../../../assets/constants"
import { createRequestAsync } from "../../../../../slices/client/service/machineLearningAndAI"

const metadata = {
    title: "New Request - Machine Learning and AI Service",
    description: "Machine learning and AI new request",
    url: `${process.env.REACT_APP_CLIENT_URL}/request/new/machine-learning-and-ai/`
}

const MachineLearningAndAI = ({ cookieKey }) => {
    const { user } = useSelector(state => state.auth)
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    // Consume common context fields.
    const {
        formPosition, setFormPosition,
        clientDetails, setClientDetails,
        title, setTitle,
        description, setDescription,
        titleError, setTitleError,
        descError, setDescError
    } = useContext(ServiceRequestContext)

    const [expert, setExpert] = useState("")
    const [otherExpert, setOtherExpert] = useState(null)
    const [paymentType, setPaymentType] = useState("")
    const [currency, setCurrency] = useState("")
    const [budget, setBudget] = useState("")
    const [otherBudget, setOtherBudget] = useState(null)
    const [hiringDecision, setHiringDecision] = useState("")
    const [otherHiringDecision, setOtherHiringDecision] = useState(null)

    // Prefill data from cookie
    useEffect(() => {
        const data = Cookies.get(cookieKey)
        if (!data) {
            return
        }
        const parsedData = JSON.parse(data)
        parsedData.expert && setExpert(parsedData.expert)
        parsedData.otherExpert && setOtherExpert(parsedData.otherExpert)
        parsedData.paymentType && setCurrency(parsedData.paymentType)
        parsedData.currency && setCurrency(parsedData.currency)
        parsedData.budget && setBudget(parsedData.budget)
        parsedData.otherBudget && setOtherBudget(parsedData.otherBudget)
        parsedData.hiringDecision && setHiringDecision(parsedData.hiringDecision)
        parsedData.otherHiringDecision && setOtherHiringDecision(parsedData.otherHiringDecision)
    }, [])

    // Generate request data
    const formData = {
        expert,
        ...(otherExpert && { otherExpert }),
        paymentType,
        currency,
        budget,
        ...(otherBudget && { otherBudget }),
        hiringDecision,
        ...(otherHiringDecision && { otherHiringDecision }),
        clientEmail: clientDetails?.email,
        clientName: clientDetails?.name,
        title,
        description,
    }
    const totalForms = 5
    return (
        <ServiceRequestLayout
            metadata={metadata}
            cookieKey={cookieKey}
            clientDetails={clientDetails}
            title={title}
            setTitleError={setTitleError}
            description={description}
            setDescError={setDescError}
            formData={formData}
            createRequestAsync={createRequestAsync}
        >
            {!expert ? (
                <Expert
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(1)}
                    defaultValue={searchParams.get("expert")}
                    setExpert={setExpert}
                    setOtherExpert={setOtherExpert}
                    goBack={() => navigate(newRequestPath)}
                />
            ) : !budget ? (
                <Budget
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(2)}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                    setCurrency={setCurrency}
                    setBudget={setBudget}
                    setOtherBudget={setOtherBudget}
                    goBack={() => setExpert("")}
                />
            ) : !hiringDecision ? (
                <HiringDecision
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(3)}
                    setHiringDecision={setHiringDecision}
                    setOtherHiringDecision={setOtherHiringDecision}
                    goBack={() => setBudget("")}
                />
            ) : !clientDetails ? (
                <ClientDetails
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(4)}
                    clientDetails={clientDetails}
                    setClientDetails={setClientDetails}
                    goBack={() => setHiringDecision("")}
                />
            ) : (
                <TitleAndDescription
                    totalForms={totalForms}
                    formPosition={formPosition}
                    updateFormPosition={() => setFormPosition(5)}
                    title={title}
                    setTitle={setTitle} description={description}
                    setDescription={setDescription} titleError={titleError} descError={descError}
                    goBack={() => { user ? setHiringDecision("") : setClientDetails(null) }}
                />
            )}
        </ServiceRequestLayout>
    )
}

const MachineLearningAndAILayout = () => {
    const cookieKey = COOKIE_NEW_REQUEST_MLAI
    return (
        <ServiceRequestProvider cookieKey={cookieKey}>
            <MachineLearningAndAI cookieKey={cookieKey} />
        </ServiceRequestProvider>
    )
}
export default MachineLearningAndAILayout