import Meta from "../../components/Meta"

const metadata = {
    title: "Privacy Policy | Your Data Protection",
    description: "Understand how we collect, use, and protect your personal information. Review our Privacy Policy for detailed information.",
    url: `${process.env.REACT_APP_CLIENT_URL}/legal/privacy-policy/`
}

const PrivacyPolicy = () => {
    return (
        <div>
            <Meta data={metadata} />
            <div className="bg-white py-10 md:py-14 px-8 md:px-16 xl:px-28">
                <div className="max_screen_w">
                    <div className="pb-3 sm:pb-6 border-b border-versich-light-blue/10">
                        <h1 className="text-versich-darktext-color text-2xl sm:text-3xl font-bold">VersiMarket Privacy Policy</h1>
                        <h2 className="text-versich-dark-blue text-sm smaller:text-base font-semibold text-left pt-8 sm:pt-10">Effective Date: February 3rd 2025</h2>
                    </div>
                    <div className="text-left mt-6 sm:mt-10 space-y-12 sm:space-y-16">
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">1. Introduction</h2>
                            <div className="text-sm lg:text-base">
                                Welcome to VersiMarket. Your privacy is important to us. This Privacy Policy explains how VersiMarket ("we", "us", "our") collects, uses, and discloses your personal information when you visit or use our website, mobile applications, and other online services (collectively, the "Platform"). By accessing or using the Platform, you agree to the practices described in this Privacy Policy.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">2. Who We Are</h2>
                            <div className="text-sm lg:text-base">
                                VersiMarket is a division of Versich, a company registered in England (Company No. 14629622) with its registered office at:<br />
                                <span className="font-semibold">Broad House, Imperial Drive, HA2 7BL, London, United Kingdom.</span>
                            </div>
                        </div>
                        <div className="space-y-6 sm:space-y-10">
                            <div>
                                <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">3. Information We Collect</h2>
                                <div className="text-sm lg:text-base">We may collect and process various types of information about you, including:</div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.1. Personal Information</h2>
                                <div className="text-sm lg:text-base">
                                    <ul className="pl-6 sm:pl-8 list-disc">
                                        <li><span className="text-versich-light-blue font-semibold">Contact Details</span>: Name, email address, phone number, and postal address.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Account Information</span>: Username, password, and other registration details.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Business Information</span>: Company name, business address, and relevant professional details.</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.2. Usage Data</h2>
                                <div className="text-sm lg:text-base">
                                    <ul className="pl-6 sm:pl-8 list-disc">
                                        <li><span className="text-versich-light-blue font-semibold">Technical Data</span>: IP address, browser type, device information, operating system, and usage patterns.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Log Data</span>: Details of your visits to our Platform, including pages viewed, links clicked, and the dates/times of your visits.</li>
                                        <li><span className="text-versich-light-blue font-semibold">Cookies and Similar Technologies</span>: Data collected through cookies, web beacons, and similar tracking technologies. (For more details, please see our Cookies Policy below.)</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h2 className="text-versich-light-blue smaller:text-lg lg:text-xl font-bold pb-3 sm:pb-6">3.3. Communications</h2>
                                <div className="text-sm lg:text-base">
                                    <ul className="pl-6 sm:pl-8 list-disc">
                                        <li><span className="text-versich-light-blue font-semibold">Customer Support</span>: Any correspondence you send to us (via email, contact forms, or other means) may be recorded and stored.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">4. How We Use Your Information</h2>
                            <div className="text-sm lg:text-base">
                                <div>We use the information we collect for various purposes, including to:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Provide and Improve Our Services</span>: Operate, maintain, and enhance the functionality of the Platform.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Account Management</span>: Process your registration, manage your account, and provide customer support.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Communications</span>: Send you updates, newsletters, marketing materials, and other information related to our services. You may opt out of marketing communications at any time.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Analytics</span>: Monitor and analyze usage and trends to improve user experience and the overall performance of our Platform.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Legal and Security</span>: Comply with legal obligations, resolve disputes, and enforce our agreements.</li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">5. Cookies and Similar Technologies</h2>
                            <div className="text-sm lg:text-base">
                                <div>We use cookies and similar technologies to enhance your experience on our Platform. These technologies help us to:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li>Recognize you when you return to our Platform.</li>
                                    <li>Understand how you use our Platform.</li>
                                    <li>Personalize content and provide tailored advertising.</li>
                                </ul>
                                <div>You can control the use of cookies through your browser settings. However, please note that disabling cookies may affect your ability to use certain features of the Platform.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">6. How We Share Your Information</h2>
                            <div className="text-sm lg:text-base">
                                <div>We may share your information with third parties in the following circumstances:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Service Providers</span>: With trusted third-party vendors who perform services on our behalf (e.g., payment processing, hosting, analytics, customer support). These service providers are contractually obligated to maintain the confidentiality and security of your information.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Legal Requirements</span>: When required by law, regulation, or legal process, or if we believe in good faith that disclosure is necessary to protect our rights, the safety of our users, or to comply with a judicial proceeding.</li>
                                    <li><span className="text-versich-light-blue font-semibold">Business Transfers</span>: In connection with any merger, sale of assets, financing, or acquisition of all or a portion of our business by another company, in which case your information may be transferred as part of that transaction.</li>
                                </ul>
                                <div>We do not sell your personal information to third parties.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">7. Data Retention</h2>
                            <div className="text-sm lg:text-base">
                                We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected or as required by law. When no longer needed, we will securely delete or anonymize your data.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">8. Data Security</h2>
                            <div className="text-sm lg:text-base">
                                We implement appropriate technical and organizational measures to protect your personal information against accidental, unlawful, or unauthorized destruction, loss, alteration, or access. However, no method of transmission over the Internet or electronic storage is 100% secure, so we cannot guarantee absolute security.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">9. Your Rights</h2>
                            <div className="text-sm lg:text-base">
                                <div>Depending on your jurisdiction, you may have certain rights regarding your personal data, including the right to:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li>Access and obtain a copy of your personal information.</li>
                                    <li>Correct or update your personal information.</li>
                                    <li>Request the deletion of your personal data.</li>
                                    <li>Restrict or object to our processing of your information.</li>
                                    <li>Data portability.</li>
                                    <li>Lodge a complaint with your local data protection authority.</li>
                                </ul>
                                <div>To exercise any of these rights, please contact us using the details provided in Section 13.</div>
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">10. International Data Transfers</h2>
                            <div className="text-sm lg:text-base">
                                Your personal data may be transferred to, stored, and processed in countries outside your own, including in the United Kingdom. We take steps to ensure that any such transfers are conducted in accordance with applicable data protection laws and provide an adequate level of protection for your personal data.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">11. Children's Privacy</h2>
                            <div className="text-sm lg:text-base">
                                Our Platform is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal data from a child under 18, we will take steps to delete such information as soon as possible.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">12. Changes to Our Privacy Policy</h2>
                            <div className="text-sm lg:text-base">
                                We may update this Privacy Policy from time to time to reflect changes in our practices, legal requirements, or for other operational reasons. We will notify you of any material changes by updating the "Effective Date" at the top of this policy and, where appropriate, through email or notices on our Platform. Your continued use of the Platform after such changes signifies your acceptance of the updated Privacy Policy.
                            </div>
                        </div>
                        <div>
                            <h2 className="text-versich-light-blue text-lg smaller:text-xl lg:text-2xl font-bold pb-3 sm:pb-6">13. Contact Information</h2>
                            <div className="text-sm lg:text-base">
                                <div>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:</div>
                                <ul className="m-1.5 sm:m-3 pl-6 sm:pl-8 list-disc">
                                    <li><span className="text-versich-light-blue font-semibold">Email</span>: Hello@versimarket.com</li>
                                    <li><span className="text-versich-light-blue font-semibold">Postal Address</span>:<br />
                                        VersiMarket<br />
                                        Broad House, Imperial Drive,<br />
                                        HA2 7BL London,<br />
                                        United Kingdom
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PrivacyPolicy
