import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"

import useBackNavigation from "../../../../hooks/useBackNavigation"
import chevronRightIcon from "../../../../assets/svgs/chevron-right.svg"
import ErrorPage from "../../../../components/ErrorPage/ErrorPage"
import DashboardLayout from "../../../../components/Dashboard/DashboardLayout"
import SectionLoader from "../../../../components/loader/SectionLoader"
import JobDetailsCard from "../../../../components/Jobs/JobDetailsWithProposalForm/JobDetailsCard"
import ProfessionalContactCard from "../../../../components/Dashboard/proposals/ProfessionalContactCard"
import StartOrNavigateMessageButton from "../../../../components/Dashboard/proposals/StartOrNavigateMessageButton"
import ProposalDetailsCard from "../../../../components/Dashboard/proposals/ProposalDetailsCard"
import { getProposalAsync } from "../../../../slices/proposal"
import { CLIENT, proposalsPath, requestsPath } from "../../../../assets/constants"
import { getFullJobPath } from "../../../../assets/path"

const metadata = {
    title: "Proposal Details",
    description: "View proposal details",
}

const ProposalDetails = () => {
    const { id } = useParams()
    const dispatch = useDispatch()

    const { user } = useSelector(state => state.auth)
    const isClient = user.accountType === CLIENT
    const [proposal, setProposal] = useState(undefined)

    // Navigation callback for the "Back" button
    const handleBack = useBackNavigation(isClient ? requestsPath : proposalsPath)

    // Load proposal details on mount (or when id changes)
    useEffect(() => {
        const loadProposal = () => {
            dispatch(getProposalAsync(id))
                .unwrap()
                .then(data => {
                    setProposal(data.payload)
                })
                .catch(() => setProposal(null))
        }
        loadProposal()
    }, [dispatch, id])

    // Display error page if 'proposal' is null
    if (proposal === null) {
        return <ErrorPage status={404} />
    }

    // Derived navigation: full view job path
    const viewFullJobPath = getFullJobPath(proposal?.request?.refId)
    return (
        <DashboardLayout metadata={metadata} activePage="Proposals">
            {proposal ? (
                <div className="flex flex-col gap-y-2.5 h-full">
                    <div className="space-y-1.5">
                        <div className="flex justify-start pt-1">
                            <button className="inline-flex items-center gap-0.5" onClick={handleBack}>
                                <img src={chevronRightIcon} alt="" />
                                <span className="text-versich-light-blue text-sm font-semibold">Back</span>
                            </button>
                        </div>
                        <div className="text-versich-dark-blue text-start space-y-[2px]">
                            <div className="text-lg lg:text-xl font-semibold">Proposal Details</div>
                        </div>
                    </div>
                    <div className="bg-white text-start flex-grow space-y-4 md:space-y-8 pt-4 pb-12 px-4 sm401:px-6 shadow-card4 rounded-lg">
                        <JobDetailsCard
                            request={proposal.request}
                            viewFullJobPath={viewFullJobPath}
                            restrictionReason={true}
                        />
                        {isClient
                            ? <ProfessionalContactCard proposal={proposal} />
                            : proposal.conversation && <div className="text-right">
                                <StartOrNavigateMessageButton conversation={proposal.conversation} />
                            </div>
                        }
                        <ProposalDetailsCard data={proposal} isClient={isClient} />
                    </div>
                </div>
            ) : (
                <SectionLoader className="h-[450px]" />
            )}
        </DashboardLayout>
    )
}
export default ProposalDetails