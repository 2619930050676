import GlobalAvatar from "../../../Avatars/Avatar"

const Avatar = ({ user }) => {
    return <GlobalAvatar
        user={user}
        fullName={user.details.name}
        ringClass="min-w-10 w-10 h-10 border border-versich-darktext-color/5 group-hover:border-versich-darktext-color/10"
        initialClass="text-sm lg:text-base"
    />
}
export default Avatar